var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "delivery-slot-card" }, [
    _c(
      "label",
      { staticClass: "radiocontainer", attrs: { for: _vm.deliverySlot.id } },
      [
        _c("div", [
          _c("p", { staticClass: "delivery-slot-card__date" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.date.toFormat("cccc")) +
                ", " +
                _vm._s(_vm.date.toFormat("dd/MM/yyyy")) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "delivery-slot-card__price" }, [
            !_vm.deliverySlot.isInStock &&
            _vm.order.product.id === 2 &&
            _vm.deliverySlot.isFirstDay
              ? _c("span", { staticClass: "free" }, [
                  _vm._v(
                    "\n          Free collection - available from 2pm\n        "
                  ),
                ])
              : _vm.deliverySlot.price == 0
              ? _c("span", { staticClass: "free" }, [_vm._v("Free")])
              : _c("span", [
                  _vm._v(_vm._s(_vm._f("price")(_vm.deliverySlot.price))),
                ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "delivery-slot-card__radio" }, [
          _c("input", {
            attrs: { type: "radio", id: _vm.deliverySlot.id },
            domProps: {
              value: _vm.deliverySlot,
              checked: _vm.deliverySlot === _vm.value,
            },
            on: { change: _vm.select },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "tickmark" }, [
            _c("div", { staticClass: "svg" }, [
              _c(
                "svg",
                {
                  attrs: {
                    width: "24px",
                    height: "23px",
                    viewBox: "0 0 12 11",
                    version: "1.1",
                    xmlns: "http://www.w3.org/2000/svg",
                    "xmlns:xlink": "http://www.w3.org/1999/xlink",
                  },
                },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        stroke: "none",
                        "stroke-width": "1",
                        fill: "none",
                        "fill-rule": "evenodd",
                      },
                    },
                    [
                      _c(
                        "g",
                        {
                          attrs: {
                            transform: "translate(-124.000000, -3326.000000)",
                            "fill-rule": "nonzero",
                            stroke: "var(--color-primary)",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M134.854266,3327.0501 C134.706927,3326.95592 134.510342,3326.99846 134.415838,3327.14467 L128.455086,3335.15954 C128.372345,3335.28695 128.263685,3335.36174 128.149243,3335.36965 C128.033804,3335.37678 127.917169,3335.31841 127.817281,3335.20267 L125.558149,3332.5875 C125.444305,3332.45593 125.243932,3332.4403 125.110749,3332.55347 C124.977765,3332.66644 124.962413,3332.86548 125.076257,3332.99744 L127.335389,3335.61281 C127.550117,3335.86091 127.836222,3336 128.129504,3336 C128.150638,3336 128.171573,3335.99941 128.192707,3335.99763 C128.507721,3335.97606 128.797614,3335.79463 128.988816,3335.49964 L134.949369,3327.48537 C135.044471,3327.33896 135.001605,3327.14427 134.854266,3327.0501 Z",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }