import debounce from 'lodash/debounce'

const Navigation = class {
  constructor() {
    this.window = window
    this.toggle = document.querySelector('.header-navigation .hamburger')
    this.body = document.body

    this._init_ui()
    this._events()
  }

  /**
   * Reset for scaling the browser up if a user has already used the responsive menu
   */
  _init_ui() {
    if (this.window.innerWidth >= 830) {
      this.body.classList.remove('nav-expanded')
    }
  }

  /**
   * Create event for responsive menu hamburger
   */
  _events() {
    if (this.toggle) {
      this.toggle.addEventListener('click', (e) => {
        e.preventDefault()

        if (this.body.classList.contains('nav-expanded')) {
          this.hide_menu()
        } else {
          this.show_menu()
        }
      })
    }

    // Reset for responsive view in the header navigation
    this.window.addEventListener('resize', debounce(() => {
      this._init_ui()
    }, 100))

    document.addEventListener('scroll', debounce(() => this.storeScoll()));


    const dropdowns = document.querySelectorAll('.header-navigation .dropdown');

    const collapseAll = () => {
      dropdowns.forEach(dropdown => {
        const button = dropdown.querySelector('button');
          dropdown.classList.remove('expanded')
          button.ariaExpanded = false;
      });
    };

    dropdowns.forEach(dropdown => {
      const button = dropdown.querySelector('button');
      button.addEventListener('click', () => {
        const expanded = dropdown.classList.contains('expanded');
        collapseAll();
        if(!expanded) {
          dropdown.classList.add('expanded')
          button.ariaExpanded = true;
        }
      })
    });

    document.addEventListener('cartToggled', e => {
      collapseAll();
    })
  }

  /**
   * Hide the reponsive navigation
   */
  hide_menu() {
    this.body.classList.remove('nav-expanded')
  }

  /**
   * Show the reponsive navigation
   */
  show_menu() {
    this.body.classList.add('nav-expanded')
  }

  storeScoll() {
    if (window.scrollY > 400) {
      this.body.classList.add('sticky')
      return
    }

    if (window.scrollY > 200) {
      this.body.classList.add('scrolled')
      return
    }

    this.body.classList.remove('scrolled')
    this.body.classList.remove('sticky')
  }
}

export default Navigation
