var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { staticClass: "checkout" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("div", { staticClass: "checkout--content" }, [
            _vm.hostedPaymentPageIframeUrl
              ? _c("div", { staticClass: "eurochange-payment" }, [
                  _c("iframe", {
                    ref: "frame",
                    attrs: {
                      src: _vm.hostedPaymentPageIframeUrl,
                      id: "eurochange-barclays-iframe",
                    },
                  }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "spinner" },
              [
                !_vm.hostedPaymentPageIframeUrl
                  ? _c("Spinner", {
                      attrs: { name: "circle", color: "var(--color-links)" },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.order
            ? _c("order-summary", {
                attrs: { deliverySlot: _vm.order.deliverySlot },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }