<template>
  <div class="column store-select" :class="{ visible: showStore }">
    <div class="label row">
      <span v-if="type === SELL_ID">{{
        content.storeFinderSellLabel ||
        '3. Select a eurochange branch to collect your GBP from'
      }}</span>
      <span v-else>
        {{
          content.storeFinderLabel ||
          '4. Select a eurochange branch to collect your currency from'
        }}
      </span>
      <Tooltip :content="content.clickAndCollectTooltip" />
    </div>

    <vue-google-autocomplete
      v-if="!hideBranchFinder"
      id="GooglePlaces"
      classname="form-control"
      placeholder="Enter your postcode, town or city"
      country="gb"
      types=""
      v-on:placechanged="getAddressData"
      ref="autocomplete"
    />

    <div class="selected" v-if="store && !store.isMeta">
      <div class="label row">
        <span>Selected Store</span>

        <button class="close" @click="reset()">Reset</button>
      </div>
      <button class="selected-modal-toggle" @click="enableToggleStoreModal" >
        {{ store.brandName }} {{ store.name }}
      </button>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'

  import { mapActions, mapState } from 'vuex'
  import { GEOCODING_URL, GEOCODING_KEY } from '@vue/common/constants/config'
  import VueGoogleAutocomplete from 'vue-google-autocomplete'
  import { getParameterByName } from '@vue/services/calculator.service'
  import { SELL_ID } from '@vue/common/constants/config'
  import Tooltip from '@vue/common/components/Tooltip'

  export default {
    name: 'StoreFinder',
    components: {
      Tooltip,
      VueGoogleAutocomplete,
    },
    data() {
      return {
        SELL_ID,
        hideBranchFinder: false,
        disableToggleStore: false,
      }
    },
    computed: {
      ...mapState({
        content: (state) => state.calculator.content,
        showStore: (state) => state.calculator.showStore,
        store: (state) => state.store.store,
        selectedCurrency: (state) => state.currency.selectedCurrency,
        targetQuantity: (state) => state.currency.targetQuantity,
        type: (state) => state.calculator.type,
        selectedProduct: (state) => state.product.selectedProduct,
      }),
    },
    mounted() {
      const queryStore = getParameterByName('store_id')

      if (queryStore) {
        this.getStore(queryStore)
      }
      this.setStoreAddress();
    },
    methods: {
      ...mapActions({
        getNearby: 'store/getNearby',
        toggleStoreModal: 'calculator/toggleStoreModal',
        toggleResult: 'calculator/toggleResult',
        clearStores: 'store/clearStores',
        getStore: 'store/getStore',
      }),
      getAddressData(place) {
        if (place.latitude && place.longitude) {
          this.getNearby({
            Latitude: place.latitude,
            Longitude: place.longitude,
            amount: this.targetQuantity,
            currency: this.selectedCurrency.code,
          TransactionType:this.type,
          })
          this.toggleStoreModal(true)
        } else {
          const params = {
            Key: encodeURIComponent(GEOCODING_KEY),
            Country: encodeURIComponent('GBR'),
            Location: encodeURIComponent(
              `${place.route}, ${place.locality}, ${place.administrative_area_level_1}, ${place.administrative_area_level_2}`
            ),
          }

          const queryString = Object.keys(params)
            .map((key) => key + '=' + params[key])
            .join('&')

          const url = `${GEOCODING_URL}?${queryString}`

          axios
            .post(url, null, { withCredentials: false })
            .then(({ data }) => {
              this.getNearby({
                ...data.Items[0],
                amount: this.targetQuantity,
                currency: this.selectedCurrency.code,
              })
              this.toggleStoreModal(true)
            })
            .catch((error) => {
              console.log(error)
            })
        }
      },

      async setStoreAddress() {
        // If we are on BranchPage setStore else skipping
        if (window.branchId) {
          await this.getStore(window.branchId);
          this.hideBranchFinder = true;
          this.disableToggleStore = true;
          if (this.selectedProduct) {
            this.toggleResult(true)
          }
        }
      },
      enableToggleStoreModal() {
        if (!this.disableToggleStore) {
          this.toggleStoreModal(true);
        }
      },
      reset() {
        this.hideBranchFinder = false;
        this.disableToggleStore = false;
        this.clearStores();        
        this.$nextTick(() => {
          this.$refs.autocomplete.autocompleteText = '';  
          this.$refs.autocomplete.focus();          
        });
      },
    },

    watch: {
      selectedProduct(newValue) {
        if (this.hideBranchFinder && newValue.id != 1) {
          this.setStoreAddress();
        }
      },
    },
  }
</script>
