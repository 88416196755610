import httpCommerce from './httpCommerce'
import { SITE_ID } from '@vue/common/constants/config'

export const getProducts = () => {
  return httpCommerce.get('/api/products')
}

export const getSiteProducts=()=>{
  return httpCommerce.get(`/api/sites/${SITE_ID}/products`);
}

export default { 
  getProducts, 
  getSiteProducts 
}
