var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.velocityModal
    ? _c("div", { staticClass: "modal modal--velocity" }, [
        _c("div", { staticClass: "modal--inner" }, [
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c("small", [
            _vm._v(
              "*Please note that you will require a proof of ID for all credit, debit\n      card and larger transactions"
            ),
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "close",
              on: {
                click: function ($event) {
                  return _vm.setVelocityModal(false)
                },
              },
            },
            [_vm._v("Close")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "button button--secondary",
              on: { click: _vm.returnToHomepage },
            },
            [_vm._v("\n      Return to the homepage\n    ")]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal--header" }, [
      _c("h3", [
        _vm._v(
          "\n        Unfortunately you have exceeded the amount of orders you can process\n        through our website today.\n      "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n      Your business is important to us, If you would like to purchase more\n      currency, you can still do this via one of our branches, subject to our\n      usual terms and conditions* and stock availability. To find your nearest\n      branch or contact one of our cashiers please use "
      ),
      _c("a", { attrs: { href: "/branches", target: "_blank" } }, [
        _vm._v("our branch finder"),
      ]),
      _vm._v(".\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }