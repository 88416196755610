var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "checkout--header" },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("steps"),
          _vm._v(" "),
          !_vm.steps.confirmation ? _c("countdown") : _vm._e(),
          _vm._v(" "),
          !_vm.steps.confirmation
            ? _c("button", {
                staticClass: "close",
                on: {
                  click: function ($event) {
                    return _vm.setExitModal(true)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.steps.confirmation
            ? _c("a", { staticClass: "close", attrs: { href: "/" } })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("modal-exit"),
      _vm._v(" "),
      _c("modal-warning"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("a", { attrs: { href: "/" } }, [
        _c("img", { attrs: { src: "/logo-mobile-alternate" } }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }