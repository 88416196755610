import Velocity  from 'velocity-animate'

import debounce from 'lodash/debounce'

const Tabs = class {
  constructor(el) {
    this.window = window
    this.tabbed = el
    this.nav = this.tabbed.querySelector('.items')
    this.items = this.nav.querySelectorAll('.item')
    this.content = this.tabbed.querySelectorAll('.tab-content')

    this.width = 200
    this.speed = 500
    this.currentItem = 0

    // Create UI Elements
    this._init_ui()

    // Bind events
    this._events()

    if (this.window.innerWidth < 770) {
      this.mobileNavigation()
    }
  }

  /**
   * Add in active state for first tab item
   */
  _init_ui() {
    this.content[0]?.classList.add('active')
    this.items[0]?.classList.add('active')
  }

  /**
   * Create event for each item in the tab navigation
   * Create event for resize of browser window
   */
  _events() {
    this.items.forEach((el, index) => {
      el?.addEventListener('click', () => {
        const tab = el.getAttribute('data-tab')
        const active = this.tabbed.querySelector('[data-content="' + tab + '"]')

        el.classList.add('active')
        this.items.forEach(item => {
          if (item !== el) {
            item.classList.remove('active')
          }
        })
        // Make tab content active
        active.classList.add('active')
        active.parentNode.querySelectorAll('.tab-content').forEach(content => {
          if (content !== active) {
            content.classList.remove('active')
          }
        })

        if (this.window.innerWidth < 770) {
          this.offsetMenu(index)
        }

      })
    })

    // On resize of the window change the UI or reset it
    this.window.addEventListener('resize', debounce(() => {
      if (this.window.innerWidth < 770) {
        this.mobileNavigation()
      }
      else {
        this.reset_tabs()
      }
    }, 100))

  }

  touchStart(e) {
    this.startX = e.touches[0].clientX
    this.isSwiping = true
  }
  touchMove(e) {
    if (!this.isSwiping || !e.cancelable) return
    e.preventDefault()
  }
  touchEnd(e) {
    if (!this.isSwiping) return
    this.isSwiping = false

    const swipe = e.changedTouches[0].clientX - this.startX
    const threshold = 75

    if (swipe > threshold) {
      this.previous_item()
    } else if (swipe < -threshold) {
      this.next_item()
    }
  }
  previous_item() {
    this.currentItem = Math.max(this.currentItem - 1, 0)

    this.offsetMenu(this.currentItem)
    this.select_item()
  }
  next_item() {
    if ((this.currentItem + 1) === this.items.length)
      return

    this.currentItem = Math.max(this.currentItem + 1, 0)
    this.offsetMenu(this.currentItem)
    this.select_item()
  }
  select_item() {
    const el = this.items[this.currentItem]

    const tab = el.getAttribute('data-tab')
    const active = this.tabbed.querySelector('[data-content="' + tab + '"]')

    el.classList.add('active')
    this.items.forEach(item => {
      if (item !== el) {
        item.classList.remove('active')
      }
    })

    active.classList.add('active')
    active.parentNode.querySelectorAll('.tab-content').forEach(content => {
      if (content !== active) {
        content.classList.remove('active')
      }
    })
  }

  /**
   * For the mobile navigation we need to offset the menu
   */
  mobileNavigation() {
    const halfNav = this.nav?.offsetWidth / 2
    const halfItem = this.items[0]?.offsetWidth / 2

    this.nav.style.transform = `translateX(${halfNav - halfItem}px)`

    this.nav?.addEventListener('touchstart', this.touchStart.bind(this))
    this.nav?.addEventListener('touchmove', this.touchMove.bind(this))
    this.nav?.addEventListener('touchend', this.touchEnd.bind(this))
  }

  /**
   * Remove all styles that affect mobile
   */
  reset_tabs() {
    this.nav.style = ''
  }

  /**
   * When a menu item is clicked in the mobile confirugation we need to move the menu along
   * @param  {Obj} el The element that has been clicked
   */
  offsetMenu(index) {
    const halfNav = this.nav.offsetWidth / 2
    const halfItem = this.items[0].offsetWidth / 2
    const posLeft = this.items[index].offsetLeft - (halfNav - halfItem)

    Velocity(this.nav, {
      translateX: -posLeft
    }, {
      duration: 200
    })
  }
}

export default Tabs
