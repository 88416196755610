import Vue from 'vue'

import store from '@vue/store'
import ModalExpired from './modal-expired'

if (document.getElementById('cart-expired-modal')) {
  new Vue({
    el: '#cart-expired-modal',
    render: createElement => createElement(ModalExpired),
    store
  })
}
