<template>
  <div class="modal modal--warning" v-if="warningModal">
    <div class="modal--inner">
      <div class="modal--header">
        <h3>Your checkout is about to expire</h3>
        <countdown />
      </div>
      <button @click="setWarningModal(false)" class="close">Close</button>

      <div class="button-list">
        <button class="button button--primary" @click="setWarningModal(false)">
          Continue with my order
        </button>

        <button @click="returnToHomepage" class="button button--secondary">
          Return to the homepage
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { SET_WARNING_MODAL } from '@vue/common/constants/mutation.types'
import Countdown from '@vue/common/components/countdown'

export default {
  name: 'ModalWarning',
  components: {
    Countdown
  },
  computed: {
    ...mapState('checkout', ['warningModal'])
  },
  methods: {
    ...mapMutations('checkout', [SET_WARNING_MODAL]),
    returnToHomepage() {
      window.location = '/'
    }
  }
}
</script>
