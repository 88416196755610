<template>
  <div>
    <section class="checkout">
      <div class="container">
        <button
          v-if="tryAgain"
          class="button button--primary"
          @click="goToPayment"
          data-testid="checkoutTryAgain"
        >
          Try again?
        </button>

        <button class="button button--secondary" @click="reset">
          Leave checkout
        </button>
      </div>
    </section>

    <modal-velocity v-if="velocityModal" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import ModalVelocity from './components/modal-velocity'
import { actionTypes } from '@vue/common/constants/store.types'

import { OrderStates} from '@vue/common/constants/order'
export default {
  name: 'PaymentUnsuccessful',
  components: {
    ModalVelocity
  },
  data() {
    return {
      // By default do not show button to try again
      tryAgain: false
    }
  },
  computed: {
    ...mapState('checkout', ['order', 'velocityModal'])
  },
  methods: {
    ...mapMutations({
      updateStep: 'checkout/updateStep',
      setVelocityModal: 'checkout/setVelocityModal'
    }),
    ...mapActions('checkout', [
      actionTypes.GetOrder,
      actionTypes.AbandonCheckout
    ]),
    goToPayment() {
      window.location = '/checkout/payment'
    },
    reset() {
      this.abandonCheckout().then(() => {
        window.location = '/'
      })
    }
  },
  async mounted() {
    this.updateStep('payment')

    await this.getOrder()

    switch (this.order.state.value) {
      case OrderStates.PAYMENT_PENDING:
      case OrderStates.COMPLETED: // TODO: Redirect if customer ends up here in completed state?
        this.tryAgain = true;
        break;
      case OrderStates.VELOCITY_CHECK_FAILED:
        this.setVelocityModal(true);
        break;
    }
  }
}
</script>
