import address from './address.filter'
import dateOfBirth from './date-of-birth.filter'
import date from './date.filter'
import price from './price.filter'
import rate from './rate.filter'
import nth from './nth.filter'
import times from './times.filter'

export default {
  install(Vue) {
    Vue.filter('address', address)
    Vue.filter('dateOfBirth', dateOfBirth)
    Vue.filter('date', date)
    Vue.filter('price', price)
    Vue.filter('rate', rate)
    Vue.filter('nth', nth)
    Vue.filter('times', times)
  }
}

