import './telemetry'
import './errorHandling'

// Entry point for styles (webpack)
// Don't worry about this being here, we import it for webpack dev
// In the production build its removed from bundle.js and output to its own .css
import '../styles/style.scss'

import '@vue/views/calculator/index'
import '@vue/views/cart/index'
import '@vue/views/expired/index'
import '@vue/views/branch-finder/index'
import '@vue/views/checkout/common'
import '@vue/views/checkout/review/index'
import '@vue/views/checkout/delivery/index'
import '@vue/views/checkout/details/index'
import '@vue/views/checkout/payment/index'
import '@vue/views/checkout/payment-response/index'
import '@vue/views/checkout/payment-unsuccessful/index'
import '@vue/views/checkout/confirmation/index'
import '@vue/views/chart/index'

// import '@vue/components/checkoutClickCollect/'

import objectFitImages from 'object-fit-images'
import inlineSVG from 'inline-svg'

import DataLayer from './dataLayer'
import Affiliate from './affiliate'
import Navigation from './navigation'
import Tabs from './tabs'
import Accordion from './accordion'
import GoogleCookie from './googleCookie'
import Social from './social'

GoogleCookie.setCookies();

function hasTouch() {
  return (
    'ontouchstart' in document.documentElement ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  )
}

if (hasTouch()) {
  document.body.className += ' is-touch'
}

new DataLayer() // Set up DataLayer events on DOM elements (non vue)
new Affiliate() // Set session from window variable (to be then used in vue)
new Navigation() // Setup responsive navigation
new Accordion() // Control Accordion component

document.querySelectorAll('[data-init="tabbed"]').forEach((element) => {
  new Tabs(element); // Control Tabbed components
})

document.querySelectorAll('.component-social-menu .social-link').forEach((el) => {
  new Social(el) // Open new window on social click
})

inlineSVG.init({
  svgSelector: 'img.svg',
  initClass: 'js-inlinesvg'
})

objectFitImages('.as-background img', { watchMQ: true })

// Fix for hovers
document.addEventListener('touchstart', function () { }, true)

if (module.hot) {
  module.hot.accept()
}

const cxLoyaltykeepAliveIframeUrlHack = sessionStorage.getItem(
  'cxLoyaltykeepAliveIframeUrlHack'
)

if (cxLoyaltykeepAliveIframeUrlHack) {
  const iframe = document.createElement('iframe')
  iframe.src = cxLoyaltykeepAliveIframeUrlHack
  iframe.style.display = 'none'
  iframe.setAttribute('width', 0)
  iframe.setAttribute('height', 0)
  iframe.setAttribute('tabindex', -1)
  document.body.appendChild(iframe)
}
