<template>
  <LWChart v-if="isDataLoaded" :data="data" />
</template>

<script>
  import LWChart from './components/lw-chart'
  import httpUmbraco from '@vue/services/api/httpUmbraco'
  import { DateTime } from 'luxon'

  const DATE_FORMAT = 'yyyy-MM-dd';

  export default {
    name: 'Chart',
    components: {
      LWChart
    },
    data() {
      return {
        fetchedData: [],
        isDataLoaded: false
      };
    },
    computed: {
      data() {
        const current = DateTime.now().toFormat(DATE_FORMAT);
        const lastmonth = DateTime.now().minus({ months: 1 }).toFormat(DATE_FORMAT);
        const lastweek = DateTime.now().minus({ weeks: 1 }).toFormat(DATE_FORMAT);

        const data = new Map([
          ['1Y', this.fetchedData],
          ['1M', this.fetchedData.filter(pair => pair.time >= lastmonth && pair.time <= current)],
          ['1W', this.fetchedData.filter(pair => pair.time >= lastweek && pair.time <= current)],
        ]);

        return data;
      }
    },
    mounted() {
      this.fetchData();
    },
    methods: {
      async fetchData() {
        try {
          const response = await httpUmbraco.get(`/api/currency-converter/${this.$root.currencycode.toLowerCase()}`);
          this.fetchedData = response.data.map(item => ({ time: DateTime.fromISO(item.date).toFormat(DATE_FORMAT), value: item.rate }));
          this.isDataLoaded = true;
        } catch (error) {
          console.error(error);
        }
      }
    }
  };
</script>
