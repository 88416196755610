var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.remaining != null && _vm.remaining < 1
    ? _c("div", { staticClass: "modal modal--expired" }, [
        _c("div", { staticClass: "modal--inner" }, [
          _c("h2", [_vm._v("Your cart has expired")]),
          _vm._v(" "),
          _c("div", { staticClass: "button-list" }, [
            _c(
              "button",
              {
                staticClass: "button button--primary",
                on: { click: _vm.recreateCart },
              },
              [_vm._v("\n        Recreate cart\n      ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button button--secondary",
                on: { click: _vm.AbandonCart },
              },
              [_vm._v("\n        Abandon cart\n      ")]
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }