import httpCommerce from './httpCommerce'
import { SITE_ID } from '@vue/common/constants/config'

export const getStores = () => {
  return httpCommerce.get(`/api/sites/${SITE_ID}/stores`)
}

export const getStoreById = id => {
  return httpCommerce.get(`/api/sites/${SITE_ID}/stores/${id}`)
}

export const getDefaultStore = () => {
  return httpCommerce.get(`/api/sites/${SITE_ID}/stores/default`)
}

export const getNearby = geoLocation => {
  return httpCommerce.post(`/api/sites/${SITE_ID}/stores/nearby`, geoLocation)
}

export const getOpeningTimes = id => {
  return httpCommerce.get(`/api/sites/${SITE_ID}/stores/${id}/opening-times`)
}

export const getStoreAddress = id => {
  return httpCommerce.get(`/api/sites/${SITE_ID}/stores/${id}/address`)
}

export default {
  getStores,
  getStoreById,
  getDefaultStore,
  getNearby,
  getOpeningTimes,
  getStoreAddress
}
