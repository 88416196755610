export const getAddressAsString = address => {
  function pickBy(object) {
    const obj = {}
    for (const key in object) {
      if (object[key]) {
        obj[key] = object[key]
      }
    }
    return obj
  }

  const sanitized = pickBy(address)
  
  return Object.keys(sanitized)
    .map(key => {
      return sanitized[key]
    })
    .join(', \n')
}

export default {
  getAddressAsString
}
