import { DateTime } from 'luxon'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

export const nameRegex = /^[A-Za-z ',.-]+$/;
export const isValidName = name => {
  return nameRegex.test(name);
}

export const isValidNumber = number => {
  if (number.length < 2) return false

  const phoneNumber = parsePhoneNumberFromString(number, 'GB')

  if (!phoneNumber) return false

  return phoneNumber.isValid()
}

export const isValidDateOfBirth = dateOfBirth => {
  if (!dateOfBirth.day || !dateOfBirth.month || !dateOfBirth.year) return false

  if(!((dateOfBirth.year.length) == 4))
    return false

   if(!((dateOfBirth.year) >= 1900 && (dateOfBirth.year) <= new Date().getFullYear()))
    return false

  if (!((dateOfBirth.month) >= 1 && (dateOfBirth.month) <= 12 && (dateOfBirth.year) <= new Date().getFullYear()))
      return false

  if(((dateOfBirth.month) > new Date().getMonth()+1  && (dateOfBirth.year) == new Date().getFullYear()))
    return false

  var date = new Date(dateOfBirth.year, dateOfBirth.month, 0);
  var totalDaysInMonth = parseInt(date.getDate());

  if (!((dateOfBirth.day) >= 1 && (dateOfBirth.day) <= totalDaysInMonth))
    return false

  if(((dateOfBirth.day) > new Date().getDate() && (dateOfBirth.month) == new Date().getMonth()+1  && (dateOfBirth.year) == new Date().getFullYear()))
      return false

  return DateTime.fromObject({
    year: parseInt(dateOfBirth.year),
    month: parseInt(dateOfBirth.month),
    day: parseInt(dateOfBirth.day)
  }).isValid
}

export const isPostcode = postcode => {
  if (!postcode) {
    return false
  }

  const postcodeRegEx = /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/
  return postcodeRegEx.test(postcode)
}
