var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "review--item", class: { expand: _vm.expand } },
        [
          _c("div", { staticClass: "review--content" }, [
            _c("span", [_vm._v("Currency")]),
            _vm._v(" "),
            _c("div", { staticClass: "review--row" }, [
              _c("div", { class: "icon icon__" + _vm.line.code.toLowerCase() }),
              _vm._v(" "),
              _c("div", { staticClass: "review--currency" }, [
                _c("div", { staticClass: "code" }, [
                  _vm._v(_vm._s(_vm.line.code)),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "review--quantity" }, [
            _c("span", [_vm._v("Amount")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "input_container", attrs: { tabindex: "-1" } },
              [
                _c("div", { staticClass: "Currency-Code" }, [
                  _c("small", {
                    staticClass: "currency-code",
                    domProps: { innerHTML: _vm._s(_vm.line.symbol) },
                  }),
                ]),
                _vm._v(" "),
                _c("input", {
                  attrs: { type: "text", tabindex: "0" },
                  domProps: { value: _vm.target },
                  on: {
                    keyup: function ($event) {
                      return _vm.targetToSource($event.target.value)
                    },
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _vm.expand
              ? _c("div", { staticClass: "review--quantity-displaySecond" }, [
                  _vm._v(
                    "\n        You'll get " +
                      _vm._s(_vm._f("price")(_vm.qty, _vm.line.symbol)) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "review--content" }, [
            _c("span", [_vm._v("Rate")]),
            _vm._v(" "),
            _vm.rate
              ? _c("strong", [_vm._v(_vm._s(_vm._f("rate")(_vm.rate)))])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "review--quantity" }, [
            _c("span", [_vm._v("Cost")]),
            _vm._v(" "),
            _c("div", { staticClass: "input", attrs: { tabindex: "-1" } }, [
              _c("small", { staticClass: "currency-code" }, [_vm._v("£")]),
              _vm._v(" "),
              _c("input", {
                attrs: { maxlength: "4", type: "text", tabindex: "0" },
                domProps: { value: _vm.source },
                on: {
                  keyup: function ($event) {
                    return _vm.sourceToTarget($event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _vm.expand
              ? _c("div", { staticClass: "review--quantity-display" }, [
                  _vm._v(
                    "\n        You'll pay " +
                      _vm._s(_vm._f("price")(_vm.price)) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "review--buttons" }, [
            _c(
              "button",
              {
                staticClass: "button button--small button--primary",
                attrs: {
                  name: "addCurrency",
                  id: "addToCart",
                  disabled: _vm.target === 0,
                },
                on: { click: _vm.addNewLine },
              },
              [_vm._v("\n        Add to cart\n      ")]
            ),
            _vm._v(" "),
            _c("button", {
              staticClass: "close",
              attrs: { name: "removeCurrency" },
              on: {
                click: function ($event) {
                  return _vm.$emit("remove", _vm.line.code)
                },
              },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.shoppingCart.product.id !== 3
        ? _c("tiered-message", {
            attrs: {
              targetQuantity: _vm.target,
              CurrencyRate: _vm.currencyRate,
              line: _vm.line,
            },
            on: { boostRate: _vm.boostRate },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.line.currencyRestrictions.length && _vm.shoppingCart.product.id !== 3
        ? _c(
            "div",
            { staticClass: "notification restriction" },
            _vm._l(
              _vm.line.currencyRestrictions,
              function (restriction, index) {
                return _c("p", { key: index }, [
                  _vm._v("\n      " + _vm._s(restriction.message) + "\n    "),
                ])
              }
            ),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }