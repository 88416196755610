var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "checkout" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "view-cart" },
        [
          _vm.cartLoading && !_vm.cartEmpty
            ? _c("Spinner", {
                attrs: { name: "circle", color: "var(--color-links)" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.cartEmpty && !_vm.cartLoading
            ? _c("div", { staticClass: "checkout--empty" }, [
                _c("h3", [
                  _vm._v("Your cart is empty, add some currency to continue"),
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "button button--primary",
                    attrs: { href: "/" },
                  },
                  [_vm._v(" Leave checkout ")]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "checkout--review" }, [
            !_vm.cartEmpty && !_vm.cartLoading
              ? _c(
                  "section",
                  { staticClass: "review" },
                  [
                    _c("div", { staticClass: "review--header" }, [
                      _c("span", [_vm._v("Currency")]),
                      _vm._v(" "),
                      _c("span", [_vm._v("Amount")]),
                      _vm._v(" "),
                      _c("span", [_vm._v("Rate")]),
                      _vm._v(" "),
                      _vm.shoppingCart.product.id !== 3
                        ? _c("span", [_vm._v("Cost")])
                        : _c("span", [_vm._v("You will receive")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "review--delete" }),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.shoppingCart.lines, function (line) {
                      return _c("ReviewItem", {
                        key: line.currency.code,
                        attrs: { line: line },
                      })
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.filteredCurrencies.length > 0
              ? _c(
                  "section",
                  { staticClass: "review review--add" },
                  [
                    _c("h4", [_vm._v("Add new currency")]),
                    _vm._v(" "),
                    _c("CurrencySelect", {
                      attrs: { currencies: _vm.filteredCurrencies },
                      on: {
                        selected: function ($event) {
                          return _vm.selectCurrency($event)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.selected.length
                      ? _c("div", { staticClass: "review--header" }, [
                          _c("span", [_vm._v("Currency")]),
                          _vm._v(" "),
                          _c("span", [_vm._v("Amount")]),
                          _vm._v(" "),
                          _c("span", [_vm._v("Rate")]),
                          _vm._v(" "),
                          _vm.shoppingCart.product.id !== 3
                            ? _c("span", [_vm._v("Cost")])
                            : _c("span", [_vm._v("You will receive")]),
                          _vm._v(" "),
                          _c("span", { staticClass: "review--delete" }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.selected, function (line) {
                      return _c("ReviewItemNew", {
                        key: line.code,
                        attrs: { line: line },
                        on: {
                          remove: function ($event) {
                            return _vm.removeCurrency($event)
                          },
                        },
                      })
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.cartEmpty && !_vm.cartLoading
              ? _c("div", { staticClass: "form" }, [
                  _vm.COMMERCE_SHOW_BUYBACK || _vm.COMMERCE_SHOW_REFUND
                    ? _c(
                        "form",
                        {
                          attrs: { autocomplete: "off", novalidate: "" },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.onSubmit($event)
                            },
                          },
                        },
                        [
                          _vm.COMMERCE_SHOW_BUYBACK &&
                          _vm.shoppingCart.product.id !== 3
                            ? _c("div", { staticClass: "checkout-inputs" }, [
                                _c("h4", [
                                  _vm._v(
                                    "Would you like to add a Buyback Guarantee?"
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.IsPayPoint
                                  ? _c("p", [
                                      _vm._v(
                                        "\n                Return up to 30% of your travel money at exactly the same\n                exchange rate, up to 30 days after your purchase date. On\n                average our customers who use our Buyback Guarantee are £20\n                better off. Please note currency can only be returned to a eurochange branch.\n              "
                                      ),
                                    ])
                                  : _c("p", [
                                      _vm._v(
                                        "\n                Return up to 30% of your travel money at exactly the same\n                exchange rate, up to 30 days after your purchase date. On\n                average our customers who use our Buyback Guarantee are £20\n                better off\n              "
                                      ),
                                    ]),
                                _vm._v(" "),
                                _vm.shoppingCart.total < 150
                                  ? _c(
                                      "div",
                                      { staticClass: "notification warning" },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            "\n                  Your cart is too small to use the Buyback Guarantee. Please\n                  add more currency to your cart. Our minimum spend is £" +
                                              _vm._s(
                                                _vm.IsPayPoint ? 200 : 150
                                              ) +
                                              ".\n                "
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "input",
                                    class: { validator: _vm.$v.buyback.$error },
                                  },
                                  [
                                    _c("div", { staticClass: "radio" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.buyback,
                                            expression: "buyback",
                                          },
                                        ],
                                        attrs: {
                                          type: "radio",
                                          name: "buyback",
                                          id: "buyback-agree",
                                          tabindex: "0",
                                          disabled:
                                            _vm.shoppingCart.total < 150,
                                        },
                                        domProps: {
                                          value: true,
                                          checked: _vm._q(_vm.buyback, true),
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.buyback = true
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        { attrs: { for: "buyback-agree" } },
                                        [
                                          _vm._v(
                                            "\n                    I'm likely to return home with unused travel money -\n                    please add the Buyback Guarantee to my order for £4\n                  "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "radio" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.buyback,
                                            expression: "buyback",
                                          },
                                        ],
                                        attrs: {
                                          type: "radio",
                                          name: "buyback",
                                          id: "buyback-decline",
                                          tabindex: "0",
                                          disabled:
                                            _vm.shoppingCart.total < 150,
                                        },
                                        domProps: {
                                          value: false,
                                          checked: _vm._q(_vm.buyback, false),
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.buyback = false
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        { attrs: { for: "buyback-decline" } },
                                        [
                                          _vm._v(
                                            "\n                    I'm certain I won't have any travel money left on my\n                    return - do not add the Buyback Guarantee\n                  "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    !_vm.$v.buyback.required
                                      ? _c("span", { staticClass: "error" }, [
                                          _vm._v(
                                            "\n                  Please select your Buyback guarantee option\n                "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.COMMERCE_SHOW_REFUND &&
                          _vm.shoppingCart.product.id !== 3
                            ? _c("div", { staticClass: "checkout-inputs" }, [
                                _c("h4", [
                                  _vm._v(
                                    "Would you like to add a Refund Guarantee?"
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm._m(0),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "input",
                                    class: { validator: _vm.$v.refund.$error },
                                  },
                                  [
                                    _c("div", { staticClass: "radio" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.refund,
                                            expression: "refund",
                                          },
                                        ],
                                        attrs: {
                                          type: "radio",
                                          name: "refund",
                                          id: "refund-agree",
                                          tabindex: "0",
                                        },
                                        domProps: {
                                          value: true,
                                          checked: _vm._q(_vm.refund, true),
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.refund = true
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        { attrs: { for: "refund-agree" } },
                                        [
                                          _vm._v(
                                            "\n                    I would like to add Refund Guarantee to my order for £3.\n                  "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "radio" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.refund,
                                            expression: "refund",
                                          },
                                        ],
                                        attrs: {
                                          type: "radio",
                                          name: "refund",
                                          id: "refund-decline",
                                          tabindex: "0",
                                        },
                                        domProps: {
                                          value: false,
                                          checked: _vm._q(_vm.refund, false),
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.refund = false
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        { attrs: { for: "refund-decline" } },
                                        [
                                          _vm._v(
                                            "\n                    I do not want to add Refund Guarantee to my order.\n                  "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    !_vm.$v.refund.required
                                      ? _c("span", { staticClass: "error" }, [
                                          _vm._v(
                                            "\n                  Please select your Refund guarantee option\n                "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("h4", [
                              _vm.shoppingCart.product.id !== 3
                                ? _c("span", [_vm._v("Cart total")])
                                : _c("span", [
                                    _vm._v("Total you will receive"),
                                  ]),
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm._f("price")(_vm.shoppingCart.total)
                                  ) +
                                  "\n              "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "spendMessage",
                              attrs: { if: "spendRequiementMessage != ''" },
                            },
                            [_vm._v(_vm._s(_vm.spendRequiementMessage))]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "row" },
                            [
                              _vm.orderLoading
                                ? _c("Spinner", {
                                    attrs: {
                                      name: "circle",
                                      color: "var(--color-links)",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.orderLoading
                                ? _c("input", {
                                    staticClass: "button button--primary",
                                    attrs: {
                                      type: "submit",
                                      value: "Proceed to checkout",
                                      disabled: _vm.checkMinMaxSpendRequirement,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      )
                    : _c(
                        "form",
                        {
                          attrs: { autocomplete: "off", novalidate: "" },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.onSubmit($event)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("h4", [
                              _vm.shoppingCart.product.id !== 3
                                ? _c("span", [_vm._v("Cart total")])
                                : _c("span", [
                                    _vm._v("Total you will receive"),
                                  ]),
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm._f("price")(_vm.shoppingCart.total)
                                  ) +
                                  "\n              "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.shoppingCart.total > 0 &&
                          _vm.spendRequiementMessage != ""
                            ? _c("span", { staticClass: "spendMessage" }, [
                                _vm._v(_vm._s(_vm.spendRequiementMessage)),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "row" },
                            [
                              _vm.orderLoading
                                ? _c("Spinner", {
                                    attrs: {
                                      name: "circle",
                                      color: "var(--color-links)",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.orderLoading
                                ? _c("input", {
                                    staticClass: "button button--primary",
                                    attrs: {
                                      type: "submit",
                                      value: "Proceed to checkout",
                                      disabled: _vm.checkMinMaxSpendRequirement,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n                In the event that your holiday is cancelled by your travel\n                provider, you can return your order in its entirety, up to 30\n                days after your purchase date, for a full refund.\n                "
      ),
      _c("a", { attrs: { target: "_blank", href: "/company/legal/terms" } }, [
        _vm._v("Terms and conditions"),
      ]),
      _vm._v(" apply.\n              "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }