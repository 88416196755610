var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal modal--warning" }, [
    _c("div", { staticClass: "modal--inner" }, [
      _c(
        "div",
        { staticClass: "modal--header" },
        [
          _c("h3", [_vm._v("Additional identification is required")]),
          _vm._v(" "),
          _c("countdown"),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          on: {
            click: function ($event) {
              return _vm.setAmlModal(false)
            },
          },
        },
        [_vm._v("Close")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "button-list" }, [
        _c(
          "button",
          {
            staticClass: "button button--primary",
            on: { click: _vm.continueWithOrder },
          },
          [_vm._v("\n        Continue with my order\n      ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "button button--secondary",
            on: { click: _vm.returnToHomepage },
          },
          [_vm._v("\n        Return to the homepage\n      ")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n      Due to the volume of transactions you have completed, additional\n      identification is required to collect your order, please provide a proof\n      of id (driving licence/passport/national ID card) and a proof of address\n      (driving licence, if not used as primary/bank statement/utility bill)\n      dated within 3 months. If you have any question please do not hesitate\n      to contact us on\n      "
      ),
      _c("a", { attrs: { href: "tel:0333 240 1687", title: "call" } }, [
        _vm._v("\n        0333 240 1687\n      "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }