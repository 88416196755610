var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.order !== null
    ? _c("address", [
        _vm.order.product.id === 1 &&
        _vm.order.customer &&
        _vm.order.customer.firstName &&
        _vm.order.customer.lastName
          ? _c("span", [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.order.customer.firstName) +
                  " " +
                  _vm._s(_vm.order.customer.lastName) +
                  ","
              ),
              _c("br"),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.order.deliveryAddress.buildingNameNumber
          ? _c("span", [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.order.deliveryAddress.buildingNameNumber) +
                  ","
              ),
              _c("br"),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.order.deliveryAddress.address1
          ? _c("span", [
              _vm._v(
                "\n    " + _vm._s(_vm.order.deliveryAddress.address1) + ","
              ),
              _c("br"),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.order.deliveryAddress.address2
          ? _c("span", [
              _vm._v(
                "\n    " + _vm._s(_vm.order.deliveryAddress.address2) + ","
              ),
              _c("br"),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.order.deliveryAddress.address3
          ? _c("span", [
              _vm._v(
                "\n    " + _vm._s(_vm.order.deliveryAddress.address3) + ","
              ),
              _c("br"),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.order.deliveryAddress.address4
          ? _c("span", [
              _vm._v(
                "\n    " + _vm._s(_vm.order.deliveryAddress.address4) + ","
              ),
              _c("br"),
            ])
          : _vm._e(),
        _vm._v("\n  " + _vm._s(_vm.order.deliveryAddress.postcode)),
        _c("br"),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }