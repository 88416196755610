<template>
  <div v-if="order">
    <section class="checkout">
      <div class="container">
        <div class="checkout--content">
          <DeliverySlotCard v-for="(deliverySlot, index) in options" :key="index" :deliverySlot="deliverySlot"
            v-model="selected" />

          <div class="row delivery--next">
            <Spinner name="circle" color="var(--color-links)" v-if="loading" />
            <button class="button button--primary" @click="selectDeliverySlot(selected.id)" v-if="!loading"
              :disabled="!selected">
              Next
            </button>
          </div>
        </div>

        <div class="info-container">
          <div class="parent-container">
            <order-summary :deliverySlot="selected" />

            <div v-html="summaryBoxContent" v-if="summaryBoxContent" class="info"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import GoogleCookie from '@/googleCookie'

import { CREATED, COMPLETED } from '@vue/common/constants/order'
import { actionTypes } from '@vue/common/constants/store.types'
import { setGoogleInfo, setDeliverySlot } from '@vue/services/api/checkout.api'

import Spinner from 'vue-spinkit'
import OrderSummary from '@vue/views/checkout/common/order-summary'
import DeliverySlotCard from './components/DeliverySlotCard'
import { getDeliverySlots } from '@vue/services/api/checkout.api'


export default {
  name: 'Delivery',
  components: {
    Spinner,
    DeliverySlotCard,
    OrderSummary,
  },
  data() {
    return {
      loading: false,
      selected: null,
      options: []
    }
  },
  computed: {
    ...mapState({
      order: (state) => state.checkout.order,
      store: (state) => state.store.store,
      content: (state) => state.checkout.content,
    }),
    storeName() {
      return this.order.store.brandName == ''
        ? this.order.store.name
        : `${this.order.store.brandName} - ${this.order.store.name}`
    },
    summaryBoxContent() {
      if(!this.content) {
        return null;
      }

      // HACK: This div is shown only for click&collect, should be handled better.
      if(this.order.product.id !== 2) {
        return null;
      }

      // TODO: Remove paypoint hacks, paypoint is a brand not a whitelabel concept.
      return this.order.store.brand === 'PP'
        ? this.content.deliveryoptionsforccpaypoint
        : this.content.deliveryoptionsforcc;
    }
  },
  watch: {
    order(newValue) {
      { { this.order.state } }
      if (!newValue) return
      if (this.order.state.value === COMPLETED) {
        window.location = '/'
      }
      if (
        this.order.state.value !== CREATED ||
        this.order.state.value === COMPLETED
      ) {
        window.location = window.checkoutWorkflow.error
      }
    }
  },
  methods: {
    ...mapMutations({
      updateStep: 'checkout/updateStep',
      expiredOrder: 'checkout/expiredOrder',
    }),
    ...mapActions('checkout', [actionTypes.GetOrder]),
    selectDeliverySlot(id) {
      this.loading = true;
      setDeliverySlot(id)
        .then(() => {
          window.location = window.checkoutWorkflow.next
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.updateStep('delivery')
    this.getOrder()

    getDeliverySlots()
      .then(({ data }) => {
        this.options = data;
      });

    setGoogleInfo(GoogleCookie.getCookieValues())
  },
}
</script>
