import Vue from 'vue'

import store from '@vue/store/index'
import filters from '@vue/common/filters'
import Delivery from './delivery'

if (document.getElementById('checkout-select-delivery-slot')) {

  Vue.use(filters)

  new Vue({
    el: '#checkout-select-delivery-slot',
    render: createElement => createElement(Delivery),
    store
  })
}
