var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.CurrencyRate.rateTiers && _vm.CurrencyRate.rateTiers.length > 0
    ? _c("div", { staticClass: "tiered-message" }, [
        _vm.nextTier()
          ? _c("p", [
              _vm._v("\n    Buy\n    "),
              _c("strong", [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm._f("price")(_vm.nextTier().amount, _vm.line.symbol)
                    ) +
                    "\n      " +
                    _vm._s(_vm.line.shortName) +
                    "\n    "
                ),
              ]),
              _vm._v("\n    or more to\n    "),
              _c(
                "button",
                { staticClass: "boost", on: { click: _vm.boostRate } },
                [_vm._v("boost your rate")]
              ),
              _vm._v(" and\n    receive our improved rate\n  "),
            ])
          : _vm.nextTier() == false
          ? _c("p")
          : _c("p", [
              _vm._v(
                "\n    Rate fully boosted! You're receiving our Best Rate\n  "
              ),
            ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }