<template>
  <div class="calculator-select">
    <div class="options tabs" tabindex="-1">
      <button
        v-for="(option, index) in options"
        :key="index"
        :class="{ active: type === option.id }"
        @click.prevent="setCalculator(option.id)"
        class="tab"
      >
        {{ option.label }} Travel Money
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { BUY_ID, SELL_ID } from '@vue/common/constants/config'
import { convert } from '@vue/services/currency.service'
export default {
  name: 'CalculatorSelect',
  data() {
    return {
      options: [
        {
          label: 'Buy',
          id: BUY_ID,
        },
        {
          label: 'Sell',
          id: SELL_ID,
        },
      ],
      calculatorSet: false,
    }
  },
  computed: {
    ...mapGetters({
      enabledProducts: 'product/enabledProducts',
      rate: 'currency/rate',
    }),
    ...mapState({
      selectedProduct: (state) => state.product.selectedProduct,
      type: (state) => state.calculator.type,
    }),
  },
  watch: {
    type(type) {
      this.toggleStoreSelect(true)
      this.setProduct(this.enabledProducts[0])
      this.getCurrencyRate()
      this.abandonCart()
    },
    selectedProduct() {
      if ((window.sellCurrencyPage ==="pageSellTravelMoney" || window.isSellcurrency === "True") && !this.calculatorSet) {
        this.$store.commit('calculator/setCalculator', SELL_ID);
        this.calculatorSet = true;
      }
    },
  },
  methods: {
    ...mapActions({
      toggleStoreSelect: 'calculator/toggleStoreSelect',
      getCurrencyRate: 'currency/getCurrencyRate',
      abandonCart: 'cart/abandonCart',
    }),
    ...mapMutations({
      setProduct: 'product/setProduct',
    }),
    setCalculator(id) {
    this.$store.commit('calculator/setCalculator',id);
    },
  },
}
</script>

<style scoped>
.tab {
  border-radius: 0;
  border: none;
  padding: 14px 25px;
  margin: 0;
  height: 100%;
  width: 100%;
  margin-bottom: 0;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  font-weight: 700;
  font-size: 13px;
  line-height: 16.25px;
  border-color: var(--color-primary);
  background: var(--color-primary);
  color: #fff;
}

.tab.active {
  border: 1px solid #fff;
  background-color: #fff;
  color: var(--color-primary);
  outline: none;
}

a:hover {
  color: var(--color-primary);
  background: #fff;
}
</style>
