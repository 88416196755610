export default value => {
  const dateArr = value.match(/[^\s,]+/g)
  const ordinal = getOrdinal(dateArr[1])

  return `${dateArr[0]} ${dateArr[1]}${ordinal} ${dateArr[2]}`
}

const getOrdinal = day => {
  if (day > 3 && day < 21) return 'th'
  switch (day % 10) {
  case 1:
    return 'st'
  case 2:
    return 'nd'
  case 3:
    return 'rd'
  default:
    return 'th'
  }
}
