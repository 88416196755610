import Vue from 'vue'
import Chart from './chart'

if (document.getElementById('chart')) {
  new Vue({
    el: '#chart',
    render: createElement => createElement(Chart),
    created: function () {
      this.currencycode = window.currencycode
    }
  })
}
