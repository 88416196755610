<template>
  <div
    class="amount-select"
    v-if="selectedCurrency && selectedProduct"
    :class="{ 'visibility-hidden': !currencyRate }">
    <div class="label">
      <div class="selling row">
        <span>{{
          content.amountSelectSellLabel || '2. Amount to Sell Back'
        }}</span>
        <div class="row">
          <span>You will receive</span>
          <Tooltip :content="content.sellingTooltip" />
        </div>
      </div>
      <div class="row"></div>
    </div>
    <div class="inputs">
      <div class="input" tabindex="-1">
        <small class="currency-code" v-html="selectedCurrency.symbol"></small>
        <input
          type="number"
          min="0"
          @keyup="onUpdate"
          :value="source"
          tabindex="0"
        />
      </div>
        <div class="row">
          <div class="icon icon__gbp"></div>
          <span class="currency-code">£</span>
          <span class="sell">{{ target.toFixed(2) }}</span>
        </div>
    </div>
  </div>
</template>

<script>
import BigNumber from 'bignumber.js'
import { mapMutations, mapState, mapGetters } from 'vuex'
import { DEFAULT_FC } from '@vue/common/constants/config'

import Tooltip from '@vue/common/components/Tooltip'


export default {
  name: 'AmountSelectSell',
  components: {
    Tooltip,
  },
  data() {
    return {
      debounceTimer: null,
      lastHashCode: null,
      DEFAULT_FC
    }
  },
  computed: {
    ...mapState({
      content: (state) => state.calculator.content,
      currencyRate: (state) => state.currency.currencyRate,
      selectedCurrency: (state) => state.currency.selectedCurrency,
      selectedProduct: (s) => s.product.selectedProduct,
      source: (s) => s.currency.source,
      target: (s) => s.currency.target,
    }),
  },
  watch: {
    'currencyRate': {
      handler(value) {
        if(this.lastHashCode == value.hashCode) {
          return; // When a store is picked the rates are reloaded from the new store (but rates don't change per store anymore).
        }
        this.lastHashCode = value.hashCode;
        this.updateSource(DEFAULT_FC);
      },
      immediate: true,
    }
  },
  methods: {
    ...mapMutations({
      setTargetQuantity: 'currency/setTargetQuantity',
      setSource: 'currency/setSource',
      setTarget: 'currency/setTarget'
    }),
    onUpdate(event){
      if(event.key == 'Tab') {
        return;
      }

      this.resetTimer();

      this.debounceTimer = setTimeout(() => {
        this.resetTimer();

        const rawQty = event.target.value;
        const qty = Number(rawQty) || 0;

        this.updateSource(qty);
      }, 500)
    },
    canUpdate() {
      // This component shouldn't ever receive rates for other products, but right now it does so handle it until refactor.
      return this.currencyRate.rate && this.selectedProduct?.id == 3;
    },
    updateSource(qty) {
      if(!this.canUpdate()){
        this.reset();
        return;
      }
      const source = BigNumber(qty) // FC
        .dp(2, BigNumber.ROUND_HALF_EVEN)
        .toNumber();

      const target = BigNumber(qty) // GBP
        .div(this.currencyRate.rate)
        .dp(2, BigNumber.ROUND_HALF_EVEN)
        .toNumber();

      this.setSource(source);
      this.setTarget(target);
      this.setTargetQuantity(Math.floor(source))
    },
    reset() {
      this.setSource(0);
      this.setTarget(0);
      this.setTargetQuantity(0);
    },
    resetTimer(){
      clearTimeout(this.debounceTimer);
      this.debounceTimer = null;
    }
  },
}
</script>
