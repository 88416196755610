import httpUmbraco from './httpUmbraco'
import httpCommerce from './httpCommerce'
import { getToken } from '@vue/services/jwt.service'

export const getContent = () => {
  return httpUmbraco.get('/api/content/checkout')
}

export const beginCheckout = () => {
  return httpCommerce.post('/api/order/begin-checkout', null, {
    headers: {
      Authorization: `bearer ${getToken()}`
    }
  })
}

export const getOrder = () => {
  return httpCommerce.get('/api/order', {
    headers: {
      Authorization: `bearer ${getToken()}`
    }
  })
}

export const createPaymentIntent = () => {
  return httpCommerce.post('/api/order/create-payment-intent', null, {
    headers: {
      Authorization: `bearer ${getToken()}`
    }
  })
}

export const getDeliverySlots = () => {
  return httpCommerce.get('/api/order/delivery-slots', {
    headers: {
      Authorization: `bearer ${getToken()}`
    }
  })
}

export const setDeliverySlot = id => {
  return httpCommerce.put('/api/order/delivery-slot', id, {
    headers: {
      Authorization: `bearer ${getToken()}`
    }
  })
}

export const setGoogleInfo = info => {
  return httpCommerce.put('/api/order/ga-info', info, {
    headers: {
      Authorization: `bearer ${getToken()}`
    }
  })
}

export const setCustomerInfo = customer => {
  return httpCommerce.put('/api/order/customer-info', customer, {
    headers: {
      Authorization: `bearer ${getToken()}`
    }
  })
}

export default {
  getContent,
  beginCheckout,
  getOrder,
  getDeliverySlots,
  setDeliverySlot,
  setGoogleInfo,
  setCustomerInfo
}
