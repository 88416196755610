var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cart" }, [
    _c(
      "button",
      {
        staticClass: "cart--toggle",
        on: {
          click: function ($event) {
            return _vm.toggleCart(!_vm.showCart)
          },
        },
      },
      [
        _c("span", { staticClass: "icon" }, [
          _vm.shoppingCart && _vm.shoppingCart.lines.length != 0
            ? _c("div", { staticClass: "count" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.shoppingCart.lines.length) +
                    "\n      "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("img", {
            staticClass: "svg",
            attrs: { src: "/assets/img/icons/basket.svg" },
          }),
        ]),
      ]
    ),
    _vm._v(" "),
    _vm.showCart
      ? _c(
          "div",
          {
            directives: [
              {
                name: "on-clickaway",
                rawName: "v-on-clickaway",
                value: _vm.hideCart,
                expression: "hideCart",
              },
            ],
            staticClass: "cart--container",
          },
          [
            _c("div", { staticClass: "cart--flex" }, [
              _vm.loading
                ? _c(
                    "div",
                    { staticClass: "spinner" },
                    [
                      _c("Spinner", {
                        attrs: { name: "circle", color: "var(--color-links)" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.loading && !_vm.shoppingCart
                ? _c("p", [_vm._v("No currency in your cart")])
                : _vm._e(),
              _vm._v(" "),
              !_vm.loading && _vm.shoppingCart
                ? _c(
                    "div",
                    [
                      _c("countdown"),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "cart--items" },
                        _vm._l(_vm.shoppingCart.lines, function (line) {
                          return _c("cart-item", {
                            key: line.id,
                            attrs: { line: line },
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "summary" }, [
                        _c("p", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.shoppingCart.lines.length)),
                          ]),
                          _vm._v(" "),
                          _vm.shoppingCart.lines.length === 1
                            ? _c("span", [
                                _vm._v(
                                  "\n              currency in your cart\n            "
                                ),
                              ])
                            : _c("span", [_vm._v("currencies in your cart")]),
                        ]),
                        _vm._v(" "),
                        _c("h5", { staticClass: "total" }, [
                          _vm._v("\n            Total to pay "),
                          _c("strong", [
                            _vm._v(
                              _vm._s(_vm._f("price")(_vm.shoppingCart.total))
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button button--primary",
                          on: {
                            click: function ($event) {
                              return _vm.redirectToCheckout()
                            },
                          },
                        },
                        [_vm._v("\n          Review & checkout\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button button--cart",
                          on: {
                            click: function ($event) {
                              return _vm.abandonCart()
                            },
                          },
                        },
                        [_vm._v("\n          Empty cart\n        ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }