var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("section", { staticClass: "checkout" }, [
        _c("div", { staticClass: "container" }, [
          _vm.tryAgain
            ? _c(
                "button",
                {
                  staticClass: "button button--primary",
                  attrs: { "data-testid": "checkoutTryAgain" },
                  on: { click: _vm.goToPayment },
                },
                [_vm._v("\n        Try again?\n      ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "button button--secondary",
              on: { click: _vm.reset },
            },
            [_vm._v("\n        Leave checkout\n      ")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.velocityModal ? _c("modal-velocity") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }