<template>
  <ul class="items steps">
    <li class="step" :class="{ active: steps.review }">
      <span>Review</span>
    </li>
    <li class="step" :class="{ active: steps.delivery }">
      <span v-if="productId !== 3">Delivery</span>
      <span v-else>Collection</span>
    </li>
    <li class="step" :class="{ active: steps.information }">
      <span>Details</span>
    </li>
    <li v-if="productId !== 3" class="step" :class="{ active: steps.payment }">
      <span>Payment</span>
    </li>
    <li class="step" :class="{ 'active-success': steps.confirmation }">
      <span>Confirmation</span>
    </li>
  </ul>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Steps',
  computed: {
    ...mapState({
      steps: state => state.checkout.steps,
      shoppingCart: state => state.cart.shoppingCart,
      order: state => state.checkout.order
    }),
    productId: function() {
      if (this.shoppingCart !== null) return this.shoppingCart.product.id

      if (this.order !== null) return this.order.product.id
    }
  }
}
</script>
