import Vue from 'vue'

import store from '@vue/store/index'
import Information from './information'
import Vuelidate from 'vuelidate'
import filters from '@vue/common/filters'

if (document.getElementById('checkout-customer-information')) {
  Vue.use(Vuelidate)
  Vue.use(filters)

  window.addEventListener('load', function() {
    new Vue({
      el: '#checkout-customer-information',
      render: createElement => createElement(Information),
      store
    })
  })
}
