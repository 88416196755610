const Social = class {
  constructor(el) {
    // Each share link
    this.el = el
    // The pages url
    this.href = window.location.href
    // The page title tag (without pipe content)
    this.title = document.title.split(/\|/g)[0]
    // Facebook share URL
    this.facebook = 'http://www.facebook.com/sharer.php?u='
    // Twitter share url
    this.twitter = 'https://twitter.com/share?url='
    // LinkedIn share url
    this.linkedin = 'http://www.linkedin.com/shareArticle?url='

    // Create UI Elements
    this._init_ui()
  }

  /**
   * We need to assign the correc URL to each share link
   */
  _init_ui() {
    // Get the ID of the link (the network)
    const checkId = this.el.getAttribute('id')

    // Switch statement to create the urls
    switch (checkId) {
      case 'facebook':
        this.el.href = this.facebook + this.href
        break
      case 'twitter':
        this.el.href = this.twitter + this.href + '&text=' + this.title
        break
      case 'linked-in':
        this.el.href = this.linkedin + this.href + '&title=' + this.title
        break
    }

    // Bind events
    this._events()
  }

  /**
   * Create the click event, which opens a new smaller sharing window
   */
  _events() {
    this.el?.addEventListener('click', _e => {
      _e.preventDefault()

      // The name we give the window
      const className = this.el.getAttribute('class')

      // The url we pass into the window
      const url = this.el.href

      // The size of the window
      const size = { h: 370, w: 550 }

      // Position on the X axis we want the window to sit on (centered)
      const xPos = (window.innerWidth - size.w) / 2

      // Window options
      let options = `height=${size.h}`
      options += `,width=${size.w}`
      options += `,left=${xPos}`
      options += ',top=60,screenX=' + xPos
      options += ',screenY=60'

      // Create the new window
      const newWindow = window.open(url, className, options)

      // Focus the new window
      if (window.focus) {
        newWindow.focus()
      }
    })
  }
}

export default Social
