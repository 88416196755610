<template>
  <div>
    <div class="container">
      <section
               class="checkout--confirmation"
               v-if="order"
        id="order-confirmation-print"
      >
        <div class="confirmation--header">
          <div class="print-logo">
            <img src="/logo-mobile" />
          </div>

          <div class="confirmation--title">
            <h4>Order Confirmation</h4>
            <span class="order-ref">{{ order.externalOrderId }}</span>
            <span>{{ nowDate }}</span>
          </div>

          <button class="button button--primary" @click="print">
            Print order confirmation
          </button>
        </div>

        <confirmation-summary v-if="order.product.id !== 3" />

        <div class="insert--cart-lines__total"v-if="order.product.id !== 3">
            <b>Total</b>
            <h4>{{ order.total | price }}</h4>
        </div>

        <div class="confirmation--delivery" v-if="order.deliverySlot">
          <div class="column">
            <div>
              <b v-if="order.product.id === 1">Delivery method</b>
              <b v-else>Collection details</b>
              <span>
                {{ order.deliverySlot.deliveryMethodName }}
              </span>
              <span>
                {{ order.deliverySlot.date | date }}
              </span>
            </div>
          </div>

          <div class="column">
            <div>
              <b v-if="order.product.id === 1">Delivery address</b>
              <b v-else>Collection address</b>
              <delivery-address :order="order" />
            </div>
          </div>
        </div>

        <div class="confirmation--delivery" v-if="!order.store.isMeta">
          <div class="column">
            <times :storeId="order.store.id" />
          </div>
        </div>

        <confirmation-summary v-if="order.product.id === 3" />

        <div class="insert--cart-lines__total" v-if="order.product.id == 3">
            <b>Total you'll receive</b>
            <h4>{{ order.total | price }}</h4>
        </div>

        <h4>Your details</h4>
        <customer :customer="order.customer" :store="order.store" />
      </section>
    </div>
  </div>
</template>

<script>
import { hasBearerToken, deleteBearerToken } from '@vue/services/jwt.service'
import { mapActions, mapMutations, mapState } from 'vuex'
import Times from '@vue/common/components/times'
import DeliveryAddress from '@vue/views/checkout/common/delivery-address'
import Customer from './components/customer'
import ConfirmationSummary from './components/confirmation-summary'
import { submitOrdertoDotDigital } from '@vue/services/dotdigital.service'
const productIdToName = (id) => {
  switch(id) {
    case 1:
      return 'HD'
    case 2:
      return 'PC'
    case 3:
      return 'SB'
    default:
      return 'NA'
  }
}

export default {
  name: 'Confirmation',
  components: {
    Times,
    ConfirmationSummary,
    DeliveryAddress,
    Customer,
  },
  computed: {
    ...mapState({
      content: (state) => state.checkout.content,
      order: (state) => state.checkout.order,
    }),
    nowDate() {
      return new Date(Date.now()).toLocaleDateString()
    },
  },
  methods: {
    ...mapMutations({
      updateStep: 'checkout/updateStep',
    }),
    ...mapActions({
      getOrder: 'checkout/getOrder',
    }),
    print() {
      window.print()
    },
    setConfirmationSession(order) {
      const affiliateCode = sessionStorage.getItem('affiliateCode') || ''
      const GoogleTransaction = {
        transactionId: order.externalOrderId,
        transactionAffiliation: `${window.commerceSettings.siteName} ${affiliateCode}`,
        transactionTotal: order.total,
        transactionTax: 0,
        transactionShipping: order.deliverySlot.price,
        transactionProducts: order.lines.map((line) => {
          return {
            sku: line.currency.code,
            name: productIdToName(order.product.id),
            category: 'Cash', // When travel card exists - add it here
            price: line.price,
            quantity: line.qty,
          }
        }),
      }

      window.sessionStorage.setItem(
        'confirmationData',
        JSON.stringify(GoogleTransaction)
      )
    },
  },
  watch: {
    order(order) {
      if (order !== null) {
        this.setConfirmationSession(order)
      }

      if (this.order.paymentAttempt && !this.order.paymentAttempt.success)
        window.checkoutWorkflow.error
    },
  },
  async mounted() {
    this.updateStep('confirmation')
    if (hasBearerToken()) await this.getOrder()
    if(this.order && this.order.customer && this.order.customer.email)
      submitOrdertoDotDigital(this.order, this.order.customer.email, 'ORDER_COMPLETE');   
    // Once we've loaded the order we can remove the token - the checkout process is finished
    deleteBearerToken()
  },
}
</script>

<style scoped>
@media print {
  body *:not(#order-confirmation-print *) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}
</style>
