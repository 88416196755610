var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", { staticClass: "items steps" }, [
    _c("li", { staticClass: "step", class: { active: _vm.steps.review } }, [
      _c("span", [_vm._v("Review")]),
    ]),
    _vm._v(" "),
    _c("li", { staticClass: "step", class: { active: _vm.steps.delivery } }, [
      _vm.productId !== 3
        ? _c("span", [_vm._v("Delivery")])
        : _c("span", [_vm._v("Collection")]),
    ]),
    _vm._v(" "),
    _c(
      "li",
      { staticClass: "step", class: { active: _vm.steps.information } },
      [_c("span", [_vm._v("Details")])]
    ),
    _vm._v(" "),
    _vm.productId !== 3
      ? _c(
          "li",
          { staticClass: "step", class: { active: _vm.steps.payment } },
          [_c("span", [_vm._v("Payment")])]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "li",
      {
        staticClass: "step",
        class: { "active-success": _vm.steps.confirmation },
      },
      [_c("span", [_vm._v("Confirmation")])]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }