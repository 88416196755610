<template>
  <div class="opening-times">
    <b>Opening times</b>
    <div v-if="validTimes">
      <div
        class="row"
        v-if="openingTimes.mondayOpens && openingTimes.mondayCloses"
      >
        <span>Monday</span>
        <span>
          {{ openingTimes.mondayOpens | times }} -
          {{ openingTimes.mondayCloses | times }}
        </span>
      </div>
      <div
        class="row"
        v-if="openingTimes.tuesdayOpens && openingTimes.tuesdayCloses"
      >
        <span>Tuesday</span>
        <span>
          {{ openingTimes.tuesdayOpens | times }} -
          {{ openingTimes.tuesdayCloses | times }}
        </span>
      </div>
      <div
        class="row"
        v-if="openingTimes.wednesdayOpens && openingTimes.wednesdayCloses"
      >
        <span>Wednesday</span>
        <span>
          {{ openingTimes.wednesdayOpens | times }} -
          {{ openingTimes.wednesdayCloses | times }}
        </span>
      </div>
      <div
        class="row"
        v-if="openingTimes.thursdayOpens && openingTimes.thursdayCloses"
      >
        <span>Thursday</span>
        <span>
          {{ openingTimes.thursdayOpens | times }} -
          {{ openingTimes.thursdayCloses | times }}
        </span>
      </div>
      <div
        class="row"
        v-if="openingTimes.fridayOpens && openingTimes.fridayCloses"
      >
        <span>Friday</span>
        <span>
          {{ openingTimes.fridayOpens | times }} -
          {{ openingTimes.fridayCloses | times }}
        </span>
      </div>
      <div
        class="row"
        v-if="openingTimes.saturdayOpens && openingTimes.saturdayCloses"
      >
        <span>Saturday</span>
        <span>
          {{ openingTimes.saturdayOpens | times }} -
          {{ openingTimes.saturdayCloses | times }}
        </span>
      </div>
      <div
        class="row"
        v-if="openingTimes.sundayOpens && openingTimes.sundayCloses"
      >
        <span>Sunday</span>
        <span>
          {{ openingTimes.sundayOpens | times }} -
          {{ openingTimes.sundayCloses | times }}
        </span>
      </div>
    </div>
    <div v-else>
      <div class="store--stock closed" v-if="!isLoading">
        <p>Sorry we're closed this week!</p>
      </div>
    </div>
    <p
      class="opening-times--comment"
      v-if="validTimes && openingTimes.openingTimeComment !== null"
    >
      {{ openingTimes.openingTimeComment }}
    </p>
  </div>
</template>

<script>
  import { getOpeningTimes } from '@vue/services/api/store.api'

  export default {
    name: 'Times',
    props: ['storeId'],
    data() {
      return {
        openingTimes: null,
        isLoading: true,
      }
    },
    computed: {
      validTimes() {
        const result = this.isEmpty(this.openingTimes)
        return !result && !this.isLoading
      }
    },
    mounted() {
      getOpeningTimes(this.storeId).then(response => {
        this.openingTimes = response.data
        if (this.openingTimes !== null) {
          this.isLoading = false;
        }
      })
    },
    methods: {
      isEmpty(value){
        return (
          value == null || (typeof value === 'object' && Object.keys(value).length === 0)
        )
      }
    }
  }
</script>
