import { getsitesettings } from '@vue/services/api/cart.api'
import { SITE_ID } from '@vue/common/constants/config'
export const submitOrdertoDotDigital = (orderInfo, emailAddress, orderStatus) => {
    getsitesettings(SITE_ID).then(({ data }) => {
        let siteInfo = data;
        if (orderInfo && orderInfo.lines && siteInfo && siteInfo.isSiteAllowed) {
            // Set affiliate code
            const affiliateCode = sessionStorage.getItem('affiliateCode') || null
            let programId = "";
            let cartId = ""; 
            let orderTypeUrl = ""; 
            let guarantee = "";

            if (orderInfo.product.id === 3) {
                programId = siteInfo.sellProgramId;
                cartId = siteInfo.sellCartId + orderInfo.id;
                orderTypeUrl = "/selling/view-cart";
            }
            else {
                programId = siteInfo.buyProgramId;
                cartId = siteInfo.buyCartId + orderInfo.id;
                orderTypeUrl = "/checkout/view-cart";
            }

            if (orderInfo && orderInfo.buyBackGuarantee === true) {
                guarantee = orderInfo.product.name + ":" + (orderInfo.deliverySlot.price !== 0 ? (orderInfo.currency.symbol + orderInfo.deliverySlot.price) : "Free") + ", " + "Buyback Guarantee" + ":" + orderInfo.currency.symbol + 4;
            } else {
                guarantee = orderInfo.product.name + ":" + (orderInfo.deliverySlot.price !== 0 ? (orderInfo.currency.symbol + orderInfo.deliverySlot.price) : "Free");
            }
            if (orderInfo && orderInfo.refundGuarantee === true) {
                guarantee = orderInfo.product.name + ":" + (orderInfo.deliverySlot.price !== 0 ? (orderInfo.currency.symbol + orderInfo.deliverySlot.price) : "Free") + ", " + "Refund Guarantee" + ":" + orderInfo.currency.symbol + 3;
            }
            let dotDigitalItems = []; 
            let total = 0;            
            dotDigitalItems = orderInfo.lines.map(function (line) {                
                return {
                    sku: line.currency.code,
                    name: line.currency.name,
                    description: line.currency.name,
                    category: "",
                    "other": guarantee,
                    "unitPrice": line.price,
                    "salePrice": line.rate.toString().slice(0, (line.rate.toString().indexOf(".")) + 5),
                    "quantity": line.qty,
                    "totalPrice": line.total.toFixed(2),
                    "imageUrl": window.location.origin + "/assets/img/icons/flags/" + line.currency.code.toLowerCase() + ".svg",
                    "productUrl": ""
                }
            });
            total = orderInfo.lines.reduce((tot, line) => {
                return tot + line.total
            }, 0);
            if (programId != "") {
                (function (w, d, u, t, o, c) { w['dmtrackingobjectname'] = o; c = d.createElement(t); c.async = 1; c.src = u; t = d.getElementsByTagName(t)[0]; t.parentNode.insertBefore(c, t); w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments); }; })(window, document, '//static.trackedweb.net/js/_dmptv4.js', 'script', 'dmPt');

                window.dmPt('create', siteInfo.siteIdentification, siteInfo.siteURLAllowed);
                window.dmPt('track');
                window.dmPt("identify", emailAddress);
                window.dmPt("cartInsight", {
                    "programID": programId,
                    "cartDelay": siteInfo.cartDelay,
                    "cartID": cartId,
                    "cartPhase": orderStatus,
                    "currency": "GBP",
                    "subtotal": total.toFixed(2),
                    "discountAmount": 0,
                    "taxAmount": 0,
                    "grandTotal": orderInfo.total.toFixed(2),
                    "cartUrl": affiliateCode == null ? window.location.origin + orderTypeUrl + "?id=" + (orderInfo.cartId) : window.location.origin + orderTypeUrl + "?id=" + orderInfo.cartId + "&" + "affiliateid=" + affiliateCode,
                    "lineItems": dotDigitalItems
                });
            }
        }
    })
}

export default {
    submitOrdertoDotDigital
}
