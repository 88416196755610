var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-google-autocomplete", {
        attrs: {
          id: "GooglePlaces",
          classname: "form-control",
          placeholder: "Enter your postcode, town or city",
          country: "gb",
          types: "",
        },
        on: { placechanged: _vm.onPlaceSelected },
      }),
      _vm._v(" "),
      _vm.location
        ? _c("branch-finder-modal", {
            attrs: { location: _vm.location },
            on: {
              close: function ($event) {
                return _vm.reset()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }