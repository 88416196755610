const Accessibility = class {
  constructor() {
    this.selectItems = null
    this.item = null
    this.list = null
  }

  itemWatcher(index) {
    this.selectItems.forEach(el => {
      el.classList.remove('accessibility-hover')
    })

    this.item = this.selectItems[index]
    this.item.classList.add('accessibility-hover')
    this.item.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest'
    })
    this.item.focus()
  }

  nextTick(dropdown) {
    this.list = dropdown.children[0]
    this.selectItems = [...this.list.querySelectorAll('.option')]
    this.handleAccessibleEvents()
  }

  keypressUp(index) {
    return index === 0 ? index : index - 1
  }

  keypressDown(index) {
    return index >= this.selectItems.length - 1 ? index : index + 1
  }

  handleAccessibleEvents() {
    this.list.focus()
    this.list.addEventListener(
      'keydown',
      e => {
        if (
          ['Space', 'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown'].indexOf(
            e.key
          ) > -1
        ) {
          e.preventDefault()
        }
      },
      false
    )
  }
}

export default new Accessibility()
