<template>
  <div class="confirmation--customer">
    <div class="column">
      <b>Name</b>
      <p>
        {{ customer.title }}
        {{ customer.firstName }}
        {{ customer.lastName }}
      </p>
      <b v-if="!store.isMeta">Home address</b>
      <b v-if="store.isMeta">Home and delivery address</b>
      <address>{{ customer.address | address }}</address>
    </div>

    <div class="column">
      <b>Date of birth</b>
      <p>{{ customer.dateOfBirth | dateOfBirth }}</p>

      <b>Email address</b>
      <p>{{ customer.email }}</p>

      <b>Phone number</b>
      <p>{{ customer.phoneNumber }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Customer',
  props: ['customer', 'store']
}
</script>
