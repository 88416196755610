import { getCurrencies, getRate } from '@vue/services/api/currency.api'
import { setDefaultCurrency } from '@vue/services/currency.service'
import { getParameterByName } from '@vue/services/calculator.service'
import { showError } from '@vue/services/toast.service'
import {
  SET_CURRENCIES,
  SET_CURRENCY,
  SET_CURRENCY_RATE,
} from '@vue/common/constants/mutation.types'

import { actionTypes } from '@vue/common/constants/store.types'

const initialState = {
  currencies: [],
  selectedCurrency: null,
  currencyRate: {
    rate: null,
    price: null,
    hashCode: null
  },
  target: 0,
  source: 0,
  targetQuantity: null,
}

const state = { ...initialState }

const actions = {
  getCurrencies({ commit, dispatch }) {
    getCurrencies().then(({ data }) => {
      commit(SET_CURRENCIES, data)
      let queryCurrency = getParameterByName('currency')

      let bc = localStorage.getItem("selcurr");
      if (bc !== null) {
        queryCurrency = bc;
        localStorage.removeItem("selcurr");
      }
      queryCurrency === null
        ? commit(SET_CURRENCY, setDefaultCurrency(data))
        : commit(
          SET_CURRENCY,
          data.find(x => x.code.toUpperCase() === queryCurrency.toUpperCase())
        )
      dispatch(actionTypes.GetCurrencyRate)
    })
  },
  /**
   * GetCurrencyRate
   * Gets the currency rate from the API and sets it in the store
   * @param {Object} { commit, rootState }
   * @param selectedProduct
   * @param selectedCurrency
   * @param defaultStore
   *
   * @returns {Object} currencyRate - set in the store
   */
  getCurrencyRate({ commit, rootState }) {
    // If there is a selected product and currency and default store
    if (
      !rootState.product.selectedProduct ||
      !rootState.store.defaultStore ||
      !state.selectedCurrency
    )
      return

    const { store, product } = rootState

    // Set affiliate code
    const affiliateCode = sessionStorage.getItem('affiliateCode')
      ? sessionStorage.getItem('affiliateCode')
      : null

    // Commerce API call for currency rate
    getRate({
      storeId: store.store.id,
      productId: product.selectedProduct.id,
      currency: state.selectedCurrency.code,
      affiliateCode: affiliateCode,
    }).then(({ data }) => {
      data.hashCode = `${data.currencyCode}_${data.rate}`;
      commit(SET_CURRENCY_RATE, data)
    })
    .catch((err) => {
      if (err && err.response && err.response.data)
        showError(err.response.data.message);
    });
  },
  selectCurrency({ commit, dispatch }, currency) {
    commit(SET_CURRENCY_RATE, {
      rate: null,
      price: null,
      hashCode: null,
    })
    commit(SET_CURRENCY, currency)
    dispatch(actionTypes.GetCurrencyRate)
    dispatch('store/clearStores', null, { root: true })
  },
}

const mutations = {
  [SET_CURRENCIES](state, currencies) {
    state.currencies = currencies
  },
  [SET_CURRENCY](state, currency) {
    state.selectedCurrency = currency
  },
  [SET_CURRENCY_RATE](state, rate) {
    state.currencyRate = rate
  },
  setTargetQuantity(state, targetQuantity) {
    state.targetQuantity = targetQuantity
  },
  setSource(state, source) {
    state.source = source
  },
  setTarget(state, target) {
    state.target = target
  },
}

const getters = {
  filteredCurrencies: (state, getters, rootState) => {
    // If there are no selected product or store, return all currencies
    if (!rootState.product.selectedProduct) {
      return []
    }

    // Copy the currencies array
    let currencies = [...state.currencies]

    // If there is a shopping cart, return only the currencies that are not in the cart
    if (rootState.cart.shoppingCart !== null) {
      const cartCurrencies = rootState.cart.shoppingCart.lines.map(
        (line) => line.currency
      )
      currencies = currencies.filter(
        (currency) => !cartCurrencies.includes(currency.code)
      )
    }

    // Filter currencies by product using availiability matrix
    return currencies.filter((currency) => {
      if (rootState.calculator.matrix !== null) {
        return rootState.calculator.matrix.hasCurrencyForProduct(
          currency.code,
          rootState.product.selectedProduct.id
        )
      }
    })
  }
}

const currency = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}

export default currency
