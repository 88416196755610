const Affiliate = class {
  constructor() {
    const affiliateCode = this.getUrlParameter('affiliateid') || window.affiliateCode

    if (affiliateCode)
      this.setSession(affiliateCode)
  }

  getUrlParameter(name) {
    const query = window.location.search.substring(1)
    const vars = query.split('&')

    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=')
      if (pair[0].toLowerCase() === name)
        return pair[1]
    }

    return (false)
  }

  setSession(code) {
    sessionStorage.setItem('affiliateCode', code)

    const date = new Date().setDate(new Date().getDate() - 1)
    document.cookie = `affiliateCode=${code};expires=${date};SameSite=Lax;path=/`
  }
}

export default Affiliate