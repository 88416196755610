<template>
  <input
         type="number"
         v-model.number="value.qty"
         @blur="checkQty"
         @input="onInput"
         ref="lineqty"
         />
</template>

<script>
  import { mapActions } from 'vuex'
  import { showError } from '@vue/services/toast.service'
  export default {
    name: 'Quantity',
    props: ['value'],
    data() {
      return {
        inputTimeout: null,
      };
    },
    computed: {
      quantity: {
        get() {
          return this.value.qty
        },
        set(newValue) {
          return newValue
        },
      },
    },
    methods: {
      ...mapActions({
        updateLine: 'cart/updateLine',
      }),
      onChange() {
        if (this.quantity <= 0) {
          return
        }
        this.updateLine({
          id: this.value.id,
          code: this.value.currency.code,
          quantity: this.quantity,
        })

      },
      onInput() {
        clearTimeout(this.inputTimeout);
        this.inputTimeout = setTimeout(() => {
          this.onChange();
        }, 600);
      },
      checkQty() {
        if (this.quantity <= 0) {
          showError('Amount should be greater than 0')
          this.$refs.lineqty.select();
          this.$refs.lineqty.focus()
        }
      },
    },
  }
</script>
