<template>
  <div class="currencies" v-if="currencies">
    <div class="currency-select">
      <div class="select">
        <div class="select-styled" tabindex="0" :class="{ active: !hidden }" :close-selects="hideSelect"
          v-on-clickaway="hideSelect" v-focus @click="onCurrencySearchFocus">
          <div class="location-item" v-if="selected || editMode">
            <template v-if="editMode">
              <input type="text" class="search"  v-model="searchText" ref="txtCurrencyCode" autocomplete="off"
                @focus="onCurrencySearchFocus" />
            </template>
            <template v-if="!editMode">
              <div class="icon" :class="'icon__' + selected.code.toLowerCase()">
              </div>
              <div class="code">
                {{ selected.code }}
              </div>
              <div class="label">
                {{ selected.pluralName }}
              </div>
            </template>
          </div>
          <div v-else>Select a currency</div>
        </div>

        <div class="select-custom items" v-if="!hidden" ref="selectDropdown">
          <div class="list" tabindex="0">
            <div
              class="option"
              v-for="(currency, index) in searchCurrency"
              :key="index"
              tabindex="-1"
              @keyup.enter="selectCurrency(currency)"
              @click="selectCurrency(currency)"
             >
              <div class="location-item">
                <div
                  class="icon"
                  :class="'icon__' + currency.code.toLowerCase()"
                     ></div>
                <div class="code">{{ currency.code }}</div>
                <div class="label">{{ currency.pluralName }}</div>
              </div>
            </div>
            <div class="option" v-if="noCurrencyFound">No results found</div>
          </div>
        </div>
      </div>
    </div>

    <div
         class="notification restriction"
         v-if="
           currency &&
           currency.currencyRestrictions.length &&
           selectedProduct.id !== 3
        "
         >
      <p
         v-for="(restriction, index) in currency.currencyRestrictions"
         :key="index"
         >
        {{ restriction.message }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'
import { getParameterByName } from '@vue/services/calculator.service'
import Accessibility from '@vue/common/accessibility'

export default {
  mixins: [clickaway],
  name: 'CurrencySelect',
  props: {
    currencies: {
      type: Array,
      required: true,
    },
    currency: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      selectedOption: null,
      selected: null,
      hidden: true,
      itemIndex: -1,
      arrowCounter: 0,
      noCurrencyFound: false,
      searchText: '',
      editMode: false
    }
  },
  computed: {
    ...mapState({
      selectedProduct: (state) => state.product.selectedProduct,
    }),
    searchCurrency() {
      if (this.searchText && this.searchText != '') {
        let currenttext = this.searchText
        let searchResult = this.currencies.filter(function (el) {
          return (
            el.pluralName.toLowerCase().indexOf(currenttext.toLowerCase()) !==
            -1 ||
            el.code.toLowerCase().indexOf(currenttext.toLowerCase()) !== -1
          )
        })
        this.noCurrencyFound = (searchResult.length == 0)
        return searchResult
      } else {
        return this.currencies
      }
    },
    isCurrencyChanged() {
      let result = this.selected && this.searchText != this.selected.code
      if (result)
        this.hidden = false;
      return result
    },
  },
  methods: {
    onCurrencySearchFocus() {
      this.editMode = true;
      this.searchText = "";
      this.hidden = false;
      this.$nextTick(
        function () {
          this.$refs.txtCurrencyCode.focus();
        }.bind(this)
      );
    },
    selectCurrency(currency) {
      // Set the selected currency
      this.editMode = false;
      this.selected = currency
      // If currency exists in the query string, replace it with the new currency without reloading
      const queryCurrency = getParameterByName('currency')
      if (queryCurrency) {
        const url = new URL(window.location.href)
        url.searchParams.set('currency', currency.code)
        window.history.pushState({}, '', url)
      }

      // Push the selected currency to the list of selected currencies
      this.$emit('selected', currency)
    },
    hideSelect() {
      this.editMode = false;
      this.hidden = true
      if (this.selected && (this.searchText == '' || this.searchText != this.selected.code)) {
        this.searchText = this.selected.code
      }
    },
    selectedclick() {
      this.hidden = false;
      this.editMode = true;
    },
    keypressEnter() {
      this.hidden = !this.hidden

      this.$nextTick(() => {
        Accessibility.nextTick(this.$refs.selectDropdown)
      })
    },
    keypressUp() {
      this.itemIndex = Accessibility.keypressUp(this.itemIndex)
    },
    keypressDown() {
      this.itemIndex = Accessibility.keypressDown(this.itemIndex)
    },
  },
  mounted() {
    if (this.currency) {
      this.selected = this.currency
      this.searchText = this.currency.code
    }
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.hideSelect()
      }
    })
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus
      },
    },
  },
}
</script>
