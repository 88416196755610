export const CREATED = 0
export const VELOCITY_CHECK_FAILED = 1
export const PAYMENT_PENDING = 3
export const CHECKOUT_FAILED = 16
export const COMPLETED = 18


export const OrderStates = {
  CREATED,
  VELOCITY_CHECK_FAILED,
  PAYMENT_PENDING,
  CHECKOUT_FAILED,
  COMPLETED
}

export default OrderStates;
