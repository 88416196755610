import Vue from 'vue'
import Vuelidate from 'vuelidate'

import store from '@vue/store'
import filters from '@vue/common/filters'
import Review from './review'

if (document.getElementById('checkout-view-cart')) {
  Vue.use(Vuelidate)
  Vue.use(filters)

  new Vue({
    el: '#checkout-view-cart',
    render: createElement => createElement(Review),
    store
  })
}
