var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal modal--store" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "on-clickaway",
            rawName: "v-on-clickaway",
            value: _vm.closeStoreModal,
            expression: "closeStoreModal",
          },
        ],
        ref: "modalContainer",
        staticClass: "modal--inner",
      },
      [
        _c("div", { staticClass: "top" }, [
          _c("h2", [
            _vm._v(_vm._s(_vm.content.storeSelectTitle || "Select a branch")),
          ]),
          _vm._v(" "),
          _c("h5", [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.content.storeSelectCopy ||
                    "Collect your order from one of our branches"
                ) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "close",
              on: {
                click: function ($event) {
                  return _vm.closeStoreModal()
                },
              },
            },
            [_vm._v("close")]
          ),
        ]),
        _vm._v(" "),
        _vm.loading
          ? _c(
              "div",
              { staticClass: "spinner" },
              [
                _c("Spinner", {
                  attrs: { name: "circle", color: "var(--color-links)" },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.loading
          ? _c("div", [
              !_vm.IsPayPoint
                ? _c("div", [
                    _c("small", { staticClass: "stores-disclaimer" }, [
                      _vm._v(
                        "\n        When your order is ready for collection, we will send you an email to\n        confirm. If you have provided your mobile number, we will also send\n        you a text message.\n      "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "stores",
                  class: { "set-margin": !_vm.IsPayPoint },
                },
                _vm._l(_vm.currentPage, function (store, index) {
                  return _c("store", { key: index, attrs: { store: store } })
                }),
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "pager" }, [
                _c(
                  "button",
                  {
                    staticClass: "button button--secondary",
                    attrs: { disabled: _vm.pageNumber == 0 },
                    on: { click: _vm.prevPage },
                  },
                  [_vm._v("\n          Previous\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button button--secondary",
                    attrs: { disabled: _vm.pageNumber >= _vm.pageCount - 1 },
                    on: { click: _vm.nextPage },
                  },
                  [_vm._v("\n          Next\n        ")]
                ),
              ]),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }