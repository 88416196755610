<template>
  <div class="timer">
    <p>Cart available for...</p>
    <strong class="countdown">{{ remaining }}</strong>
  </div>
</template>

<script>
import { Duration } from 'luxon'
import { mapMutations, mapState } from 'vuex'

import {
  SET_WARNING_MODAL
} from '@vue/common/constants/mutation.types'

import { getCartExpiryUnixTimestamp, calculateSecondsUntilCartExpires } from '@vue/services/cart.service'

export default {
  name: 'Countdown',
  data() {
    return {
      remaining: null,
      finished: false,
      tick: null
    }
  },
  computed: {
    ...mapState('cart', ['shoppingCart']),
    ...mapState('checkout', ['steps'])
  },
  methods: {
    ...mapMutations('checkout', [SET_WARNING_MODAL]),
    init() {
      if (!this.tick) {
        clearInterval(this.tick)
      }

      const cartExpiryTimestamp = getCartExpiryUnixTimestamp();

      if (cartExpiryTimestamp === null) {
        return;
      }

      this.tick = setInterval(() => {
        const remaining = calculateSecondsUntilCartExpires(cartExpiryTimestamp)
        this.finished = remaining <= 0
        this.remaining = Duration.fromMillis(remaining * 1000).toFormat('mm:ss')

        if (remaining == 300) {
          this.setWarningModal(true)
        }
      }, 200)
    }
  },
  watch: {
    shoppingCart() {
      this.init()
    },
    finished() {
      if (this.finished) {
        clearInterval(this.tick)
      }
    },
    steps() {
      if (this.steps.confirmation) {
        clearInterval(this.tick)
      }
    }
  },
  mounted() {
    this.init()
  }
}
</script>
