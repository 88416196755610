var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.exitModal
    ? _c("div", { staticClass: "modal modal--exit" }, [
        _c("div", { staticClass: "modal--inner" }, [
          _c("h2", [_vm._v(_vm._s(_vm.content.leaveTitle))]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.content.leaveText))]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "close",
              on: {
                click: function ($event) {
                  return _vm.setExitModal(false)
                },
              },
            },
            [_vm._v("Close")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "button-list" }, [
            !_vm.steps.confirmation
              ? _c(
                  "button",
                  {
                    staticClass: "button button--primary",
                    on: {
                      click: function ($event) {
                        return _vm.setExitModal(false)
                      },
                    },
                  },
                  [_vm._v("\n        Continue with my order\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button button--secondary",
                on: { click: _vm.reset },
              },
              [_vm._v("\n        Leave checkout\n      ")]
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }