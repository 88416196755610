import { getProducts, getSiteProducts } from '@vue/services/api/product.api'

import { BUY_ID, SELL_ID } from '@vue/common/constants/config'
import { SET_PRODUCTS, SET_PRODUCT, SET_SITEPRODUCTS } from '@vue/common/constants/mutation.types'
import { actionTypes } from '@vue/common/constants/store.types'
const initialState = {
  products: [],
  selectedProduct: null,
  siteProducts: []
}

const state = { ...initialState }

const actions = {
  [actionTypes.GetProducts]({ commit, rootState }) {
    getProducts().then(({ data }) => {
      commit(
        SET_PRODUCTS,
        data.filter((product) => product.cartsEnabled)          
      )
      
      rootState.cart.shoppingCart
        ? commit(SET_PRODUCT, rootState.cart.shoppingCart.product)
        : window.doc_type === 'pageHomeDelivery'
          ? commit(SET_PRODUCT, state.products[0])  
          : commit(SET_PRODUCT, state.products[1]) // Initial product C&C
    })
  },
  [actionTypes.GetSiteProducts]({ commit, rootState }) {
    getSiteProducts().then(({ data }) => {
      commit(SET_SITEPRODUCTS,data)      
    })
  },
}

const mutations = {
  [SET_PRODUCTS](state, products) {
    state.products = products
  },
  [SET_PRODUCT](state, product) {
    state.selectedProduct = product
  },
  [SET_SITEPRODUCTS](state, siteProducts) {
    state.siteProducts = siteProducts
  }
}

const getters = {
  enabledProducts: (state, getters, rootState) => {
    if (!state.products) return []
    if (!rootState.currency.selectedCurrency) return []

    const type = rootState.calculator.type

    const allProducts = state.products.filter((product) => {
      if (type === BUY_ID) {
        if (product.id === 1 || product.id === 2) {
          return product
        }
      }

      if (type === SELL_ID) {
        if (product.id === 3 || product.id === 4) {
          return product
        }
      }
    })

    const products = allProducts.map((product) => {
      product.enabled = rootState.calculator.matrix.hasProductForCurrency(
        product.id,
        rootState.currency.selectedCurrency.code
      )

      return product
    })

    return products.reverse()
  },
}

const product = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}

export default product
