export class AvailabilityMatrix {
  constructor(matrix) {
    this.matrix = matrix
  }

  hasProductForCurrency(productId, currencyCode) {
    return this.matrix.some(item => item.currencyCode === currencyCode && item.productId === productId)
  }

  hasCurrencyForProduct(currencyCode, productId) {
    return this.matrix.some(item => item.currencyCode === currencyCode && item.productId === productId)
  }
}
