import httpCommerce from './httpCommerce'
import { SITE_ID } from '@vue/common/constants/config'

export const getCurrencies = () => {
  return httpCommerce.get('/api/currencies')
}

export const getRate = params => {
  const url = params.affiliateCode
    ? `/api/sites/${SITE_ID}/stores/${params.storeId}/products/${params.productId}/rates/currency/${params.currency}?affiliateCode=${params.affiliateCode}`
    : `/api/sites/${SITE_ID}/stores/${params.storeId}/products/${params.productId}/rates/currency/${params.currency}`
  return httpCommerce.get(url)
}

export const getRates = params => {
  return httpCommerce.get(
    `/api/sites/${SITE_ID}/stores/${params.storeId}/products/${params.productId}/rates/`
  )
}

export default { getCurrencies, getRate, getRates }
