var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "headers" }, [
        _c("b", [_vm._v("Currency")]),
        _vm._v(" "),
        _c("b", [_vm._v("Amount")]),
        _vm._v(" "),
        _c("b", [_vm._v("Rate")]),
        _vm._v(" "),
        _vm.order.product.id !== 3
          ? _c("b", [_vm._v("Cost")])
          : _c("b", [_vm._v("You'll receive")]),
      ]),
      _vm._v(" "),
      _vm._l(_vm.order.lines, function (line, index) {
        return _c("div", { key: index, staticClass: "confirmation--line" }, [
          _c("div", { staticClass: "order-currency" }, [
            _c("div", {
              class: "icon icon__" + line.currency.code.toLowerCase(),
            }),
            _vm._v(" "),
            _c("strong", [_vm._v(_vm._s(line.currency.code))]),
          ]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(line.qty))]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm._f("rate")(line.rate)))]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm._f("price")(line.total)))]),
        ])
      }),
      _vm._v(" "),
      _vm.COMMERCE_SHOW_BUYBACK && _vm.order.buyBackGuarantee
        ? _c("div", { staticClass: "confirmation--line" }, [
            _c("b", [_vm._v("Buyback Guarantee")]),
            _vm._v(" "),
            _c("p"),
            _vm._v(" "),
            _c("p"),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm._f("price")(4)))]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.COMMERCE_SHOW_REFUND && _vm.order.refundGuarantee
        ? _c("div", { staticClass: "confirmation--line" }, [
            _c("b", [_vm._v("Refund Guarantee")]),
            _vm._v(" "),
            _c("p"),
            _vm._v(" "),
            _c("p"),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm._f("price")(3)))]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.order.deliverySlot
        ? _c("div", { staticClass: "confirmation--line" }, [
            _c("b", [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.order.deliverySlot.deliveryMethodName) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _c("p"),
            _vm._v(" "),
            _c("p"),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n      " +
                  _vm._s(_vm._f("price")(_vm.order.deliverySlot.price)) +
                  "\n    "
              ),
            ]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }