var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "store" }, [
      _c("div", { staticClass: "store--inner" }, [
        _c("div", { class: "store--brand__" + _vm.branch.brand.toLowerCase() }),
        _vm._v(" "),
        _c("h3", [_vm._v(_vm._s(_vm.branch.name))]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Distance: " +
              _vm._s(_vm.branch.distanceInMiles.toFixed(2)) +
              " miles"
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "store--info" },
      [
        _c("OpeningTimes", {
          model: {
            value: _vm.branch.openingTimes,
            callback: function ($$v) {
              _vm.$set(_vm.branch, "openingTimes", $$v)
            },
            expression: "branch.openingTimes",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "store--address" }, [
          _c("b", [_vm._v("Address")]),
          _vm._v(" "),
          _c("div", { staticClass: "address" }, [
            _c("span", [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.branch.brand) +
                  "\n          " +
                  _vm._s(_vm.branch.name) +
                  ",\n        "
              ),
            ]),
            _vm._v(" "),
            _vm.branch.address.line1
              ? _c("span", [
                  _vm._v(" " + _vm._s(_vm.branch.address.line1) + ", "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.branch.address.line2
              ? _c("span", [
                  _vm._v(" " + _vm._s(_vm.branch.address.line2) + ", "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.branch.address.line3
              ? _c("span", [
                  _vm._v(" " + _vm._s(_vm.branch.address.line3) + ", "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.branch.address.postcode))]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "brand", class: _vm.branch.brand }),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "button button--primary button--small",
        attrs: { href: _vm.branch.url },
      },
      [_vm._v("\n    View Details\n  ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }