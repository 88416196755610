export const actionTypes = {
  GetCurrencyRate: 'getCurrencyRate',
  GetProducts: 'getProducts',
  GetNearby: 'getNearby',
  ToggleResult: 'toggleResult',
  SetResult: 'setResult',
  ToggleStoreModal: 'toggleStoreModal',
  ClearStores: 'clearStores',
  GetStoreAddress: 'getStoreAddress',
  GetCheckoutContent: 'getCheckoutContent',
  AbandonCheckout: 'abandonCheckout',
  GetOrder: 'getOrder',
  AddLine: 'addLine',
  IsBuyBack: 'isBuyBack',
  IsRefund: 'isRefund',
  UpdateLine: 'updateLine',
  DeleteLine: 'deleteLine',
  GetSiteProducts: 'getSiteProducts'
}

export const commitTypes = {
  SetCalculatorContent: 'SET_CALCULATOR_CONTENT',
  SetCurrencies: 'SET_CURRENCIES',
  SetCurrency: 'SET_CURRENCY',
  SetCurrencyRate: 'SET_CURRENCY_RATE',
  SetTargetQuantity: 'setTargetQuantity',
  SetProducts: 'SET_PRODUCTS',
  SetProduct: 'setProduct',
  SetSiteProducts: 'SET_SITEPRODUCTS',
  SetOpeningTimes: 'SET_OPENING_TIMES',
  SetStoreAddress: 'SET_STORE_ADDRESS',
  SetStores: 'SET_STORES',
  ClearStores: 'clearStores',
  SetResult: 'SET_RESULT',
  SetStoreSelect: 'SET_STORE_SELECT',
  SetStoreModal: 'SET_STORE_MODAL',
  LoadingCart: 'loadingCart',
  ToggleCart: 'toggleCart',
  SetCart: 'SET_CART',
  RemoveLine: 'REMOVE_LINE',
  ClearCart: 'CLEAR_CART',
  ExpiredOrder: 'expiredOrder',
  SetAddressString: 'setAddressString',
  SetExitModal: 'setExitModal',
  SetWarningModal: 'setWarningModal',
  SetAddressModal: 'setAddressModal',
  SetVelocityModal: 'setVelocityModal'
}