const maxAge = 30 * 24 * 60 * 60; // 30 days.

const GoogleCookie = class {

  setCookies() {
    const params = new URLSearchParams(window.location.search);

    this.storeQueryStringValue(params, 'utm_source')
    this.storeQueryStringValue(params, 'utm_medium')
    this.storeQueryStringValue(params, 'utm_campaign')
    this.storeQueryStringValue(params, 'utm_content')
    this.storeQueryStringValue(params, 'utm_term')
    this.storeQueryStringValue(params, 'utm_id')
    this.storeQueryStringValue(params, 'gclid')
    this.storeQueryStringValue(params, 'msclkid')
  }

  getCookieValues() {
    const cookies = Object.fromEntries(
      document.cookie
        .split(';')
        .map(x => x.split('=').map(x => decodeURIComponent(x.trim())))
    )

    return {
      UTM_Source: cookies['utm_source'] || '',
      UTM_Medium: cookies['utm_medium'] || '',
      UTM_Campaign: cookies['utm_campaign'] || '',
      UTM_Content: cookies['utm_content'] || '',
      UTM_Term: cookies['utm_term'] || '',
      UTM_Id: cookies['utm_id'] || '',
      Gclid: cookies['gclid'] || '',
      Msclkid: cookies['msclkid'] || ''
    }
  }

  storeQueryStringValue(params, name) {
    const value = params.get(name) || ''
    if(value) {
      document.cookie = `${name}=${value};max-age=${maxAge};SameSite=Lax;path=/;`
    }
  }
}

export default new GoogleCookie()
