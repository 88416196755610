import { showError } from '@vue/services/toast.service'
import { deleteBearerToken } from '@vue/services/jwt.service'
import { COMMERCE_API_URL } from '@vue/common/constants/config'
import { CartExpired } from '@vue/common/errors/cartExpired'
import { trackError } from '@/telemetry'
import axios from 'axios'

axios.defaults.withCredentials = true
axios.defaults.headers.common['Content-type'] = 'application/json'

const instance = axios.create({
  baseURL: COMMERCE_API_URL
});

const onRejected = (error) => {
  const response = error.response;

  if(!response) {
    showError('An unexpected error occurred.');
    return Promise.reject(error);
  }

  if (response.status === 500) {
    showError('An unexpected error occurred.');
    return Promise.reject(error);
  }

  if (response.status === 401) {
    deleteBearerToken();
    showError('Cart has expired and is no longer retrievable.');

    // Delay redirect to give customer time to read toasts.
    setTimeout(() => {
      window.location.assign("/")
    }, 1000);

    return Promise.reject(new CartExpired());
  }

  const errors = response.data.errors;
  if (errors) {
    Object.keys(errors).forEach(key => {
      errors[key].forEach(message => showError(message));
    });
    return Promise.reject(error);
  }

  if (response.headers['content-type'].includes('problem+json')) {
    showError(response.data.detail || response.data.title);
    return Promise.reject(error);
  }

  // Ensure this ends up in sentry.io as it's a weird response.
  trackError(error);
  return Promise.reject(error);
};

const onFulfilled = (response) => {
  return Promise.resolve(response);
}

instance.interceptors.response.use(onFulfilled, onRejected);

export default instance;
