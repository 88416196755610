<template>
  <div class="row">
    <div class="headers">
      <b>Currency</b>
      <b>Amount</b>
      <b>Rate</b>
      <b v-if="order.product.id !== 3">Cost</b>
      <b v-else>You'll receive</b>
    </div>

    <div
      v-for="(line, index) in order.lines"
         :key="index"
      class="confirmation--line"
    >
      <div class="order-currency">
        <div :class="`icon icon__${line.currency.code.toLowerCase()}`"></div>
        <strong>{{ line.currency.code }}</strong>
      </div>

      <p>{{ line.qty }}</p>

      <p>{{ line.rate | rate }}</p>

      <p>{{ line.total | price }}</p>
    </div>

    <div
         class="confirmation--line"
         v-if="COMMERCE_SHOW_BUYBACK && order.buyBackGuarantee"
         >
      <b>Buyback Guarantee</b>
      <p></p>
      <p></p>
      <p>{{ 4 | price }}</p>
    </div>

    <div
         class="confirmation--line"
         v-if="COMMERCE_SHOW_REFUND && order.refundGuarantee"
         >
      <b>Refund Guarantee</b>
      <p></p>
      <p></p>
      <p>{{ 3 | price }}</p>
    </div>

    <div class="confirmation--line"
         v-if="order.deliverySlot">
      <b>
        {{ order.deliverySlot.deliveryMethodName }}
      </b>
      <p></p>
      <p></p>
      <p>
        {{ order.deliverySlot.price | price }}
      </p>
    </div>

  </div>
</template>

<script>
import {
  COMMERCE_SHOW_BUYBACK,
  COMMERCE_SHOW_REFUND,
} from '@vue/common/constants/config'

import { mapState } from 'vuex'

export default {
  data() {
    return {
      COMMERCE_SHOW_BUYBACK,
      COMMERCE_SHOW_REFUND,
    }
  },
  computed: {
    ...mapState({
      order: (state) => state.checkout.order,
    }),
  },
}
</script>
