import Vue from 'vue'
import Toasted from 'vue-toasted'

Vue.use(Toasted)

export const showInfo = infoMessage => {
  Vue.toasted.show(infoMessage.text, {
    duration: 6500,
    position: 'bottom-right',
    iconPack: 'custom-class',
    icon: {
      name: infoMessage.icon
    },
    action: [infoMessage.action]
  })
}

export const showError = errorMessage => {
  console.warn(errorMessage);
  Vue.toasted.show(errorMessage, {
    duration: 3500,
    position: 'bottom-right',
    iconPack: 'custom-class',
    type: 'error',
    icon: {
      name: 'error_toast'
    }
  })
}

export default {
  showInfo,
  showError
}
