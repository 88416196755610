<template>
  <div class="modal modal--velocity" v-if="velocityModal">
    <div class="modal--inner">
      <div class="modal--header">
        <h3>
          Unfortunately you have exceeded the amount of orders you can process
          through our website today.
        </h3>
      </div>
      <p>
        Your business is important to us, If you would like to purchase more
        currency, you can still do this via one of our branches, subject to our
        usual terms and conditions* and stock availability. To find your nearest
        branch or contact one of our cashiers please use <a href="/branches" target="_blank">our branch finder</a>.
      </p>
      <small
        >*Please note that you will require a proof of ID for all credit, debit
        card and larger transactions</small
      >
      <button @click="setVelocityModal(false)" class="close">Close</button>

      <button @click="returnToHomepage" class="button button--secondary">
        Return to the homepage
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { SET_VELOCITY_MODAL } from '@vue/common/constants/mutation.types'
import { actionTypes } from '@vue/common/constants/store.types'

export default {
  name: 'ModalVelocity',
  computed: {
    ...mapState('checkout', ['velocityModal'])
  },
  methods: {
    ...mapMutations('checkout', [SET_VELOCITY_MODAL]),
    ...mapActions('checkout', [actionTypes.AbandonCheckout]),
    returnToHomepage() {
      this.setVelocityModal(false)

      this.abandonCheckout().then(() => {
        window.location = '/'
      })
    }
  }
}
</script>
