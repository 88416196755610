<template>
  <div>
    <section class="checkout">
      <div class="container">
        <div class="checkout--content" v-if="order && content">
          <div class="form">
            <form @submit.prevent="onSubmit" autocomplete="off" novalidate>
              <fieldset>

                <legend>Contact details</legend>

                <div class="row">
                  <div class="inputs">
                    <!-- Email -->
                    <div class="input"
                         :class="{ validator: $v.details.emailAddress.$error }">
                      <label for="emailAddress">
                        <span>Email address*</span>
                      </label>
                      <input type="text"
                             name="emailAddress"
                             v-model="details.emailAddress"
                             ref="emailAddress"
                             @blur="checkemail('CUSTOMER_DETAILS')" />
                      <span class="error"
                            v-if="!$v.details.emailAddress.required">
                        Please enter your email address
                      </span>
                      <span class="error" v-if="!$v.details.emailAddress.email">
                        Please enter a valid email address
                      </span>
                      <div class="error"
                           v-if="!$v.details.emailAddress.maxLength">
                        You've used the maximum number of characters available
                        (250)
                      </div>
                    </div>
                    <!-- Phone -->
                    <div class="input"
                         :class="{ validator: $v.details.phoneNumber.$error }">
                      <label for="phoneNumber">
                        <span>Phone number*</span>
                      </label>
                      <input type="tel"
                             pattern="\d*"
                             name="phoneNumber"
                             v-model.lazy="details.phoneNumber"
                             ref="phoneNumber" />
                      <span class="error"
                            v-if="!$v.details.phoneNumber.required">
                        Please enter your phone number
                      </span>
                      <span class="error"
                            v-if="
                          $v.details.phoneNumber.required &&
                          !$v.details.phoneNumber.validNumber
                        ">
                        Please enter a valid phone number
                      </span>
                      <div class="error"
                           v-if="!$v.details.phoneNumber.maxLength">
                        You've used the maximum number of characters available
                        (250)
                      </div>
                    </div>
                  </div>
                  <span v-if="this.order && this.order.lines && this.getsite && this.getsite.isSiteAllowed">
                  Entered your email address? If you have to break off before finishing your order, we’ll drop you an email with a link to your basket.
                  </span>
                </div>
                <br />

                <legend>About you</legend>

                <div class="row">
                  <div class="inputs">
                    <!-- Firstname -->
                    <div class="input"
                         :class="{ validator: $v.details.firstName.$error }">
                      <label for="firstName"><span>First name*</span></label>
                      <input type="text"
                             name="firstName"
                             v-model.trim="details.firstName"
                             @input="isLetter('firstName')"
                             ref="firstName" />
                      <div class="error" v-if="!$v.details.firstName.required">
                        Please enter your firstname
                      </div>
                      <div class="error" v-if="!$v.details.firstName.minLength">
                        First name must be at least 2 characters
                      </div>
                      <div class="error" v-if="!$v.details.firstName.maxLength">
                        You've used the maximum number of characters available
                        (250)
                      </div>
                    </div>
                    <!-- Surname -->
                    <div class="input"
                         :class="{ validator: $v.details.lastName.$error }">
                      <label for="lastName"><span>Last name*</span></label>
                      <input type="text"
                             name="lastName"
                             v-model.trim="details.lastName"
                             @input="isLetter('lastName')"
                             ref="lastName" />
                      <div class="error" v-if="!$v.details.lastName.required">
                        Please enter your last name
                      </div>
                      <div class="error" v-if="!$v.details.lastName.minLength">
                        Last name must be at least 2 characters
                      </div>
                      <div class="error" v-if="!$v.details.lastName.maxLength">
                        You've used the maximum number of characters available
                        (250)
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="inputs">
                    <!-- Date of birth -->
                    <div class="input"
                         :class="{ validator: $v.details.dateOfBirth.$error }">
                      <label>
                        <span>Date of birth*</span>
                        <Tooltip :content="content.dateOfBirthTooltip" />
                      </label>

                      <div class="dob">
                        <input name="DD"
                               type="number"
                               placeholder="DD"
                               maxlength="2"
                               v-on:input="validateInput($event, 'DD')"
                               v-model.lazy="$v.details.dateOfBirth.$model.day"
                               ref="DD" />
                        <input name="MM"
                               type="number"
                               placeholder="MM"
                               maxlength="2"
                               v-on:input="validateInput($event, 'MM')"
                               v-model.lazy="$v.details.dateOfBirth.$model.month"
                               ref="MM" />
                        <input name="YYYY"
                               type="number"
                               placeholder="YYYY"
                               maxlength="4"
                               v-on:input="validateInput($event, 'YYYY')"
                               v-model.lazy="$v.details.dateOfBirth.$model.year"
                               ref="YYYY" />
                      </div>

                      <span class="error"
                            v-if="!$v.details.dateOfBirth.isValidDateOfBirth">
                        Please enter a valid date of birth
                      </span>
                    </div>
                  </div>
                </div>
              </fieldset>

              <fieldset class="address-fieldset">
                <label>
                  <legend v-if="order && order.product.id != 3">Billing address</legend>
                  <legend v-else="order && order.product.id == 3">Your address</legend>
                </label>

                <div v-if="order && order.product.id === 1" class="disclaimer">
                  <strong>
                    For home delivery orders the payment card must be registered to the delivery address entered here.
                  </strong>
                </div>

                <CustomerAddressFormPartial ref="customerAddress" v-model="details.address" :validator="$v.details.address" />

              </fieldset>
              <fieldset v-if="COMMERCE_SHOW_MARKETING && order.product.id !== 3">
                <legend>Marketing Preferences</legend>
                <p>
                  Our customers enjoy receiving special offers and promotions by
                  email. <br />
                  So you don't miss out, simply tick the box below. Remember
                  that you can opt-out at any time.
                </p>
                <div class="checkout-inputs">
                  <div class="input checkbox">
                    <input type="checkbox"
                           name="marketing"
                           id="marketing"
                           v-model="details.marketing" />
                    <label for="marketing">
                      <p>
                        Travel Money &amp; eurochange products and services by
                        email
                      </p>
                    </label>
                    <p>
                      For more information about how we use your data please
                      visit our
                      <a v-if="content.privacyLink"
                         :href="content.privacyLink.link"
                         target="_blank">
                        {{ content.privacyLink.title }}
                      </a>
                    </p>
                  </div>
                </div>
              </fieldset>

              <fieldset v-if="COMMERCE_SHOW_ABTA && order.product.id !== 3">
                <legend>
                  Would you like offers and promotions by email from ABTA? Select to
                  receive the following:
                </legend>

                <div class="checkout-inputs">
                  <div class="input checkbox">
                    <input type="checkbox"
                           name="marketingABTATravelMoney"
                           id="marketingABTATravelMoney"
                           v-model="details.marketingABTATravelMoney" />
                    <label for="marketingABTATravelMoney">
                      <p>ABTA Travel Money</p>
                    </label>
                  </div>
                  <div class="input checkbox">
                    <input type="checkbox"
                           name="marketingABTATravelInsurance"
                           id="marketingABTATravelInsurance"
                           v-model="details.marketingABTATravelInsurance" />
                    <label for="marketingABTATravelInsurance">
                      <p>ABTA Travel Insurance</p>
                    </label>
                  </div>
                  <p>
                    You may opt-out of these emails at any time by clicking the
                    opt-out link that is included on all ABTA emails. More
                    information about how ABTA processes personal data is
                    available on the ABTA website
                    <a href="https://www.abta.com/privacy-notice" target="_blank">https://www.abta.com/privacy-notice</a>.
                    <a v-if="content.additionalPrivacyLink"
                       :href="content.additionalPrivacyLink.link"
                       target="_blank">
                      {{ content.additionalPrivacyLink.title }}
                    </a>
                  </p><br>
                  <p>
                    By submitting this form, you are agreeing to receiving emails from Trustpilot.
                    <a href="https://uk.legal.trustpilot.com/for-businesses/business-privacy-policy" target="_blank">Trustpilot privacy notice</a> has more information about how they will use your data and how they keep it safe.
                  </p>
                </div>
              </fieldset>

              <fieldset>
                <legend>Terms and conditions</legend>
                <div class="checkout-inputs">
                  <div class="input checkbox"
                       :class="{ validator: $v.details.terms.$error }">
                    <input type="checkbox"
                           name="terms"
                           id="terms"
                           v-model="details.terms"
                           ref="terms" />
                    <label for="terms">
                      <p>
                        Please tick the box to confirm that you agree to our
                        <a v-if="content.termsLink"
                           :href="content.termsLink.link"
                           target="_blank">
                          {{ content.termsLink.title }}
                        </a>
                        and
                        <a v-if="content.privacyLink"
                           :href="content.privacyLink.link"
                           target="_blank">
                          {{ content.privacyLink.title }}
                        </a>
                      </p>
                    </label>
                  </div>
                </div>
              </fieldset>

              <div class="row legal">
                <p>* Indicates a required field</p>
              </div>

              <div class="row submission">
                <Spinner name="circle"
                         color="var(--color-links)"
                         v-if="loading" />
                <input type="submit"
                       class="button button--primary"
                       value="Submit"
                       v-if="!loading" />
              </div>
            </form>
          </div>
        </div>

        <order-summary :deliverySlot="order.deliverySlot" v-if="order && order.deliverySlot" />
      </div>
    </section>

    <modal-aml v-if="amlModal" />
    <div class="modal modal--address" v-if="noStock">
      <div class="modal--inner">
        <h2>Oh snap!</h2>

        <div class="row noStock">
          This branch has just sold out of the currency you’re ordering
          - please choose another collection date
        </div>
        <div class="row">
          <input
            type="button"
            class="button button--primary"
            value="Choose another collection date"
            tabindex="0"
            @click="gotoCalander()"
          />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex'
  import { DateTime } from 'luxon'
  import { submitOrdertoDotDigital } from  '@vue/services/dotdigital.service'
  import { required, email, maxLength, minLength } from 'vuelidate/lib/validators'
  import {
    isValidDateOfBirth,
    isValidNumber,
    isValidName,
    isPostcode
  } from '@vue/common/validations'

  import { OrderStates } from '@vue/common/constants/order'

  import { showError } from '@vue/services/toast.service'
  import { setCustomerInfo } from '@vue/services/api/checkout.api'
  import { getsitesettings } from '@vue/services/api/cart.api'
  import {
    COMMERCE_SHOW_MARKETING,
    COMMERCE_SHOW_ABTA,
  } from '@vue/common/constants/config'
  import Spinner from 'vue-spinkit'
  import Tooltip from '@vue/common/components/Tooltip'
  import ModalAml from './components/modal-aml'
  import CustomerAddressFormPartial from './components/CustomerAddressFormPartial'
  import OrderSummary from '@vue/views/checkout/common/order-summary'
  import { SITE_ID } from '@vue/common/constants/config'

  export default {
    name: 'Information',
    components: {
      CustomerAddressFormPartial,
      Spinner,
      Tooltip,
      ModalAml,
      OrderSummary
    },
    data() {
      return {
        loading: false,
        getsite: null,
        details: {
          firstName:  '',
          lastName:  '',
          emailAddress:  '',
          phoneNumber:  '',
          dateOfBirth: {
            day: '',
            month: '',
            year: '',
          },
          terms:  false,
          marketing: !COMMERCE_SHOW_MARKETING
            ? false
            :  true,
          marketingABTATravelMoney: false,
          marketingABTATravelInsurance: false,
          address: {
            houseNameNumber: '',
            addressLine1:  '',
            addressLine2:  '',
            townCity:  '',
            postcodeZip:  '',
            country: 'United Kingdom',
          },
        },
        COMMERCE_SHOW_MARKETING,
        COMMERCE_SHOW_ABTA,
        noStock: false,
      }
    },
    computed: {
      ...mapState('checkout', ['content', 'order', 'amlModal']),
    },
    validations: {
      details: {
        firstName: {
          required,
          maxLength: maxLength(250),
          isValidName,
          minLength: minLength(2)
        },
        lastName: {
          required,
          maxLength: maxLength(250),
          isValidName,
          minLength: minLength(2)
        },
        dateOfBirth: {
          isValidDateOfBirth,
        },
        address: {
          houseNameNumber: {
            required,
            maxLength: maxLength(250)
          },
          addressLine1: {
            required,
            maxLength: maxLength(250)
          },
          addressLine2: {
            maxLength: maxLength(250)
          },
          townCity: {
            required,
            maxLength: maxLength(250)
          },
          postcodeZip: {
            required,
            isPostcode,
            maxLength: maxLength(50)
          }
        },
        emailAddress: {
          required,
          email,
          maxLength: maxLength(500),
        },
        terms: {
          checked: (value) => value === true,
          required,
        },
        phoneNumber: {
          required,
          isValidNumber,
          maxLength: maxLength(50),
        },
      },
    },
    methods: {
      ...mapMutations({
        updateStep: 'checkout/updateStep',
        expiredOrder: 'checkout/expiredOrder',
        setAmlModal: 'checkout/setAmlModal',
      }),
      ...mapActions({
        getCheckoutContent: 'checkout/getCheckoutContent',
        getOrder: 'checkout/getOrder',
      }),
      checkemail(orderstatus) {
        if (this.$v.details.emailAddress.email && this.details.emailAddress != "" 
            && this.details.emailAddress != null) {
            submitOrdertoDotDigital(this.order,this.details.emailAddress, orderstatus);
        }
      },
      onSubmit() {
        this.$v.details.$touch()

        if (this.$v.details.$pending || this.$v.details.$error) {
          showError()
          if (this.$v.details.emailAddress.$error) { this.$refs.emailAddress.focus(); return }
          if (this.$v.details.phoneNumber.$error) { this.$refs.phoneNumber.focus(); return }
          if (this.$v.details.firstName.$error || !this.$v.details.firstName.isValidName) { this.$refs.firstName.focus(); return }
          if (this.$v.details.lastName.$error || !this.$v.details.lastName.isValidName) { this.$refs.lastName.focus(); return }
          if (this.$v.details.dateOfBirth.$error) {
            if (isNaN(this.$v.details.dateOfBirth.$model.day)) { this.$refs.DD.focus(); return }
            if (isNaN(this.$v.details.dateOfBirth.$model.month)) { this.$refs.MM.focus(); return }
            if (isNaN(this.$v.details.dateOfBirth.$model.year)) { this.$refs.YYYY.focus(); return }
            if (!(Number(this.$v.details.dateOfBirth.$model.year) >= 1900 && Number(this.$v.details.dateOfBirth.$model.year <= new Date().getFullYear()))) {
              { this.$refs.YYYY.focus(); return }
            }

            if (Number(this.$v.details.dateOfBirth.$model.month) > new Date().getMonth() + 1 && Number(this.$v.details.dateOfBirth.$model.year) == new Date().getFullYear()) {
              { this.$refs.MM.focus(); return }
            }
            if (!(Number(this.$v.details.dateOfBirth.$model.month) >= 1 && Number(this.$v.details.dateOfBirth.$model.month) <= 12)) {
              { this.$refs.MM.focus(); return }
            }
            var date = new Date(this.$v.details.dateOfBirth.$model.year, this.$v.details.dateOfBirth.$model.month, 0);
            var totalDaysInMonth = parseInt(date.getDate());
            if (!(Number(this.$v.details.dateOfBirth.$model.day) >= 1 && Number(this.$v.details.dateOfBirth.$model.day) <= totalDaysInMonth)) {
              { this.$refs.DD.focus(); return }
            }
            if (!(Number(this.$v.details.dateOfBirth.$model.day) <= new Date().getDate())) {
              { this.$refs.DD.focus(); return }
            }
          }

          if (this.$v.details.address.$error) {
            this.$refs.customerAddress.$el.scrollIntoView();
            return;
          }

          if (this.$v.details.terms.$error) { this.$refs.terms.focus(); return }
        }

        this.loading = true

        const customerInfo = {
          ...this.details,
        }

        customerInfo.dateOfBirth = DateTime.fromObject(
          this.details.dateOfBirth
        ).toISODate()

        customerInfo.address.postcodeZip = customerInfo.address.postcodeZip.toUpperCase()

        setCustomerInfo(customerInfo)
          .then(({ data }) => {
            // TODO: Why is this response 200 OK with a random string in the body when there's an inventory issue?
            if (data == 'NoStock') {
              this.noStock = true;
              return;
            }

            if (data.showAMLPopup) {
              this.setAmlModal(true);
              return;
            }

            if(data.state.value === OrderStates.PAYMENT_PENDING) {
              window.location = window.checkoutWorkflow.next;
              return;
            }

            if(data.state.value === OrderStates.COMPLETED) {
              window.location = window.checkoutWorkflow.next;
              return;
            }

            window.location = window.checkoutWorkflow.error;
          })
          .finally(() => {
            this.loading = false;
          })

      },
      isLetter(fieldName) {
        const inputValue = this.details[fieldName];
        this.details[fieldName] = inputValue.replace(/[^A-Za-z ',.-]/g, '');
      },
      validateInput(event, currentRef) {
        var maxLength = event.target.maxLength;
        var regex = new RegExp("^[0-9]{" + maxLength + "}$");
        const value = event.target.value;
        event.target.value = value.slice(0, maxLength); // Restrict input to above maxlength characters
        if (currentRef == 'DD' && value.length == 2) {
          this.$refs.MM.focus()
          return
        } else if (currentRef == 'MM' && value.length == 2) {
          this.$refs.YYYY.focus()
          return
        }
      },
      gotoCalander() {
        this.noStock = false
        window.location = '/checkout/delivery-options'
    },
    },
    watch: {
      'details.dateOfBirth.day': {
        handler(day) {
          if (day.length < 2) this.details.dateOfBirth.day = `0${day}`
        },
        deep: true,
      },
      'details.dateOfBirth.month': {
        handler(month) {
          if (month.length < 2) this.details.dateOfBirth.month = `0${month}`
        },
        deep: true,
      },
    },
    async mounted() {
      this.getCheckoutContent()
      this.updateStep('information')

      await this.getOrder()

      // Sanity check that we're on the right page for the given order state.
      switch (this.order.state.value) {
        case OrderStates.PAYMENT_PENDING:
        case OrderStates.COMPLETED:
          window.location = window.checkoutWorkflow.next;
          break;
        case OrderStates.CHECKOUT_FAILED:
          window.location = window.checkoutWorkflow.error;
          break;
      }

      getsitesettings(SITE_ID).then(({ data }) => {
        this.getsite = data
      })
    },
  }
</script>

<style lang="scss" scoped>
.address-fieldset{
   legend {
    margin-bottom: 0;
  }

  .disclaimer{
    margin-bottom: 1rem;
  }
}
</style>
