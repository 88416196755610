import httpCommerce from './httpCommerce'
import { getToken } from '@vue/services/jwt.service'

export const getCart = () => {
  return httpCommerce.get('/api/cart', {
    headers: {
      Authorization: `bearer ${getToken()}`
    }
  })
}

export const recreateCart = () => {
  return httpCommerce.post('/api/cart/recreate', null, {
    headers: {
      Authorization: `bearer ${getToken()}`
    }
  })
}

export const recreatewebcart = id => {   
  return httpCommerce.get(`/api/cart/recreatewebcart?id=${id}`, {
    // headers: {
    //   Authorization: `bearer ${getToken()}`
    // }
  })
}

export const getsitesettings = id => {   
  return httpCommerce.get(`/api/sites/getsitesettings?id=${id}`, {
    headers: {
      Authorization: `bearer ${getToken()}`
    }
  })
}

export const createCart = params => {
  return httpCommerce.post('/api/cart/create', params)
}

export const addLine = params => {
  return httpCommerce.post(
    '/api/cart/add',
    {
      currencyCode: params.currencyCode,
      qty: params.qty
    },
    {
      headers: {
        Authorization: `bearer ${getToken()}`
      }
    }
  )
}

export const isBuyBack = buyback => {
  return httpCommerce.get(`/api/cart/buyback?isBuyBack=${buyback}`, {
    headers: {
      Authorization: `bearer ${getToken()}`
    }
  })
}

export const isRefund = refund => {
  return httpCommerce.get(`/api/cart/refund?isRefund=${refund}`, {
    headers: {
      Authorization: `bearer ${getToken()}`
    }
  })
}

export const updateLine = (id, params) => {
  return httpCommerce.put(`/api/cart/line/${id}`, params, {
    headers: {
      Authorization: `bearer ${getToken()}`
    }
  })
}

export const deleteLine = id => {
  return httpCommerce.delete(`/api/cart/line/${id}`, {
    headers: {
      Authorization: `bearer ${getToken()}`
    }
  })
}

export default {
  getCart,
  recreateCart,
  createCart,
  addLine,
  updateLine,
  deleteLine,
  recreatewebcart,
  getsitesettings
}
