var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "tooltip-toggle" }),
    _vm._v(" "),
    _vm.content
      ? _c("div", { staticClass: "tooltip" }, [
          _c("h6", [_vm._v(_vm._s(_vm.content.title))]),
          _vm._v(" "),
          _c("p", {
            domProps: { innerHTML: _vm._s(_vm.lineBreaks(_vm.content.copy)) },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }