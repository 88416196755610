<template>
  <div>
    <div class="store">
      <div class="store--inner">
        <div :class="`store--brand__${branch.brand.toLowerCase()}`"></div>
        <h3>{{ branch.name }}</h3>

        <p>Distance: {{ branch.distanceInMiles.toFixed(2) }} miles</p>
      </div>
    </div>

    <div class="store--info">
      <OpeningTimes v-model="branch.openingTimes" />

      <div class="store--address">
        <b>Address</b>
        <div class="address">
          <span>
            {{ branch.brand }}
            {{ branch.name }},
          </span>
          <span v-if="branch.address.line1"> {{ branch.address.line1 }}, </span>
          <span v-if="branch.address.line2"> {{ branch.address.line2 }}, </span>
          <span v-if="branch.address.line3"> {{ branch.address.line3 }}, </span>
          <span>{{ branch.address.postcode }}</span>
        </div>
        <div class="brand" :class="branch.brand"></div>
      </div>
    </div>
    <a :href="branch.url" class="button button--primary button--small">
      View Details
    </a>
  </div>
</template>

<script>
import OpeningTimes from '@vue/common/components/opening-times'

export default {
  name: 'BranchFinderBranch',
  props: ['branch'],
  components: {
    OpeningTimes
  }
}
</script>
