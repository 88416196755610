var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "opening-times" }, [
    _c("b", [_vm._v("Opening times")]),
    _vm._v(" "),
    _vm.validTimes
      ? _c("div", [
          _vm.openingTimes.mondayOpens && _vm.openingTimes.mondayCloses
            ? _c("div", { staticClass: "row" }, [
                _c("span", [_vm._v("Monday")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm._f("times")(_vm.openingTimes.mondayOpens)) +
                      " -\n        " +
                      _vm._s(_vm._f("times")(_vm.openingTimes.mondayCloses)) +
                      "\n      "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.openingTimes.tuesdayOpens && _vm.openingTimes.tuesdayCloses
            ? _c("div", { staticClass: "row" }, [
                _c("span", [_vm._v("Tuesday")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm._f("times")(_vm.openingTimes.tuesdayOpens)) +
                      " -\n        " +
                      _vm._s(_vm._f("times")(_vm.openingTimes.tuesdayCloses)) +
                      "\n      "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.openingTimes.wednesdayOpens && _vm.openingTimes.wednesdayCloses
            ? _c("div", { staticClass: "row" }, [
                _c("span", [_vm._v("Wednesday")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm._f("times")(_vm.openingTimes.wednesdayOpens)) +
                      " -\n        " +
                      _vm._s(
                        _vm._f("times")(_vm.openingTimes.wednesdayCloses)
                      ) +
                      "\n      "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.openingTimes.thursdayOpens && _vm.openingTimes.thursdayCloses
            ? _c("div", { staticClass: "row" }, [
                _c("span", [_vm._v("Thursday")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm._f("times")(_vm.openingTimes.thursdayOpens)) +
                      " -\n        " +
                      _vm._s(_vm._f("times")(_vm.openingTimes.thursdayCloses)) +
                      "\n      "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.openingTimes.fridayOpens && _vm.openingTimes.fridayCloses
            ? _c("div", { staticClass: "row" }, [
                _c("span", [_vm._v("Friday")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm._f("times")(_vm.openingTimes.fridayOpens)) +
                      " -\n        " +
                      _vm._s(_vm._f("times")(_vm.openingTimes.fridayCloses)) +
                      "\n      "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.openingTimes.saturdayOpens && _vm.openingTimes.saturdayCloses
            ? _c("div", { staticClass: "row" }, [
                _c("span", [_vm._v("Saturday")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm._f("times")(_vm.openingTimes.saturdayOpens)) +
                      " -\n        " +
                      _vm._s(_vm._f("times")(_vm.openingTimes.saturdayCloses)) +
                      "\n      "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.openingTimes.sundayOpens && _vm.openingTimes.sundayCloses
            ? _c("div", { staticClass: "row" }, [
                _c("span", [_vm._v("Sunday")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm._f("times")(_vm.openingTimes.sundayOpens)) +
                      " -\n        " +
                      _vm._s(_vm._f("times")(_vm.openingTimes.sundayCloses)) +
                      "\n      "
                  ),
                ]),
              ])
            : _vm._e(),
        ])
      : _c("div", [
          !_vm.isLoading
            ? _c("div", { staticClass: "store--stock closed" }, [
                _c("p", [_vm._v("Sorry we're closed this week!")]),
              ])
            : _vm._e(),
        ]),
    _vm._v(" "),
    _vm.validTimes && _vm.openingTimes.openingTimeComment !== null
      ? _c("p", { staticClass: "opening-times--comment" }, [
          _vm._v(
            "\n    " + _vm._s(_vm.openingTimes.openingTimeComment) + "\n  "
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }