<template>
  <div class="insert" v-if="order">
    <div class="insert--cart">
      <div class="inner">
        <div class="row title">
          <h4>Order summary</h4>
        </div>

        <div class="insert--cart-lines">
          <div class="insert--cart-lines__header">
            <div>
              <strong>Currency</strong>
            </div>
            <div>
              <strong>Quantity</strong>
            </div>
            <div>
              <strong>Rate</strong>
            </div>
            <div>
              <strong v-if="order.product.id !== 3">Price</strong>
              <strong v-else>You'll get</strong>
            </div>
          </div>

          <div class="insert--cart-lines__items">
            <div
                 class="insert--cart-lines__item"
                 v-for="(line, index) in order.lines"
                 :key="index"
                 >
              <div class="column">
                <strong class="label">Currency</strong>
                <div class="row">
                  <div
                       :class="`icon icon__${line.currency.code.toLowerCase()}`"
                       ></div>
                  <div class="code">{{ line.currency.code }}</div>
                </div>
              </div>
              <div>
                <strong class="label">Quantity</strong>
                <span>{{ line.qty }}</span>
              </div>
              <div>
                <strong class="label">Rate</strong>
                <span>{{ line.rate | rate }}</span>
              </div>
              <div>
                <strong class="label" v-if="order.product.id !== 3"
                  >Price</strong
                >
                <strong class="label" v-else>You'll get</strong>
                <span>{{ line.total | price }}</span>
              </div>
            </div>
            <div
                 class="insert--cart-lines__buyback"
                 v-if="COMMERCE_SHOW_BUYBACK && order.buyBackGuarantee"
                 >
              <b>Buyback Guarantee</b>
              <p>{{ 4 | price }}</p>
            </div>
            <div
                 class="insert--cart-lines__buyback"
                 v-if="COMMERCE_SHOW_REFUND && order.refundGuarantee"
                 >
              <b>Refund Guarantee</b>
              <p>{{ 3 | price }}</p>
            </div>

            <div class="insert--cart-lines__buyback"
                 v-if="deliverySlot">
              <b>
                {{ deliverySlot.deliveryMethodName }}
              </b>
              <p>
                {{ deliverySlot.price | price }}
              </p>
            </div>
            <div class="insert--cart-lines__total">
                <b v-if="order.product.id !== 3">Total to pay</b>
                <b v-else>Total you will receive</b>
                <p class="total-price">{{ order.total | price }}</p>
            </div>

          </div>
        </div>
        <div class="delivery">
          <div v-if="order.deliveryAddress">
            <b v-if="order.product.id === 1">Delivery Address</b>
            <b v-else>Collection Address</b>
            <delivery-address :order="order" />
          </div>          
          <div v-if="deliverySlot">
            <b v-if="order.product.id === 1">Delivery Date</b>
            <b v-else>Collection Date</b>
            <div class="column">
              <span>{{ deliverySlot.date | date }}</span>             
              <br>
              <div v-if="order.product.id === 1 && isMonday()">
                <span>
                  {{ `If you place an order for Monday, Royal Mail may attempt delivery on Saturday. This is beyond our
                  control. If you miss your delivery, Royal Mail will automatically arrange a redelivery for the next day.
                                    If you have the Royal Mail app you can redirect the delivery to a later date.` }}
                </span>
              </div> 
              <div v-if="!deliverySlot.isInStock && order.product.id === 2">
                <span>
                  <br>
                  <strong>Free collection - available from 2pm. </strong> <br>
                  {{ 'If your order is available for collection before 2pm, we’ll contact you' }}
                </span>
              </div>
            </div>
          </div>
          
        </div>       
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DeliveryAddress from '@vue/views/checkout/common/delivery-address'
import {
  COMMERCE_SHOW_BUYBACK,
  COMMERCE_SHOW_REFUND,
} from '@vue/common/constants/config'
let isinstock = true;

export default {
  name: 'OrderSummary',
  props: {
    osisinstock: {
      type: Boolean
    },
    deliverySlot: {
      type: Object
    }
  },
  components: {
    DeliveryAddress,
  },
  data() {
    return {
      COMMERCE_SHOW_BUYBACK,
      COMMERCE_SHOW_REFUND,
    }
  },
  methods: {
    isMonday() {
      if (this.deliverySlot != null && this.deliverySlot !=undefined) {
        let d = new Date(this.deliverySlot.date);
        if (d.getDay() === 1)
          return true
      }
    }
  },
  computed: {
    ...mapState('checkout', ['order']),
    store: (state) => state.store.store
  }
}
</script>
