<template>
  <div class="notification restriction product-locked" v-if="shoppingCart && selectedProduct">
    <p>
      {{ selectedProduct.name }} selected -
      <button @click="startAgain">Start again</button>
    </p>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'ProductSelection',
    computed: {
      ...mapState({
        selectedProduct: (state) => state.product.selectedProduct,
        shoppingCart: (state) => state.cart.shoppingCart,
      }),
    },
    methods: {
      ...mapActions({
        abandonCart: 'cart/abandonCart',
        toggleStoreSelect: 'calculator/toggleStoreSelect',
        toggleResult: 'calculator/toggleResult',
      }),
      startAgain() {
        this.abandonCart();
        if (window.doc_type === "pageStore" && this.selectedProduct.id === 1) {
          this.selectedProduct.id = 2;
          this.toggleStoreSelect(true);
          this.toggleResult(false)
        }

      },
    },
  }
</script>
