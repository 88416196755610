var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("section", { staticClass: "checkout" }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _vm.order && _vm.content
              ? _c("div", { staticClass: "checkout--content" }, [
                  _c("div", { staticClass: "form" }, [
                    _c(
                      "form",
                      {
                        attrs: { autocomplete: "off", novalidate: "" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.onSubmit($event)
                          },
                        },
                      },
                      [
                        _c("fieldset", [
                          _c("legend", [_vm._v("Contact details")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "inputs" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "input",
                                  class: {
                                    validator:
                                      _vm.$v.details.emailAddress.$error,
                                  },
                                },
                                [
                                  _vm._m(0),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.details.emailAddress,
                                        expression: "details.emailAddress",
                                      },
                                    ],
                                    ref: "emailAddress",
                                    attrs: {
                                      type: "text",
                                      name: "emailAddress",
                                    },
                                    domProps: {
                                      value: _vm.details.emailAddress,
                                    },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.checkemail(
                                          "CUSTOMER_DETAILS"
                                        )
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.details,
                                          "emailAddress",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  !_vm.$v.details.emailAddress.required
                                    ? _c("span", { staticClass: "error" }, [
                                        _vm._v(
                                          "\n                      Please enter your email address\n                    "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.$v.details.emailAddress.email
                                    ? _c("span", { staticClass: "error" }, [
                                        _vm._v(
                                          "\n                      Please enter a valid email address\n                    "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.$v.details.emailAddress.maxLength
                                    ? _c("div", { staticClass: "error" }, [
                                        _vm._v(
                                          "\n                      You've used the maximum number of characters available\n                      (250)\n                    "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "input",
                                  class: {
                                    validator:
                                      _vm.$v.details.phoneNumber.$error,
                                  },
                                },
                                [
                                  _vm._m(1),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.lazy",
                                        value: _vm.details.phoneNumber,
                                        expression: "details.phoneNumber",
                                        modifiers: { lazy: true },
                                      },
                                    ],
                                    ref: "phoneNumber",
                                    attrs: {
                                      type: "tel",
                                      pattern: "\\d*",
                                      name: "phoneNumber",
                                    },
                                    domProps: {
                                      value: _vm.details.phoneNumber,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.details,
                                          "phoneNumber",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  !_vm.$v.details.phoneNumber.required
                                    ? _c("span", { staticClass: "error" }, [
                                        _vm._v(
                                          "\n                      Please enter your phone number\n                    "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$v.details.phoneNumber.required &&
                                  !_vm.$v.details.phoneNumber.validNumber
                                    ? _c("span", { staticClass: "error" }, [
                                        _vm._v(
                                          "\n                      Please enter a valid phone number\n                    "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.$v.details.phoneNumber.maxLength
                                    ? _c("div", { staticClass: "error" }, [
                                        _vm._v(
                                          "\n                      You've used the maximum number of characters available\n                      (250)\n                    "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            this.order &&
                            this.order.lines &&
                            this.getsite &&
                            this.getsite.isSiteAllowed
                              ? _c("span", [
                                  _vm._v(
                                    "\n                Entered your email address? If you have to break off before finishing your order, we’ll drop you an email with a link to your basket.\n                "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c("legend", [_vm._v("About you")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "inputs" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "input",
                                  class: {
                                    validator: _vm.$v.details.firstName.$error,
                                  },
                                },
                                [
                                  _vm._m(2),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: _vm.details.firstName,
                                        expression: "details.firstName",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    ref: "firstName",
                                    attrs: { type: "text", name: "firstName" },
                                    domProps: { value: _vm.details.firstName },
                                    on: {
                                      input: [
                                        function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.details,
                                            "firstName",
                                            $event.target.value.trim()
                                          )
                                        },
                                        function ($event) {
                                          return _vm.isLetter("firstName")
                                        },
                                      ],
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  !_vm.$v.details.firstName.required
                                    ? _c("div", { staticClass: "error" }, [
                                        _vm._v(
                                          "\n                      Please enter your firstname\n                    "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.$v.details.firstName.minLength
                                    ? _c("div", { staticClass: "error" }, [
                                        _vm._v(
                                          "\n                      First name must be at least 2 characters\n                    "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.$v.details.firstName.maxLength
                                    ? _c("div", { staticClass: "error" }, [
                                        _vm._v(
                                          "\n                      You've used the maximum number of characters available\n                      (250)\n                    "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "input",
                                  class: {
                                    validator: _vm.$v.details.lastName.$error,
                                  },
                                },
                                [
                                  _vm._m(3),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: _vm.details.lastName,
                                        expression: "details.lastName",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    ref: "lastName",
                                    attrs: { type: "text", name: "lastName" },
                                    domProps: { value: _vm.details.lastName },
                                    on: {
                                      input: [
                                        function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.details,
                                            "lastName",
                                            $event.target.value.trim()
                                          )
                                        },
                                        function ($event) {
                                          return _vm.isLetter("lastName")
                                        },
                                      ],
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  !_vm.$v.details.lastName.required
                                    ? _c("div", { staticClass: "error" }, [
                                        _vm._v(
                                          "\n                      Please enter your last name\n                    "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.$v.details.lastName.minLength
                                    ? _c("div", { staticClass: "error" }, [
                                        _vm._v(
                                          "\n                      Last name must be at least 2 characters\n                    "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.$v.details.lastName.maxLength
                                    ? _c("div", { staticClass: "error" }, [
                                        _vm._v(
                                          "\n                      You've used the maximum number of characters available\n                      (250)\n                    "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "inputs" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "input",
                                  class: {
                                    validator:
                                      _vm.$v.details.dateOfBirth.$error,
                                  },
                                },
                                [
                                  _c(
                                    "label",
                                    [
                                      _c("span", [_vm._v("Date of birth*")]),
                                      _vm._v(" "),
                                      _c("Tooltip", {
                                        attrs: {
                                          content:
                                            _vm.content.dateOfBirthTooltip,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "dob" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.lazy",
                                          value:
                                            _vm.$v.details.dateOfBirth.$model
                                              .day,
                                          expression:
                                            "$v.details.dateOfBirth.$model.day",
                                          modifiers: { lazy: true },
                                        },
                                      ],
                                      ref: "DD",
                                      attrs: {
                                        name: "DD",
                                        type: "number",
                                        placeholder: "DD",
                                        maxlength: "2",
                                      },
                                      domProps: {
                                        value:
                                          _vm.$v.details.dateOfBirth.$model.day,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.validateInput($event, "DD")
                                        },
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.$v.details.dateOfBirth.$model,
                                            "day",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.lazy",
                                          value:
                                            _vm.$v.details.dateOfBirth.$model
                                              .month,
                                          expression:
                                            "$v.details.dateOfBirth.$model.month",
                                          modifiers: { lazy: true },
                                        },
                                      ],
                                      ref: "MM",
                                      attrs: {
                                        name: "MM",
                                        type: "number",
                                        placeholder: "MM",
                                        maxlength: "2",
                                      },
                                      domProps: {
                                        value:
                                          _vm.$v.details.dateOfBirth.$model
                                            .month,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.validateInput($event, "MM")
                                        },
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.$v.details.dateOfBirth.$model,
                                            "month",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.lazy",
                                          value:
                                            _vm.$v.details.dateOfBirth.$model
                                              .year,
                                          expression:
                                            "$v.details.dateOfBirth.$model.year",
                                          modifiers: { lazy: true },
                                        },
                                      ],
                                      ref: "YYYY",
                                      attrs: {
                                        name: "YYYY",
                                        type: "number",
                                        placeholder: "YYYY",
                                        maxlength: "4",
                                      },
                                      domProps: {
                                        value:
                                          _vm.$v.details.dateOfBirth.$model
                                            .year,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.validateInput(
                                            $event,
                                            "YYYY"
                                          )
                                        },
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.$v.details.dateOfBirth.$model,
                                            "year",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  !_vm.$v.details.dateOfBirth.isValidDateOfBirth
                                    ? _c("span", { staticClass: "error" }, [
                                        _vm._v(
                                          "\n                      Please enter a valid date of birth\n                    "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "fieldset",
                          { staticClass: "address-fieldset" },
                          [
                            _c("label", [
                              _vm.order && _vm.order.product.id != 3
                                ? _c("legend", [_vm._v("Billing address")])
                                : _c("legend", [_vm._v("Your address")]),
                            ]),
                            _vm._v(" "),
                            _vm.order && _vm.order.product.id === 1
                              ? _c("div", { staticClass: "disclaimer" }, [
                                  _c("strong", [
                                    _vm._v(
                                      "\n                  For home delivery orders the payment card must be registered to the delivery address entered here.\n                "
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("CustomerAddressFormPartial", {
                              ref: "customerAddress",
                              attrs: { validator: _vm.$v.details.address },
                              model: {
                                value: _vm.details.address,
                                callback: function ($$v) {
                                  _vm.$set(_vm.details, "address", $$v)
                                },
                                expression: "details.address",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.COMMERCE_SHOW_MARKETING &&
                        _vm.order.product.id !== 3
                          ? _c("fieldset", [
                              _c("legend", [_vm._v("Marketing Preferences")]),
                              _vm._v(" "),
                              _vm._m(4),
                              _vm._v(" "),
                              _c("div", { staticClass: "checkout-inputs" }, [
                                _c("div", { staticClass: "input checkbox" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.details.marketing,
                                        expression: "details.marketing",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      name: "marketing",
                                      id: "marketing",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.details.marketing
                                      )
                                        ? _vm._i(_vm.details.marketing, null) >
                                          -1
                                        : _vm.details.marketing,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.details.marketing,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.details,
                                                "marketing",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.details,
                                                "marketing",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.details,
                                            "marketing",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm._m(5),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(
                                      "\n                    For more information about how we use your data please\n                    visit our\n                    "
                                    ),
                                    _vm.content.privacyLink
                                      ? _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: _vm.content.privacyLink
                                                .link,
                                              target: "_blank",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.content.privacyLink.title
                                                ) +
                                                "\n                    "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.COMMERCE_SHOW_ABTA && _vm.order.product.id !== 3
                          ? _c("fieldset", [
                              _c("legend", [
                                _vm._v(
                                  "\n                Would you like offers and promotions by email from ABTA? Select to\n                receive the following:\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "checkout-inputs" }, [
                                _c("div", { staticClass: "input checkbox" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.details.marketingABTATravelMoney,
                                        expression:
                                          "details.marketingABTATravelMoney",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      name: "marketingABTATravelMoney",
                                      id: "marketingABTATravelMoney",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.details.marketingABTATravelMoney
                                      )
                                        ? _vm._i(
                                            _vm.details
                                              .marketingABTATravelMoney,
                                            null
                                          ) > -1
                                        : _vm.details.marketingABTATravelMoney,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a =
                                            _vm.details
                                              .marketingABTATravelMoney,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.details,
                                                "marketingABTATravelMoney",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.details,
                                                "marketingABTATravelMoney",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.details,
                                            "marketingABTATravelMoney",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm._m(6),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "input checkbox" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.details
                                            .marketingABTATravelInsurance,
                                        expression:
                                          "details.marketingABTATravelInsurance",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      name: "marketingABTATravelInsurance",
                                      id: "marketingABTATravelInsurance",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.details.marketingABTATravelInsurance
                                      )
                                        ? _vm._i(
                                            _vm.details
                                              .marketingABTATravelInsurance,
                                            null
                                          ) > -1
                                        : _vm.details
                                            .marketingABTATravelInsurance,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a =
                                            _vm.details
                                              .marketingABTATravelInsurance,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.details,
                                                "marketingABTATravelInsurance",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.details,
                                                "marketingABTATravelInsurance",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.details,
                                            "marketingABTATravelInsurance",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm._m(7),
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "\n                  You may opt-out of these emails at any time by clicking the\n                  opt-out link that is included on all ABTA emails. More\n                  information about how ABTA processes personal data is\n                  available on the ABTA website\n                  "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "https://www.abta.com/privacy-notice",
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "https://www.abta.com/privacy-notice"
                                      ),
                                    ]
                                  ),
                                  _vm._v(".\n                  "),
                                  _vm.content.additionalPrivacyLink
                                    ? _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: _vm.content
                                              .additionalPrivacyLink.link,
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.content
                                                  .additionalPrivacyLink.title
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                                _c("br"),
                                _vm._v(" "),
                                _vm._m(8),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("fieldset", [
                          _c("legend", [_vm._v("Terms and conditions")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "checkout-inputs" }, [
                            _c(
                              "div",
                              {
                                staticClass: "input checkbox",
                                class: {
                                  validator: _vm.$v.details.terms.$error,
                                },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.details.terms,
                                      expression: "details.terms",
                                    },
                                  ],
                                  ref: "terms",
                                  attrs: {
                                    type: "checkbox",
                                    name: "terms",
                                    id: "terms",
                                  },
                                  domProps: {
                                    checked: Array.isArray(_vm.details.terms)
                                      ? _vm._i(_vm.details.terms, null) > -1
                                      : _vm.details.terms,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.details.terms,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.details,
                                              "terms",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.details,
                                              "terms",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(_vm.details, "terms", $$c)
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("label", { attrs: { for: "terms" } }, [
                                  _c("p", [
                                    _vm._v(
                                      "\n                      Please tick the box to confirm that you agree to our\n                      "
                                    ),
                                    _vm.content.termsLink
                                      ? _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: _vm.content.termsLink.link,
                                              target: "_blank",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm.content.termsLink.title
                                                ) +
                                                "\n                      "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(
                                      "\n                      and\n                      "
                                    ),
                                    _vm.content.privacyLink
                                      ? _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: _vm.content.privacyLink
                                                .link,
                                              target: "_blank",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm.content.privacyLink.title
                                                ) +
                                                "\n                      "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm._m(9),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "row submission" },
                          [
                            _vm.loading
                              ? _c("Spinner", {
                                  attrs: {
                                    name: "circle",
                                    color: "var(--color-links)",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.loading
                              ? _c("input", {
                                  staticClass: "button button--primary",
                                  attrs: { type: "submit", value: "Submit" },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.order && _vm.order.deliverySlot
              ? _c("order-summary", {
                  attrs: { deliverySlot: _vm.order.deliverySlot },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.amlModal ? _c("modal-aml") : _vm._e(),
      _vm._v(" "),
      _vm.noStock
        ? _c("div", { staticClass: "modal modal--address" }, [
            _c("div", { staticClass: "modal--inner" }, [
              _c("h2", [_vm._v("Oh snap!")]),
              _vm._v(" "),
              _c("div", { staticClass: "row noStock" }, [
                _vm._v(
                  "\n        This branch has just sold out of the currency you’re ordering\n        - please choose another collection date\n      "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("input", {
                  staticClass: "button button--primary",
                  attrs: {
                    type: "button",
                    value: "Choose another collection date",
                    tabindex: "0",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.gotoCalander()
                    },
                  },
                }),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "emailAddress" } }, [
      _c("span", [_vm._v("Email address*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "phoneNumber" } }, [
      _c("span", [_vm._v("Phone number*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "firstName" } }, [
      _c("span", [_vm._v("First name*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "lastName" } }, [
      _c("span", [_vm._v("Last name*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n                Our customers enjoy receiving special offers and promotions by\n                email. "
      ),
      _c("br"),
      _vm._v(
        "\n                So you don't miss out, simply tick the box below. Remember\n                that you can opt-out at any time.\n              "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "marketing" } }, [
      _c("p", [
        _vm._v(
          "\n                      Travel Money & eurochange products and services by\n                      email\n                    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "marketingABTATravelMoney" } }, [
      _c("p", [_vm._v("ABTA Travel Money")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "marketingABTATravelInsurance" } }, [
      _c("p", [_vm._v("ABTA Travel Insurance")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n                  By submitting this form, you are agreeing to receiving emails from Trustpilot.\n                  "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://uk.legal.trustpilot.com/for-businesses/business-privacy-policy",
            target: "_blank",
          },
        },
        [_vm._v("Trustpilot privacy notice")]
      ),
      _vm._v(
        " has more information about how they will use your data and how they keep it safe.\n                "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row legal" }, [
      _c("p", [_vm._v("* Indicates a required field")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }