<template>
  <div
       class="column product-select"
       v-if="enabledProducts && selectedProduct"
       >
    <div class="label row">
      <span>{{
        content.productSelectLabel ||
        '3. Select how you would like to receive your currency'
      }}</span>
      <Tooltip :content="content.deliveryOptionsTooltip" />
    </div>

    <div class="options" tabindex="-1">
      <div
        class="radio"
           v-for="(product, index) in enabledProducts"
        :key="index"
      >
        <input
          type="radio"
               name="products"
               :id="kebabCase(product.name)"
               :disabled="!product.enabled || shoppingCart"
               :checked="product.id === selectedProduct.id"
               tabindex="0"
               @keyup.enter="setProduct(product)"
          @click="setProduct(product)"
        />
        <label :for="kebabCase(product.name)">
          {{ product.name }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
  import { SET_PRODUCT } from '@vue/common/constants/mutation.types'
  import { getParameterByName } from '@vue/services/calculator.service'

  import Tooltip from '@vue/common/components/Tooltip'

  export default {
    name: 'ProductSelect',
    components: {
      Tooltip,
    },
    computed: {
      ...mapGetters('product', ['enabledProducts']),
      ...mapState({
        content: (state) => state.calculator.content,
        selectedProduct: (state) => state.product.selectedProduct,
        shoppingCart: (state) => state.cart.shoppingCart,
        store: (state) => state.store.store,
        type: (state) => state.calculator.type,
      }),
    },
    methods: {
      ...mapActions({
        getProducts: 'product/getProducts',
        toggleStoreSelect: 'calculator/toggleStoreSelect',
        toggleResult: 'calculator/toggleResult',
      }),
      ...mapMutations('store', ['clearStores']),
      ...mapMutations('product', [SET_PRODUCT]),
      kebabCase: function(name) {
        const re = /([0-9]+|([A-Z][a-z]+)|[a-z]+|([A-Z]+)(?![a-z]))/g

        const toKebabCase = str =>
          (String(str ?? '').match(re) || []).map(x => x.toLowerCase()).join('-')

        return toKebabCase(name)
      },
      initialState() {
        const queryService = getParameterByName('service')
        if (!queryService) return

        const product = this.enabledProducts.filter(product => queryService === product.name)

        this.setProduct(product[0])
      },
    },
    watch: {
      enabledProducts(newValue) {
        if (!newValue) return

        this.initialState()
      },
      selectedProduct(newValue) {
        if (!newValue) return

        this.clearStores()

        if (!this.store) return

        // If is store or sell
        if (this.selectedProduct.id === 2 || this.selectedProduct.id === 3) {
          this.toggleStoreSelect(true)
          this.toggleResult(false)
          return
        }

        this.toggleStoreSelect(false)
        this.toggleResult(true)
      },
      'shoppingCart': function () {
        if (this.shoppingCart != null) {
          this.setProduct(this.shoppingCart.product);
        }
      },
    },
    mounted() {
      this.getProducts()
    },
  }
</script>
