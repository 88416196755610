<template>
  <div class="modal modal--warning">
    <div class="modal--inner">
      <div class="modal--header">
        <h3>Additional identification is required</h3>
        <countdown />
      </div>
      <p>
        Due to the volume of transactions you have completed, additional
        identification is required to collect your order, please provide a proof
        of id (driving licence/passport/national ID card) and a proof of address
        (driving licence, if not used as primary/bank statement/utility bill)
        dated within 3 months. If you have any question please do not hesitate
        to contact us on
        <a href="tel:0333 240 1687" title="call">
          0333 240 1687
        </a>
      </p>

      <button @click="setAmlModal(false)" class="close">Close</button>

      <div class="button-list">
        <button class="button button--primary" @click="continueWithOrder">
          Continue with my order
        </button>

        <button @click="returnToHomepage" class="button button--secondary">
          Return to the homepage
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { SET_AML_MODAL } from '@vue/common/constants/mutation.types'
import Countdown from '@vue/common/components/countdown'

export default {
  name: 'ModalAml',
  components: {
    Countdown
  },
  methods: {
    ...mapMutations('checkout', [SET_AML_MODAL]),
    continueWithOrder() {
      window.location = window.checkoutWorkflow.next;
    },
    returnToHomepage() {
      this.setAmlModal(false)
      window.location = '/'
    }
  }
}
</script>
