var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.intervals, function (interval) {
        return _c(
          "button",
          {
            key: interval,
            class: {
              "switcher-active-item": interval === _vm.activeInterval,
              "switcher-item": true,
            },
            on: {
              click: function ($event) {
                return _vm.syncToInterval(interval)
              },
            },
          },
          [_vm._v("\n    " + _vm._s(interval) + "\n  ")]
        )
      }),
      _vm._v(" "),
      _c("div", { ref: "chartContainer", staticClass: "chart-container" }, [
        _vm.showTooltip
          ? _c("div", { ref: "tooltip", staticClass: "tooltip-style" }, [
              _c("div", { staticClass: "tooltip-content" }, [
                _vm._v("\n        " + _vm._s(_vm.formattedPrice) + "\n      "),
              ]),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(_vm.formattedDate))]),
            ])
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }