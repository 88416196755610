var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.order
    ? _c("div", { staticClass: "insert" }, [
        _c("div", { staticClass: "insert--cart" }, [
          _c("div", { staticClass: "inner" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "insert--cart-lines" }, [
              _c("div", { staticClass: "insert--cart-lines__header" }, [
                _vm._m(1),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _vm._m(3),
                _vm._v(" "),
                _c("div", [
                  _vm.order.product.id !== 3
                    ? _c("strong", [_vm._v("Price")])
                    : _c("strong", [_vm._v("You'll get")]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "insert--cart-lines__items" },
                [
                  _vm._l(_vm.order.lines, function (line, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "insert--cart-lines__item" },
                      [
                        _c("div", { staticClass: "column" }, [
                          _c("strong", { staticClass: "label" }, [
                            _vm._v("Currency"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", {
                              class:
                                "icon icon__" +
                                line.currency.code.toLowerCase(),
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "code" }, [
                              _vm._v(_vm._s(line.currency.code)),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("strong", { staticClass: "label" }, [
                            _vm._v("Quantity"),
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(line.qty))]),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("strong", { staticClass: "label" }, [
                            _vm._v("Rate"),
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm._f("rate")(line.rate))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm.order.product.id !== 3
                            ? _c("strong", { staticClass: "label" }, [
                                _vm._v("Price"),
                              ])
                            : _c("strong", { staticClass: "label" }, [
                                _vm._v("You'll get"),
                              ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm._f("price")(line.total))),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm.COMMERCE_SHOW_BUYBACK && _vm.order.buyBackGuarantee
                    ? _c(
                        "div",
                        { staticClass: "insert--cart-lines__buyback" },
                        [
                          _c("b", [_vm._v("Buyback Guarantee")]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(_vm._f("price")(4)))]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.COMMERCE_SHOW_REFUND && _vm.order.refundGuarantee
                    ? _c(
                        "div",
                        { staticClass: "insert--cart-lines__buyback" },
                        [
                          _c("b", [_vm._v("Refund Guarantee")]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(_vm._f("price")(3)))]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.deliverySlot
                    ? _c(
                        "div",
                        { staticClass: "insert--cart-lines__buyback" },
                        [
                          _c("b", [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.deliverySlot.deliveryMethodName) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("price")(_vm.deliverySlot.price)
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "insert--cart-lines__total" }, [
                    _vm.order.product.id !== 3
                      ? _c("b", [_vm._v("Total to pay")])
                      : _c("b", [_vm._v("Total you will receive")]),
                    _vm._v(" "),
                    _c("p", { staticClass: "total-price" }, [
                      _vm._v(_vm._s(_vm._f("price")(_vm.order.total))),
                    ]),
                  ]),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "delivery" }, [
              _vm.order.deliveryAddress
                ? _c(
                    "div",
                    [
                      _vm.order.product.id === 1
                        ? _c("b", [_vm._v("Delivery Address")])
                        : _c("b", [_vm._v("Collection Address")]),
                      _vm._v(" "),
                      _c("delivery-address", { attrs: { order: _vm.order } }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.deliverySlot
                ? _c("div", [
                    _vm.order.product.id === 1
                      ? _c("b", [_vm._v("Delivery Date")])
                      : _c("b", [_vm._v("Collection Date")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "column" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm._f("date")(_vm.deliverySlot.date))),
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _vm.order.product.id === 1 && _vm.isMonday()
                        ? _c("div", [
                            _c("span", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    "If you place an order for Monday, Royal Mail may attempt delivery on Saturday. This is beyond our\n                control. If you miss your delivery, Royal Mail will automatically arrange a redelivery for the next day.\n                                  If you have the Royal Mail app you can redirect the delivery to a later date."
                                  ) +
                                  "\n              "
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.deliverySlot.isInStock && _vm.order.product.id === 2
                        ? _c("div", [
                            _c("span", [
                              _c("br"),
                              _vm._v(" "),
                              _c("strong", [
                                _vm._v(
                                  "Free collection - available from 2pm. "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    "If your order is available for collection before 2pm, we’ll contact you"
                                  ) +
                                  "\n              "
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row title" }, [
      _c("h4", [_vm._v("Order summary")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("strong", [_vm._v("Currency")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("strong", [_vm._v("Quantity")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("strong", [_vm._v("Rate")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }