var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.selectedCurrency && _vm.selectedProduct
    ? _c(
        "div",
        {
          staticClass: "amount-select",
          class: { "visibility-hidden": !_vm.currencyRate },
        },
        [
          _c("div", { staticClass: "label" }, [
            _c("div", { staticClass: "selling row" }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.content.amountSelectSellLabel ||
                      "2. Amount to Sell Back"
                  )
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("span", [_vm._v("You will receive")]),
                  _vm._v(" "),
                  _c("Tooltip", {
                    attrs: { content: _vm.content.sellingTooltip },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "inputs" }, [
            _c("div", { staticClass: "input", attrs: { tabindex: "-1" } }, [
              _c("small", {
                staticClass: "currency-code",
                domProps: { innerHTML: _vm._s(_vm.selectedCurrency.symbol) },
              }),
              _vm._v(" "),
              _c("input", {
                attrs: { type: "number", min: "0", tabindex: "0" },
                domProps: { value: _vm.source },
                on: { keyup: _vm.onUpdate },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "icon icon__gbp" }),
              _vm._v(" "),
              _c("span", { staticClass: "currency-code" }, [_vm._v("£")]),
              _vm._v(" "),
              _c("span", { staticClass: "sell" }, [
                _vm._v(_vm._s(_vm.target.toFixed(2))),
              ]),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }