var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.shoppingCart && _vm.selectedProduct
    ? _c("div", { staticClass: "notification restriction product-locked" }, [
        _c("p", [
          _vm._v(
            "\n    " + _vm._s(_vm.selectedProduct.name) + " selected -\n    "
          ),
          _c("button", { on: { click: _vm.startAgain } }, [
            _vm._v("Start again"),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }