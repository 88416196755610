<template>
  <div class="payment-response">
    <Spinner name="circle" color="var(--color-links)" />
  </div>
</template>

<script>
import { getOrder } from '@vue/services/api/checkout.api'
import Spinner from 'vue-spinkit'
import OrderStates from '@/vue/common/constants/order'
import {
  CHECKOUT_PAYMENT_RECEIVED,
  CHECKOUT_PAYMENT_FAILED,
  CHECKOUT_PAYMENT_STATE_UNKNOWN
} from '@/vue/common/constants/windowEventDiscriminators'
import { trackError } from '@/telemetry'

export default {
  name: 'PaymentResponse',
  components: {
    Spinner
  },
  async mounted() {
    while (true) {
      try {
        const order = await getOrder().then(r => r.data);

        if (order.state.value === OrderStates.COMPLETED) {
          window.parent.postMessage({ type: CHECKOUT_PAYMENT_RECEIVED }, window.origin);
          break;
        }
        else {
          window.parent.postMessage({ type: CHECKOUT_PAYMENT_FAILED }, window.origin);
          break;
        }
      }
      catch (e) {
        trackError(e);

        // post message for alerting customer of issue
        window.parent.postMessage({ type: CHECKOUT_PAYMENT_STATE_UNKNOWN }, window.origin);

        // Try again
        const retryDelay = 1000; // 1 second
        await new Promise(resolve => setTimeout(resolve, retryDelay));
      }
    }
  }
}
</script>

<style lang="css" scoped>
.payment-response {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
