var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "confirmation--customer" }, [
    _c("div", { staticClass: "column" }, [
      _c("b", [_vm._v("Name")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n      " +
            _vm._s(_vm.customer.title) +
            "\n      " +
            _vm._s(_vm.customer.firstName) +
            "\n      " +
            _vm._s(_vm.customer.lastName) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      !_vm.store.isMeta ? _c("b", [_vm._v("Home address")]) : _vm._e(),
      _vm._v(" "),
      _vm.store.isMeta
        ? _c("b", [_vm._v("Home and delivery address")])
        : _vm._e(),
      _vm._v(" "),
      _c("address", [_vm._v(_vm._s(_vm._f("address")(_vm.customer.address)))]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "column" }, [
      _c("b", [_vm._v("Date of birth")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(_vm._s(_vm._f("dateOfBirth")(_vm.customer.dateOfBirth))),
      ]),
      _vm._v(" "),
      _c("b", [_vm._v("Email address")]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.customer.email))]),
      _vm._v(" "),
      _c("b", [_vm._v("Phone number")]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.customer.phoneNumber))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }