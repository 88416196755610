<template>
  <address v-if="order !== null">
    <span
      v-if="
        order.product.id === 1 &&
        order.customer &&
        order.customer.firstName &&
        order.customer.lastName
      "
    >
      {{ order.customer.firstName }} {{ order.customer.lastName }},<br />
    </span>
    <span v-if="order.deliveryAddress.buildingNameNumber">
      {{ order.deliveryAddress.buildingNameNumber }},<br />
    </span>
    <span v-if="order.deliveryAddress.address1">
      {{ order.deliveryAddress.address1 }},<br />
    </span>
    <span v-if="order.deliveryAddress.address2">
      {{ order.deliveryAddress.address2 }},<br />
    </span>
    <span v-if="order.deliveryAddress.address3">
      {{ order.deliveryAddress.address3 }},<br />
    </span>
    <span v-if="order.deliveryAddress.address4">
      {{ order.deliveryAddress.address4 }},<br />
    </span>
    {{ order.deliveryAddress.postcode }}<br />
  </address>
</template>

<script>
export default {
  name: 'DeliverAddress',
  props: ['order']
}
</script>
