<template>
  <div>
    <button v-for="interval in intervals"
            :key="interval"
            @click="syncToInterval(interval)"
            :class="{ 'switcher-active-item': interval === activeInterval, 'switcher-item': true }">
      {{ interval }}
    </button>

    <div ref="chartContainer" class="chart-container">
      <div v-if="showTooltip" ref="tooltip" class="tooltip-style">
        <div class="tooltip-content">
          {{ formattedPrice }}
        </div>
        <div>{{ formattedDate }}</div>
      </div>
    </div>

  </div>
</template>
<script>
  import { createChart } from 'lightweight-charts';

  export default {
    name: 'LWChart',
    props: {
      data: {
        type: Map,
        required: true
      }
    },
    data() {
      return {
        chart: null,
        intervals: ['1W', '1M', '1Y'],
        activeInterval: '1M',       
        showTooltip: false,
        tooltipWidth: 80,
        tooltipHeight: 80,
        tooltipMargin: 10,
        chartData: null,
      };
    },
    computed: {
      formattedPrice() {
        return this.chartData ? parseFloat(this.chartData.value).toFixed(4) : '';
      },
      formattedDate() {
        return this.chartData ? this.chartData.time : '';
      },
    },
    mounted() {
      if (!this.data) {
        return;
      }
      this.createChart();

      window.addEventListener('resize', () => {
        this.$nextTick(() => {
          this.chart.applyOptions({ width: this.$refs.chartContainer.clientWidth });
        });
      });
    },
    methods: {
      createChart() {
        this.chart = createChart(this.$refs.chartContainer, {
          rightPriceScale: {
            scaleMargins: {
              top: 0.2,
              bottom: 0.2,
            },
            borderVisible: true,
          },
          timeScale: {
            borderVisible: true,
          },
          grid: {
            horzLines: {
              color: '#ffffff',
            },
            vertLines: {
              color: '#ffffff',
            },
          },
          handleScale: {
            mouseWheel: false
          }
        });
        this.syncToInterval(this.activeInterval);
      },
      syncToInterval(interval) {
        if (this.series) {
          this.chart.removeSeries(this.series);
          this.series = null;
        }
        this.series = this.chart.addLineSeries({
          color: '#007ea0',
          lineWidth: 2,
          priceFormat: {
            minMove: 0.0001
          }
        });
        // const values = this.data.get(interval).map(item => item.value);
        // const minValue = Math.min(...values);
        // const maxValue = Math.max(...values);
        // const avgValue = (maxValue + minValue) / 2;

        // this.lines = [
        //   { price: minValue, color: '#be1238', title: 'minimum price' },
        //   { price: maxValue, color: '#be1238', title: 'maximum price' },
        //   { price: avgValue, color: '#be1238', title: 'average price' },
        // ];

        // this.lines.forEach(line => {
        //   this.areaSeries.createPriceLine(line);
        // });

        this.series.setData(this.data.get(interval));
        this.chart.timeScale().fitContent();
        this.activeInterval = interval;
        this.chart.subscribeCrosshairMove((param) => {
          this.updateTooltip(param);
        });

      },
      updateTooltip(param) {
        if (
          param.point === undefined ||
          !param.time ||
          param.point.x < 0 ||
          param.point.x > this.$refs.chartContainer.clientWidth ||
          param.point.y < 0 ||
          param.point.y > this.$refs.chartContainer.clientHeight
        ) {
          this.showTooltip = false;
        } else {
          const dateStr = param.time;
          this.showTooltip = true;
          const data = param.seriesData.get(this.series);
          const price = data.value !== undefined ? data.value : data.close;

          this.chartData = { time: dateStr, value: price };
          if (!this.$refs.tooltip) return;
          this.$refs.tooltip.style.display = 'block';
          const y = param.point.y;
          let left = param.point.x + this.tooltipMargin;

          if (left > this.$refs.chartContainer.clientWidth - this.tooltipWidth) {
            left = param.point.x - this.tooltipMargin - this.tooltipWidth;
          }
          let top = y + this.tooltipMargin;
          if (top > this.$refs.chartContainer.clientHeight - this.tooltipHeight) {
            top = y - this.tooltipHeight - this.tooltipMargin;
          }
          this.$refs.tooltip.style.left = left + 'px';
          this.$refs.tooltip.style.top = top + 'px';
        }
      }
    }
  }
</script>

<style scoped>
  .chart-container {
    position: relative;
    width: 100%;
    height: 300px;
  }

  .switcher-item {
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    padding: 6px 8px;
    font-size: 14px;
    color: #262b3e;
    background-color: transparent;
    margin-right: 8px;
    border: 1px solid grey;
    border-radius: 4px;
    outline: none;
  }

  .switcher-item:hover {
      background-color: #f2f3f5;
  }

  .switcher-active-item {
    text-decoration: none;
    cursor: default;
    color: #262b3e;
  }

  .switcher-active-item,
  .switcher-active-item:hover {
    background-color: #e1eff9;
  }

  .tooltip-style {
    position: absolute;
    display: none;
    padding: 8px;
    box-sizing: border-box;
    font-size: 12px;
    text-align: left;
    z-index: 1000;    
    pointer-events: none;
    border: 1px solid;
    border-radius: 2px;
    font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: white;
    color: black;
    border-color: #ccc;
    transition: left 0.3s ease-out, top 0.3s ease-out;
  }

  .tooltip-content {
    font-size: 24px;
    margin: 4px 0px;
  }
</style>
