<template>
  <div class="cart">
    <button class="cart--toggle" @click="toggleCart(!showCart)">
      <span class="icon">
        <div
          class="count"
          v-if="shoppingCart && shoppingCart.lines.length != 0"
        >
          {{ shoppingCart.lines.length }}
        </div>
        <img src="/assets/img/icons/basket.svg" class="svg" />
      </span>
    </button>

    <div class="cart--container" v-on-clickaway="hideCart" v-if="showCart">
      <div class="cart--flex">
        <div class="spinner" v-if="loading">
          <Spinner name="circle" color="var(--color-links)" />
        </div>

        <p v-if="!loading && !shoppingCart">No currency in your cart</p>

        <div v-if="!loading && shoppingCart">
          <countdown />

          <div class="cart--items">
            <cart-item
              v-for="line in shoppingCart.lines"
              :key="line.id"
              :line="line"
            />
          </div>

          <div class="summary">
            <p>
              <strong>{{ shoppingCart.lines.length }}</strong>
              <span v-if="shoppingCart.lines.length === 1">
                currency in your cart
              </span>
              <span v-else>currencies in your cart</span>
            </p>

            <h5 class="total">
              Total to pay <strong>{{ shoppingCart.total | price }}</strong>
            </h5>
          </div>

          <button class="button button--primary" @click="redirectToCheckout()">
            Review &amp; checkout
          </button>

          <button class="button button--cart" @click="abandonCart()">
            Empty cart
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'

import { LOADING_CART, TOGGLE_CART } from '@vue/common/constants/mutation.types'
import { hasBearerToken } from '@vue/services/jwt.service'

import Spinner from 'vue-spinkit'
import Countdown from '@vue/common/components/countdown'
import CartItem from './components/cart-item'

export default {
  name: 'Cart',
  mixins: [clickaway],
  components: {
    Spinner,
    CartItem,
    Countdown,
  },
  computed: {
    ...mapState({
      loading: (state) => state.cart.loading,
      showCart: (state) => state.cart.showCart,
      shoppingCart: (state) => state.cart.shoppingCart,
    }),
  },
  methods: {
    ...mapMutations('cart', [LOADING_CART, TOGGLE_CART]),
    ...mapActions({
      getCart: 'cart/getCart',
      abandonCart: 'cart/abandonCart',
    }),
    hideCart() {
      this.toggleCart(false)
    },
    redirectToCheckout() {
      window.location.href =
        this.shoppingCart.product.id === 3
          ? '/selling/view-cart'
          : '/checkout/view-cart'
    },
  },
  watch: {
    shoppingCart() {
      if (!this.shoppingCart) return
      if (this.shoppingCart.lines.length !== 0) return

      this.toggleCart(false)
      this.abandonCart()
    },
  },
  mounted() {
    if (hasBearerToken()) {
      this.getCart()
    }
  },
}
</script>
