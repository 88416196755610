import { getContent, getOrder } from '@vue/services/api/checkout.api'
import { deleteBearerToken } from '@vue/services/jwt.service'

import {
  SET_EXIT_MODAL,
  SET_WARNING_MODAL,
  SET_ADDRESS_MODAL,
  SET_AML_MODAL,
  SET_VELOCITY_MODAL,
  SET_ADDRESS_STRING,
  EXPIRED_ORDER
} from '@vue/common/constants/mutation.types'
import { actionTypes } from '@vue/common/constants/store.types'
const initialState = {
  steps: {
    review: false,
    delivery: false,
    information: false,
    payment: false,
    confirmation: false
  },
  content: null,
  order: null,
  exitModal: false,
  amlModal: false,
  warningModal: false,
  velocityModal: false,
  addressModal: false,
  addressString: null
}

const state = { ...initialState }

const actions = {
  async [actionTypes.GetCheckoutContent]({ state }) {
    const { data } = await getContent()
    state.content = data
  },
  [actionTypes.AbandonCheckout]({ state, dispatch }) {
    deleteBearerToken()

    return new Promise(resolve => {
      state.order
        ? (state.order = null)
        : dispatch('cart/abandonCart', null, { root: true })

      resolve()
    })
  },
  [actionTypes.GetOrder]({ state }) {
    return getOrder()
      .then(({ data }) => {
        state.order = data
      })
  }
}

const mutations = {
  [SET_EXIT_MODAL](state, flag) {
    state.exitModal = flag
    state.warningModal = false
    state.addressModal = false
    state.amlModal = false
    state.velocityModal = false
  },
  [SET_WARNING_MODAL](state, flag) {
    state.warningModal = flag
    state.exitModal = false
    state.addressModal = false
  },
  [SET_ADDRESS_MODAL](state, flag) {
    state.addressModal = flag
    state.warningModal = false
  },
  [SET_AML_MODAL](state, flag) {
    state.amlModal = flag
  },
  [SET_VELOCITY_MODAL](state, flag) {
    state.velocityModal = flag
  },
  updateStep(state, step) {
    for (const key in state.steps) {
      state.steps[key] = false
    }

    state.steps[step] = true
  },
  [SET_ADDRESS_STRING](state, addressString) {
    state.addressString = addressString
  },
  [EXPIRED_ORDER](state) {
    state.order = null
    deleteBearerToken()
  }
}

const getters = {}

const checkout = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default checkout
