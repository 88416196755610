<template>
  <div class="tiered-message" v-if="CurrencyRate.rateTiers && CurrencyRate.rateTiers.length > 0">
    <p v-if="nextTier()">
      Buy
      <strong>
        {{ nextTier().amount | price(line.symbol) }}
        {{ line.shortName }}
      </strong>
      or more to
      <button @click="boostRate" class="boost">boost your rate</button> and
      receive our improved rate
    </p>
    <p v-else-if="nextTier() == false">
    </p>
    <p v-else>
      Rate fully boosted! You're receiving our Best Rate
    </p>
  </div>
</template>

<script>
  import BigNumber from 'bignumber.js'

  export default {
    name: 'TieredMessage',
    props: {
      targetQuantity: Number,
      CurrencyRate: Object,
      line: Object,
    },

    methods: {
      nextTier() {
        if (!this.CurrencyRate.rateTiers || this.CurrencyRate.rateTiers.length < 1) {
          return false
        }
        const qty = this.targetQuantity || 0;
        const tieredRates = this.CurrencyRate.rateTiers
          .filter((i) => i.amount > qty)
          .sort((a, b) => a.amount - b.amount)

        return tieredRates[0] || null
      },
      boostRate() {
        const target = BigNumber(this.nextTier().amount); // FC
        const source = BigNumber(this.nextTier().amount) // GBP
          .div(this.nextTier().rate)
          .dp(2, BigNumber.ROUND_HALF_EVEN)
          .toNumber();
        this.$emit('boostRate', { source: source, target: target });
      }
    },
  }
</script>
