var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.warningModal
    ? _c("div", { staticClass: "modal modal--warning" }, [
        _c("div", { staticClass: "modal--inner" }, [
          _c(
            "div",
            { staticClass: "modal--header" },
            [
              _c("h3", [_vm._v("Your checkout is about to expire")]),
              _vm._v(" "),
              _c("countdown"),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "close",
              on: {
                click: function ($event) {
                  return _vm.setWarningModal(false)
                },
              },
            },
            [_vm._v("Close")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "button-list" }, [
            _c(
              "button",
              {
                staticClass: "button button--primary",
                on: {
                  click: function ($event) {
                    return _vm.setWarningModal(false)
                  },
                },
              },
              [_vm._v("\n        Continue with my order\n      ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button button--secondary",
                on: { click: _vm.returnToHomepage },
              },
              [_vm._v("\n        Return to the homepage\n      ")]
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }