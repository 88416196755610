<template>
  <div class="modal modal--branch">
    <div class="modal--inner" ref="modalContainer">
      <div class="modal--close">
        <button class="close" @click="close">close</button>
      </div>
      <div class="spinner" v-if="loading">
        <Spinner name="circle" color="var(--color-links)" />
      </div>

      <div v-if="!loading">
        <div class="stores">
          <Branch
            v-for="(branch, index) in currentPage"
            :key="index"
            :branch="branch"
          />
        </div>

        <div class="pager">
          <button
            class="button button--secondary"
            @click="prevPage"
            :disabled="pageNumber == 0"
          >
            Previous
          </button>
          <button
            class="button button--secondary"
            @click="nextPage"
            :disabled="pageNumber >= pageCount - 1"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import axios from 'axios'
  import Spinner from 'vue-spinkit'
  import Branch from './branch'

  export default {
    name: 'BranchFinderModal',
    props: ['location'],
    components: {
      Spinner,
      Branch
    },
    data() {
      return {
        pageNumber: 0,
        loading: true,
        branches: []
      }
    },
    computed: {
      // ...mapState({
      //   stores: state => state.store.stores
      // }),
      // ...mapGetters('store', ['loading']),
      pageCount() {
        return Math.ceil(this.branches.length / 5)
      },
      currentPage() {
        return this.branches.slice(this.pageNumber * 5, this.pageNumber * 5 + 5)
      }
    },
    mounted() {
      axios.post('/api/branches/nearby', this.location)
        .then(response => {
          this.branches = response.data.map(x => {
            x.branch.distanceInMiles = x.distanceInMiles
            return x.branch
          })
        })
        .finally(() => {
          this.loading = false;
        })
    },
    methods: {
      nextPage() {
        this.$refs.modalContainer.scrollTop = 0;
        this.pageNumber++
      },
      prevPage() {
        this.$refs.modalContainer.scrollTop = 0;
        this.pageNumber--
      },
      close() {
        this.$emit('close')
      }
    }
  }
</script>
