import Vue from 'vue'

import store from '@vue/store/index'
import filters from '@vue/common/filters'
import Payment from './payment'

if (document.getElementById('checkout-hosted-payment-form')) {
  Vue.use(filters)

  new Vue({
    el: '#checkout-hosted-payment-form',
    render: createElement => createElement(Payment),
    store
  })
}
