<template>
  <div class="modal modal--expired" v-if="remaining != null && remaining < 1">
    <div class="modal--inner">
      <h2>Your cart has expired</h2>

      <div class="button-list">
        <button class="button button--primary" @click="recreateCart">
          Recreate cart
        </button>
        <button class="button button--secondary" @click="AbandonCart">
          Abandon cart
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { SET_WARNING_MODAL } from '@vue/common/constants/mutation.types'
import { getCartExpiryUnixTimestamp, calculateSecondsUntilCartExpires } from '@vue/services/cart.service'

export default {
  name: 'ModalExpired',
  data() {
    return {
      remaining: null,
      tick: null
    }
  },
  computed: {
    ...mapState('cart', ['shoppingCart'])
  },
  methods: {
    ...mapMutations('checkout', [SET_WARNING_MODAL]),
    ...mapActions({
      recreateCart: 'cart/recreateCart',
      abandonCart: 'cart/abandonCart'
    }),
    AbandonCart() {
      this.abandonCart();
      window.location = '/'
    },

    startCountdown() {
      const cartExpiryTimestamp = getCartExpiryUnixTimestamp();

      if (cartExpiryTimestamp === null) {
        return;
      }

      this.tick = setInterval(() => {
        this.remaining = calculateSecondsUntilCartExpires(cartExpiryTimestamp)
      }, 200)
    }
  },
  watch: {
    shoppingCart() {
      if (
        window.location.pathname !== '/checkout/payment-response' ||
        window.location.pathname !== '/checkout/unsuccessful'
      ) {
        this.startCountdown()
      }
    },
    remaining(newValue) {
      if (!newValue) {
        clearInterval(this.tick)
        this.tick = null
      }

      if (this.remaining < 1) {
        this.setWarningModal(false)
      }
    }
  },
    mounted() {
    if (
      window.location.pathname !== '/checkout/payment-response' ||
      window.location.pathname !== '/checkout/unsuccessful'
    ) {
      this.startCountdown()
    }
  }
}
</script>
