<template>
  <div class="calculator-container" tabindex="0">
    <div id="calculator" :class="{ loading: isLoading }">
      <div class="cromessage" v-if="!isLoading && (content.cromessage && content.cromessage != '')"
        v-html="content.cromessage" />
      <div class="spinner" v-if="isLoading">
        <Spinner name="circle" color="var(--color-links)" />
      </div>

      <div v-else>
        <calculator-select v-if="COMMERCE_IS_SELLING" />

        <div class="inner">
          <h5 class="calc-title" v-if="!COMMERCE_IS_SELLING">
            Buy Travel Money
          </h5>
          <div class="column">
            <div class="label row">
              <span>{{
                content.currencySelectLabel || '1. Select your currency'
              }}</span>
              <Tooltip :content="content.currencyTooltip" />
            </div>
            <CurrencySelect v-if="selectedCurrency && filteredCurrencies.length" :currencies="filteredCurrencies"
              :currency="selectedCurrency" @selected="selectCurrency($event)" />
          </div>

          <amount-select-buy v-if="type !== SELL_ID" />
          <amount-select-sell v-if="type == SELL_ID" />

          <amount-result v-if="type !== SELL_ID" />
          <tiered-message v-if="type !== SELL_ID" />
          <exchange-rate />
          <product-select v-if="type !== SELL_ID" />
          <product-selection />
          <store-finder />
          <calculator-result />
          <modal-store v-if="showStoreModal" />

          <div v-if="content.optionalBuyContent && type === BUY_ID">
            <div class="optional-content" v-html="content.optionalBuyContent" />
          </div>

          <div v-if="content.optionalSellContent && type === SELL_ID">
            <div class="optional-content" v-html="content.optionalSellContent" />
          </div>
        </div>
      </div>
    </div>
    <div v-html="content.trustPilotScript" v-if="content && content.trustPilotScript && isPageStore"
      class="trustpilot-banner" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import {
  BUY_ID,
  SELL_ID,
  COMMERCE_IS_SELLING,
} from '@vue/common/constants/config'
import Spinner from 'vue-spinkit'
import Tooltip from '@vue/common/components/Tooltip'
import CurrencySelect from '@vue/common/components/form/CurrencySelect'
import CalculatorSelect from './components/calculator-select'
import AmountSelectBuy from './components/amount-select-buy'
import AmountSelectSell from './components/amount-select-sell'
import AmountResult from './components/amount-result'
import ExchangeRate from './components/exchange-rate'
import TieredMessage from './components/tiered-message'
import StoreFinder from './components/store-finder'
import ProductSelect from './components/product-select'
import ProductSelection from './components/product-selection'
import CalculatorResult from './components/calculator-result'
import ModalStore from './components/modal-store'

export default {
  name: 'Calculator',
  components: {
    Spinner,
    Tooltip,
    CalculatorSelect,
    CurrencySelect,
    AmountSelectBuy,
    AmountSelectSell,
    AmountResult,
    ExchangeRate,
    StoreFinder,
    TieredMessage,
    ProductSelect,
    ProductSelection,
    CalculatorResult,
    ModalStore,
  },
  data() {
    return {
      BUY_ID,
      SELL_ID,
      COMMERCE_IS_SELLING,
      isPageStore: true,
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'calculator/isLoading',
      filteredCurrencies: 'currency/filteredCurrencies',
    }),
    ...mapState({
      shoppingCart: (state) => state.cart.shoppingCart,
      showStoreModal: (state) => state.calculator.showStoreModal,
      selectedCurrency: (state) => state.currency.selectedCurrency,
      content: (state) => state.calculator.content,
      type: (state) => state.calculator.type,
    }),
  },
  watch: {
    shoppingCart(cart) {
      if (cart && cart.product.id === 3) {
        this.setCalculator(SELL_ID)
      }
    },
  },
  methods: {
    ...mapActions({
      getCart: 'cart/getCart',
      selectCurrency: 'currency/selectCurrency',
      getCurrencies: 'currency/getCurrencies',
      getDefaultStore: 'store/getDefaultStore',
      getCalculatorContent: 'calculator/getCalculatorContent',
      getMatrix: 'calculator/getMatrix',
    }),
    ...mapMutations({
      setCalculator: 'calculator/setCalculator',
    }),
  },
  mounted() {
    this.getCurrencies()
    this.getMatrix()
    this.getDefaultStore()
    this.getCalculatorContent()
    if (window.doc_type === "pageStore" || window.doc_type === "pageOurStoresRegion" || window.doc_type === "pageOurStores") {
      this.isPageStore = false;
    }
  },
}
</script>
