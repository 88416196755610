<template>
  <div class="cart--item">
    <div class="cart--flex">
      <div :class="'icon icon__' + line.currency.code.toLowerCase()"></div>
      <div class="cart--inner">
        <div class="code">{{ line.currency.code }}</div>
        <div class="quantity">
          <span>Amount:</span>
          <quantity :value="line" />
        </div>
      </div>
      <span class="price">{{ line.total | price }}</span>
    </div>

    <button class="close" @click="deleteLine(line.id)"></button>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Quantity from '@vue/common/components/quantity'

export default {
  name: 'CartItem',
  props: ['line'],
  components: {
    Quantity
  },
  methods: {
    ...mapActions({
      deleteLine: 'cart/deleteLine'
    })
  }
}
</script>
