import Vue from 'vue'

import store from '@vue/store/index'
import filters from '@vue/common/filters'
import Confirmation from './confirmation'

if (document.getElementById('checkout-order-confirmation')) {
  Vue.use(filters)

  window.confirmation = new Vue({
    el: '#checkout-order-confirmation',
    render: createElement => createElement(Confirmation),
    store
  })
}
