import jwt_decode from 'jwt-decode'
import { hasBearerToken, getToken } from '@vue/services/jwt.service'

/**
 * @deprecated please don't decode the jwt ~10 times per second, what a waste of cpu.
 */
export const getCartRemaining = () => {
  if (!hasBearerToken()) {
    return null
  }

  const cartExpiryClaim = jwt_decode(getToken())['ice:cart_exp']
  const expiresUnixTime = parseInt(cartExpiryClaim)
  const nowUnixTime = Math.floor(Date.now() / 1000)
  const diff = expiresUnixTime - nowUnixTime

  return diff >= 0 ? diff : 0
}

/**
 * @function getCartExpiryUnixTimestamp
 * @returns {number|null} The value of the cart_exp claim when present, otherwise null.
 */
export const getCartExpiryUnixTimestamp = () => {
  if (!hasBearerToken()) {
    return null
  }

  const cartExpiryClaim = jwt_decode(getToken())['ice:cart_exp']
  return parseInt(cartExpiryClaim)
}

/**
 * @function calculateTimeRemaining
 * @param {number} cartExpiryUnixTimestamp - unix timestamp representing cart expiry.
 * @returns {number} The remaining time until expiry (in seconds) or 0 if already expired.
 */
export const calculateSecondsUntilCartExpires = (cartExpiryUnixTimestamp) => {
  const nowUnixTime = Math.floor(Date.now() / 1000)
  const diff = cartExpiryUnixTimestamp - nowUnixTime

  return diff >= 0 ? diff : 0
}
