export const SET_CALCULATOR_CONTENT = 'SET_CALCULATOR_CONTENT'

export const SET_CURRENCIES = 'SET_CURRENCIES'
export const SET_CURRENCY = 'SET_CURRENCY'
export const SET_CURRENCY_RATE = 'SET_CURRENCY_RATE'

export const SET_PRODUCTS = 'setProducts'
export const SET_PRODUCT = 'setProduct'
export const SET_SITEPRODUCTS = 'setSiteProducts'

export const SET_STORES = 'SET_STORES'

export const SET_RESULT = 'SET_RESULT'
export const SET_STORE_SELECT = 'SET_STORE_SELECT'
export const SET_STORE_MODAL = 'SET_STORE_MODAL'

export const LOADING_CART = 'loadingCart'
export const TOGGLE_CART = 'toggleCart'
export const SET_CART = 'SET_CART'
export const REMOVE_LINE = 'REMOVE_LINE'
export const CLEAR_CART = 'CLEAR_CART'
export const EXPIRED_ORDER = 'EXPIRED_ORDER'

export const SET_ADDRESS_STRING = 'setAddressString'

export const SET_EXIT_MODAL = 'setExitModal'
export const SET_WARNING_MODAL = 'setWarningModal'
export const SET_ADDRESS_MODAL = 'setAddressModal'
export const SET_AML_MODAL = 'setAmlModal'
export const SET_VELOCITY_MODAL = 'setVelocityModal'

