import httpCommerce from './httpCommerce'
import httpUmbraco from './httpUmbraco'
import { SITE_ID } from '@vue/common/constants/config'

export const getContent = () => {
  return httpUmbraco.get('/api/content/calculator')
}

export const getMatrix = () => {
  return httpCommerce.get(`/api/sites/${SITE_ID}/availability/matrix`)
}

export default { getContent, getMatrix }