import Vue from 'vue'
import PaymentResponse from './payment-response'

if (document.getElementById('checkout-payment-response')) {

  new Vue({
    el: '#checkout-payment-response',
    render: createElement => createElement(PaymentResponse)
  })
}
