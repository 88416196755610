var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.order
    ? _c("div", [
        _c("section", { staticClass: "checkout" }, [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              { staticClass: "checkout--content" },
              [
                _vm._l(_vm.options, function (deliverySlot, index) {
                  return _c("DeliverySlotCard", {
                    key: index,
                    attrs: { deliverySlot: deliverySlot },
                    model: {
                      value: _vm.selected,
                      callback: function ($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected",
                    },
                  })
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "row delivery--next" },
                  [
                    _vm.loading
                      ? _c("Spinner", {
                          attrs: {
                            name: "circle",
                            color: "var(--color-links)",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.loading
                      ? _c(
                          "button",
                          {
                            staticClass: "button button--primary",
                            attrs: { disabled: !_vm.selected },
                            on: {
                              click: function ($event) {
                                return _vm.selectDeliverySlot(_vm.selected.id)
                              },
                            },
                          },
                          [_vm._v("\n            Next\n          ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "info-container" }, [
              _c(
                "div",
                { staticClass: "parent-container" },
                [
                  _c("order-summary", {
                    attrs: { deliverySlot: _vm.selected },
                  }),
                  _vm._v(" "),
                  _vm.summaryBoxContent
                    ? _c("div", {
                        staticClass: "info",
                        domProps: { innerHTML: _vm._s(_vm.summaryBoxContent) },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }