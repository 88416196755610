import Vuex from 'vuex'
import Vue from 'vue'

import calculator from './modules/calculator.module'
import currency from './modules/currency.module'
import product from './modules/product.module'
import store from './modules/store.module'
import cart from './modules/cart.module'
import checkout from './modules/checkout.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    calculator,
    currency,
    product,
    store,
    cart,
    checkout
  }
})
