var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "container" }, [
      _vm.order
        ? _c(
            "section",
            {
              staticClass: "checkout--confirmation",
              attrs: { id: "order-confirmation-print" },
            },
            [
              _c("div", { staticClass: "confirmation--header" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "confirmation--title" }, [
                  _c("h4", [_vm._v("Order Confirmation")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "order-ref" }, [
                    _vm._v(_vm._s(_vm.order.externalOrderId)),
                  ]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.nowDate))]),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button button--primary",
                    on: { click: _vm.print },
                  },
                  [_vm._v("\n          Print order confirmation\n        ")]
                ),
              ]),
              _vm._v(" "),
              _vm.order.product.id !== 3
                ? _c("confirmation-summary")
                : _vm._e(),
              _vm._v(" "),
              _vm.order.product.id !== 3
                ? _c("div", { staticClass: "insert--cart-lines__total" }, [
                    _c("b", [_vm._v("Total")]),
                    _vm._v(" "),
                    _c("h4", [
                      _vm._v(_vm._s(_vm._f("price")(_vm.order.total))),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.order.deliverySlot
                ? _c("div", { staticClass: "confirmation--delivery" }, [
                    _c("div", { staticClass: "column" }, [
                      _c("div", [
                        _vm.order.product.id === 1
                          ? _c("b", [_vm._v("Delivery method")])
                          : _c("b", [_vm._v("Collection details")]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.order.deliverySlot.deliveryMethodName
                              ) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm._f("date")(_vm.order.deliverySlot.date)
                              ) +
                              "\n            "
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "column" }, [
                      _c(
                        "div",
                        [
                          _vm.order.product.id === 1
                            ? _c("b", [_vm._v("Delivery address")])
                            : _c("b", [_vm._v("Collection address")]),
                          _vm._v(" "),
                          _c("delivery-address", {
                            attrs: { order: _vm.order },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.order.store.isMeta
                ? _c("div", { staticClass: "confirmation--delivery" }, [
                    _c(
                      "div",
                      { staticClass: "column" },
                      [_c("times", { attrs: { storeId: _vm.order.store.id } })],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.order.product.id === 3
                ? _c("confirmation-summary")
                : _vm._e(),
              _vm._v(" "),
              _vm.order.product.id == 3
                ? _c("div", { staticClass: "insert--cart-lines__total" }, [
                    _c("b", [_vm._v("Total you'll receive")]),
                    _vm._v(" "),
                    _c("h4", [
                      _vm._v(_vm._s(_vm._f("price")(_vm.order.total))),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("h4", [_vm._v("Your details")]),
              _vm._v(" "),
              _c("customer", {
                attrs: { customer: _vm.order.customer, store: _vm.order.store },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "print-logo" }, [
      _c("img", { attrs: { src: "/logo-mobile" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }