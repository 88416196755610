var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal modal--branch" }, [
    _c("div", { ref: "modalContainer", staticClass: "modal--inner" }, [
      _c("div", { staticClass: "modal--close" }, [
        _c("button", { staticClass: "close", on: { click: _vm.close } }, [
          _vm._v("close"),
        ]),
      ]),
      _vm._v(" "),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "spinner" },
            [
              _c("Spinner", {
                attrs: { name: "circle", color: "var(--color-links)" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading
        ? _c("div", [
            _c(
              "div",
              { staticClass: "stores" },
              _vm._l(_vm.currentPage, function (branch, index) {
                return _c("Branch", { key: index, attrs: { branch: branch } })
              }),
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "pager" }, [
              _c(
                "button",
                {
                  staticClass: "button button--secondary",
                  attrs: { disabled: _vm.pageNumber == 0 },
                  on: { click: _vm.prevPage },
                },
                [_vm._v("\n          Previous\n        ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button button--secondary",
                  attrs: { disabled: _vm.pageNumber >= _vm.pageCount - 1 },
                  on: { click: _vm.nextPage },
                },
                [_vm._v("\n          Next\n        ")]
              ),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }