<template>
  <div>
    <div class="tooltip-toggle"></div>
    <div v-if="content" class="tooltip">
      <h6>{{ content.title }}</h6>
      <p v-html="lineBreaks(content.copy)" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    content: {
      type: Object,
      default: () => {
        return {
          title: '',
          copy: '',
        }
      },
    },
  },
  methods: {
    lineBreaks(input) {
      if (input) return input.replace('\n', '<br/><br/>')
    },
  },
}
</script>
