var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currencies
    ? _c("div", { staticClass: "currencies" }, [
        _c("div", { staticClass: "currency-select" }, [
          _c("div", { staticClass: "select" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "on-clickaway",
                    rawName: "v-on-clickaway",
                    value: _vm.hideSelect,
                    expression: "hideSelect",
                  },
                  { name: "focus", rawName: "v-focus" },
                ],
                staticClass: "select-styled",
                class: { active: !_vm.hidden },
                attrs: { tabindex: "0", "close-selects": _vm.hideSelect },
                on: { click: _vm.onCurrencySearchFocus },
              },
              [
                _vm.selected || _vm.editMode
                  ? _c(
                      "div",
                      { staticClass: "location-item" },
                      [
                        _vm.editMode
                          ? [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.searchText,
                                    expression: "searchText",
                                  },
                                ],
                                ref: "txtCurrencyCode",
                                staticClass: "search",
                                attrs: { type: "text", autocomplete: "off" },
                                domProps: { value: _vm.searchText },
                                on: {
                                  focus: _vm.onCurrencySearchFocus,
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.searchText = $event.target.value
                                  },
                                },
                              }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.editMode
                          ? [
                              _c("div", {
                                staticClass: "icon",
                                class:
                                  "icon__" + _vm.selected.code.toLowerCase(),
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "code" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.selected.code) +
                                    "\n            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "label" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.selected.pluralName) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  : _c("div", [_vm._v("Select a currency")]),
              ]
            ),
            _vm._v(" "),
            !_vm.hidden
              ? _c(
                  "div",
                  { ref: "selectDropdown", staticClass: "select-custom items" },
                  [
                    _c(
                      "div",
                      { staticClass: "list", attrs: { tabindex: "0" } },
                      [
                        _vm._l(_vm.searchCurrency, function (currency, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "option",
                              attrs: { tabindex: "-1" },
                              on: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.selectCurrency(currency)
                                },
                                click: function ($event) {
                                  return _vm.selectCurrency(currency)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "location-item" }, [
                                _c("div", {
                                  staticClass: "icon",
                                  class: "icon__" + currency.code.toLowerCase(),
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "code" }, [
                                  _vm._v(_vm._s(currency.code)),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "label" }, [
                                  _vm._v(_vm._s(currency.pluralName)),
                                ]),
                              ]),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _vm.noCurrencyFound
                          ? _c("div", { staticClass: "option" }, [
                              _vm._v("No results found"),
                            ])
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _vm.currency &&
        _vm.currency.currencyRestrictions.length &&
        _vm.selectedProduct.id !== 3
          ? _c(
              "div",
              { staticClass: "notification restriction" },
              _vm._l(
                _vm.currency.currencyRestrictions,
                function (restriction, index) {
                  return _c("p", { key: index }, [
                    _vm._v("\n      " + _vm._s(restriction.message) + "\n    "),
                  ])
                }
              ),
              0
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }