<!--
  This component is much like times.vue but without relying on vuex.
  This component should be simple, it doesn't require a state container.
-->
<template>
  <div class="opening-times">
    <b>Opening times</b>

    <div>
      <div v-for="item in value" class="row">
        <span>
          {{ item.dayOfWeek }}
        </span>

        <span v-if="item.isOpen">
          {{ item.openTime  }} - {{ item.closeTime }}
        </span>
        <span v-else>
          Closed
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default{
  name: 'OpeningTimes',
  props: ['value'],
}
</script>
