<template>
  <div  class="tiered-message"  > 
    <p v-if="isNextTier(nextTier)">
      Rate fully boosted! You're receiving our Best Rate
    </p>
    <p v-else-if="isNextTier(nextTier) == null">    
    </p>
    <p v-else>
      Buy
      <strong>
        {{ nextTier.amount | price(selectedCurrency.symbol) }}
        {{ selectedCurrency.shortName }}
      </strong>
      or more to
      <button @click="boostRate" class="boost">boost your rate</button> and
      receive our improved rate
    </p>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import BigNumber from 'bignumber.js'
export default {
  name: 'TieredMessage',
  data() {
    return {
      amount: null,
      rate: null
    }
  },
  computed: {
    ...mapState({
      currencyRate: state => state.currency.currencyRate,
      selectedCurrency: state => state.currency.selectedCurrency,
      targetQuantity: state => state.currency.targetQuantity
    }),
    ...mapGetters({
      tieredRate: 'calculator/tieredRate',
      nextTier: 'calculator/nextTier',
    }),
  },
  methods: {
    ...mapMutations({
      setTargetQuantity: 'currency/setTargetQuantity',
      setSource: 'currency/setSource',
      setTarget: 'currency/setTarget'
    }),
    boostRate() {
      const target = BigNumber(this.nextTier.amount); // FC

      const source = BigNumber(this.nextTier.amount) // GBP
        .div(this.nextTier.rate)
        .dp(2, BigNumber.ROUND_HALF_EVEN)
        .toNumber();

      this.setSource(source);
      this.setTarget(target);
      this.setTargetQuantity(Math.floor(target))
    },
    isNextTier(nextTier) {      
    if (nextTier == null){
      if(this.currencyRate.rateTiers && this.currencyRate.rateTiers.length < 1){
         return null
      }else{
        return true
      }
    }
    if (nextTier != null) {
      return false
    }
  }
  }
}
</script>
