var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "opening-times" }, [
    _c("b", [_vm._v("Opening times")]),
    _vm._v(" "),
    _c(
      "div",
      _vm._l(_vm.value, function (item) {
        return _c("div", { staticClass: "row" }, [
          _c("span", [
            _vm._v("\n        " + _vm._s(item.dayOfWeek) + "\n      "),
          ]),
          _vm._v(" "),
          item.isOpen
            ? _c("span", [
                _vm._v(
                  "\n        " +
                    _vm._s(item.openTime) +
                    " - " +
                    _vm._s(item.closeTime) +
                    "\n      "
                ),
              ])
            : _c("span", [_vm._v("\n        Closed\n      ")]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }