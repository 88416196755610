<template>
  <div>
    <div class="review--item">
      <div class="review--content">
        <span>Currency</span>

        <div class="review--row">
          <div :class="`icon icon__${line.currency.code.toLowerCase()}`"></div>

          <div class="review--currency">
            <div class="code">{{ line.currency.code }}</div>
            <p class="label">({{ line.currency.pluralName }})</p>
          </div>
        </div>
      </div>

      <div class="review--quantity">
        <span>Amount</span>

        <div class="input_container" tabindex="-1">
          <div class="Currency-Code">
            <small class="currency-code" v-html="line.currency.symbol"></small>
          </div>
          <quantity :value="line" />
        </div>
       </div>

      <div class="review--content">
        <span>Rate</span>
        <strong>{{ line.rate | rate }}</strong>
      </div>

      <div class="review--content">
        <span v-if="shoppingCart.product.id !== 3">Cost</span>
        <span v-else>You will receive</span>
        <strong>{{ line.total | price }}</strong>
      </div>

      <div class="review--delete">
        <button class="close" @click="deleteLine(line.id)"></button>
      </div>
    </div>
    <tiered-message  v-if="shoppingCart.product.id !== 3 " :targetQuantity="line.qty"  :CurrencyRate="currencyRate" :line="line.currency"  @boostRate="boostRate" />
    <div class="notification restriction" v-if="restrictions.length && shoppingCart.product.id !== 3">
      <p v-for="(restriction, index) in restrictions" :key="index">
        {{ restriction.message }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Quantity from '@vue/common/components/quantity'
  import TieredMessage from './checkout-tiered-message'
  import { getRate } from '@vue/services/api/currency.api'

  export default {
    name: 'ReviewItem',
    props: ['line'],
    data() {
      return {
        currencyRate: {
          rate: null,
          price: null,
        }
      }
    },
    components: {
      Quantity,
      TieredMessage,
    },
    computed: {
      ...mapState({
        currencies: (state) => state.currency.currencies,
        shoppingCart: (state) => state.cart.shoppingCart,
        storeId: (state) => state.store.defaultStore.id,
      }),
      restrictions: function () {
        const currency = this.currencies.find(
          (r) => r.code === this.line.currency.code
        )

        if (!currency) {
          return []
        }

        return currency.currencyRestrictions
      },
    },
    methods: {
      ...mapActions({
        deleteLine: 'cart/deleteLine',
        updateLine: 'cart/updateLine',
      }),
      boostRate(value) {
        this.updateLine({
          id: this.line.id,
          code: this.line.currency.code,
          quantity: value.target,
        })
      },

      getCurrencyRate() {
          const affiliateCode = sessionStorage.getItem('affiliateCode')
            ? sessionStorage.getItem('affiliateCode')
            : null

          getRate({
            storeId: this.storeId,
            productId: this.shoppingCart.product.id,
            currency: this.line.currency.code,
            affiliateCode: affiliateCode,
          }).then(({ data }) => {
            this.currencyRate = data
          })

        },

      },
    mounted() {
      this.getCurrencyRate();
    },
  }
</script>
