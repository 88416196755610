var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "exchange-rate",
      class: { "visibility-hidden": !_vm.currencyRate },
    },
    [
      _vm.selectedCurrency
        ? _c("p", { staticClass: "exchange" }, [
            _c("b", [_vm._v("Exchange rate")]),
            _vm._v(" "),
            _vm.currencyRate
              ? _c("span", [
                  _vm._v(
                    "\n      £1 = " +
                      _vm._s(_vm.selectedCurrency.symbol) +
                      _vm._s(_vm.rate.toFixed(4)) +
                      "\n    "
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }