<template>
  <div class="exchange-rate" :class="{ 'visibility-hidden': !currencyRate }">
    <p class="exchange" v-if="selectedCurrency">
      <b>Exchange rate</b>
      <span v-if="currencyRate">
        £1 = {{ selectedCurrency.symbol
        }}{{ rate.toFixed(4) }}
      </span>
    </p>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'ExchangeRate',
  computed: {
    ...mapState({
      selectedCurrency: state => state.currency.selectedCurrency,
      currencyRate: state => state.currency.currencyRate,
    }),
    ...mapGetters({
      tieredRate: 'calculator/tieredRate',
    }),
    rate() {
      return this.tieredRate.rate || this.currencyRate.rate || 0
    }
  }
}
</script>
