<template>
  <div class="modal modal--exit" v-if="exitModal">
    <div class="modal--inner">
      <h2>{{ content.leaveTitle }}</h2>
      <p>{{ content.leaveText }}</p>
      <button @click="setExitModal(false)" class="close">Close</button>

      <div class="button-list">
        <button
          class="button button--primary"
          @click="setExitModal(false)"
          v-if="!steps.confirmation"
        >
          Continue with my order
        </button>
        <button class="button button--secondary" @click="reset">
          Leave checkout
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { SET_EXIT_MODAL } from '@vue/common/constants/mutation.types'
import { actionTypes } from '@vue/common/constants/store.types'

export default {
  name: 'ModalExit',
  computed: {
    ...mapState({
      content: state => state.checkout.content,
      exitModal: state => state.checkout.exitModal,
      steps: state => state.checkout.steps
    })
  },
  methods: {
    ...mapMutations('checkout', [SET_EXIT_MODAL]),
    ...mapActions('checkout', [actionTypes.AbandonCheckout]),
    reset() {
      this.abandonCheckout().then(() => {
        window.location = '/'
      })
    }
  }
}
</script>
