import jwt_decode from 'jwt-decode'
import * as Sentry from "@sentry/browser";

const EUROCHANGE_COMMERCE_TOKEN = 'eurochange_commerce_token'

/**
 * Sets (or clears) tags relating to the cart/order in sentry context
 * @param {string|null} token - The jwt
 */
const setSentryTags = (token) => {
  const payload = token ? jwt_decode(token) : {};

  Sentry.setTag('commerce.cartId', payload['ice:cart_id']);
  Sentry.setTag('commerce.orderId', payload['ice:order_id']);
}

export const getToken = () => {
  const token = window.localStorage.getItem(EUROCHANGE_COMMERCE_TOKEN);
  setSentryTags(token);
  return token;
}

export const saveToken = token => {
  setSentryTags(token);
  window.localStorage.setItem(EUROCHANGE_COMMERCE_TOKEN, token)
}

export const hasBearerToken = () => {
  return !!window.localStorage.getItem(EUROCHANGE_COMMERCE_TOKEN)
}

export const deleteBearerToken = () => {
  setSentryTags(null);
  window.localStorage.removeItem(EUROCHANGE_COMMERCE_TOKEN)
}

export default { getToken, saveToken, hasBearerToken, deleteBearerToken }
