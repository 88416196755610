import Vue from 'vue'
import PaymentUnsuccessful from './payment-unsuccessful'
import Vuelidate from 'vuelidate'
import store from '@vue/store/index'

if (document.getElementById('checkout-payment-unsuccessful')) {
  Vue.use(Vuelidate)

  new Vue({
    el: '#checkout-payment-unsuccessful',
    render: createElement => createElement(PaymentUnsuccessful),
    store
  })
}
