<template>
  <div class="modal modal--store">
    <div class="modal--inner"  v-on-clickaway="closeStoreModal" ref="modalContainer">
      <div class="top">
        <h2>{{ content.storeSelectTitle || 'Select a branch' }}</h2>
        <h5>
          {{
            content.storeSelectCopy ||
              'Collect your order from one of our branches'
          }}
        </h5>
        <button class="close" @click="closeStoreModal()">close</button>
      </div>

      <div class="spinner" v-if="loading">
        <Spinner name="circle" color="var(--color-links)" />
      </div>

      <div v-if="!loading">
      <div v-if="!IsPayPoint">
        <small class="stores-disclaimer">
          When your order is ready for collection, we will send you an email to
          confirm. If you have provided your mobile number, we will also send
          you a text message.
        </small>
      </div>
        <div class="stores" :class="{'set-margin': !IsPayPoint}">
          <store
            v-for="(store, index) in currentPage"
            :key="index"
            :store="store"
          />
        </div>

        <div class="pager">
          <button
            class="button button--secondary"
            @click="prevPage"
            :disabled="pageNumber == 0"
          >
            Previous
          </button>
          <button
            class="button button--secondary"
            @click="nextPage"
            :disabled="pageNumber >= pageCount - 1"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'
import Spinner from 'vue-spinkit'
import Store from './store'
import { SITE_NAME } from '@vue/common/constants/config'

export default {
  name: 'ModalStore',
  mixins: [clickaway],
  components: {
    Spinner,
    Store
  },
  data() {
    return {
      pageNumber: 0,
      SITE_NAME,
    }
   },
  computed: {
    ...mapState({
      content: state => state.calculator.content,
      stores: state => state.store.stores
    }),
    ...mapGetters('store', ['loading']),
    pageCount() {
      return Math.ceil(this.stores.length / 5)
    },
    currentPage() {
      return this.stores.slice(this.pageNumber * 5, this.pageNumber * 5 + 5)
    }
  },
  methods: { 
    ...mapActions({ 
      toggleStoreModal: 'calculator/toggleStoreModal',
      clearStores: 'store/clearStores',
    }),
    nextPage() {
      this.$refs.modalContainer.scrollTop = 0;      
      this.pageNumber++
    },
    closeStoreModal() {
      document.body.removeAttribute('class');
      this.$parent.$children.forEach(x => {
        if (x.$refs.autocomplete != null)
          x.$refs.autocomplete.autocompleteText = ''
      });
      this.clearStores();
      this.toggleStoreModal(false);
    },
    prevPage() {
      this.$refs.modalContainer.scrollTop = 0;    
      this.pageNumber--
    }
  },
  created() {
     window.addEventListener('keydown', (e) => {
       if (e.key == 'Escape') {
        this.toggleStoreModal(false)
       }
     });
  },
  mounted() {
    this.IsPayPoint = (SITE_NAME == 'PAYPOINT')
    document.body.classList.add('modal-open');
  }
}
</script>

<style lang="scss">
.top {
  padding: 25px 15px 5px 15px;
  background: #fff;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
  width: calc(100% + 30px);
  transform: translate(-15px, -26px);

  @media (min-width: 1130px) {
    width: calc(100% + 100px);
    padding: 50px 50px 5px 50px;
    transform: translate(-50px, -50px);
  }
}
</style>
