const DataLayer = class {
  constructor() {
    this.dataLayer = window.dataLayer

    this.init()
    this.events()
  }

  init() {
    this.dataLayer.push({
      'pageCategory': window.doc_type
    })
  }

  events() {
    document.querySelectorAll('.component-tabbed-content .tab').forEach((el) => {
      el.addEventListener('click', (e) => {
        const content = el.querySelector('span')?.textContent
        if (content) {
          this.dataLayer.push({
            'event': 'tabbedContent',
            'content': content
          })
        }
      })
    })

    document.querySelectorAll('.component-faq .title').forEach((el) => {
      el.addEventListener('click', (e) => {
        const content = el.querySelector('span')?.innerText
        if (content) {
          this.dataLayer.push({
            'event': 'tabbedContent',
            'content': content
          })
        }
      })
    }) 
  }
}

export default DataLayer
