import Vue from 'vue'

import BranchFinder from './branch-finder'

if (document.getElementById('stores-finder')) {

  new Vue({
    el: '#stores-finder',
    render: createElement => createElement(BranchFinder)
  })
}
