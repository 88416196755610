import Vue from 'vue'
import store from '@vue/store/index'
import Calculator from './calculator'
import filters from '@vue/common/filters'

if (document.getElementById('calculator')) {
  Vue.use(filters)

  new Vue({
    el: '#calculator',
    render: createElement => createElement(Calculator),
    store,
    created: function() {
      this.doc_type = window.doc_type
      this.origin = window.origin
      this.isIframe = window.calculatorIframe
    }
  })
}
