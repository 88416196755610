<template>
  <div>
    <section class="checkout">
      <div class="container">
        <div class="checkout--content">
          <div v-if="hostedPaymentPageIframeUrl" class="eurochange-payment">
            <iframe ref="frame"
                    :src="hostedPaymentPageIframeUrl"
                    id="eurochange-barclays-iframe"></iframe>
          </div>
          <div class="spinner">
            <Spinner name="circle" color="var(--color-links)" v-if="!hostedPaymentPageIframeUrl" />
          </div>
        </div>

        <order-summary :deliverySlot="order.deliverySlot" v-if="order" />
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { showError } from '@vue/services/toast.service'
import { actionTypes } from '@vue/common/constants/store.types'
import { createPaymentIntent } from '@vue/services/api/checkout.api'

import Spinner from 'vue-spinkit'
import OrderSummary from '@vue/views/checkout/common/order-summary'

import { OrderStates } from '@vue/common/constants/order'
import { WindowEventDiscriminators as WindowEvents} from '@/vue/common/constants/windowEventDiscriminators'

export default {
  name: 'Payment',
  components: {
    OrderSummary,
    Spinner
  },
  data() {
    return {
      hostedPaymentPageIframeUrl: null
    }
  },
  computed: {
    ...mapState('checkout', ['content', 'order'])
  },
  methods: {
    ...mapMutations({
      updateStep: 'checkout/updateStep',
      expiredOrder: 'checkout/expiredOrder'
    }),
    ...mapActions('checkout', [
      actionTypes.GetCheckoutContent,
      actionTypes.GetOrder,
      actionTypes.AbandonCheckout
    ]),
    onWindowMessageReceived: (event) => {
      if (event.origin !== window.origin || !event.data?.type) {
        return;
      }

      switch (event.data.type) {
        case WindowEvents.CHECKOUT_PAYMENT_RECEIVED:
          window.location = window.checkoutWorkflow.next;
          break;
        case WindowEvents.CHECKOUT_PAYMENT_FAILED:
          window.location = window.checkoutWorkflow.error;
          break;
        case WindowEvents.CHECKOUT_PAYMENT_STATE_UNKNOWN:
          showError('Unable to check status of your payment, please wait whilst we retry.');
          break;
      }
    },
    async getHostedPaymentPageIframeUrl() {
      try{
        const response = await createPaymentIntent();

        if(response.status != 201) {
           window.location = window.checkoutWorkflow.error;
           return;
        }

        this.hostedPaymentPageIframeUrl = response.headers['location']
      }
      catch(e) {
        window.location = window.checkoutWorkflow.error
      }
    }
  },
  async mounted() {
    window.addEventListener('message', this.onWindowMessageReceived);

    this.getCheckoutContent()
    this.updateStep('payment')

    await this.getOrder()

    switch (this.order.state.value) {
      case OrderStates.PAYMENT_PENDING:
        this.getHostedPaymentPageIframeUrl();
        break;
      case OrderStates.COMPLETED:
        window.location = window.checkoutWorkflow.next;
        break;
      default:
        window.location = window.checkoutWorkflow.error
        break;
    }
  }
}
</script>

<style lang="css" scoped>
.spinner {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
