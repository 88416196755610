<template>
  <header class="checkout--header">
    <div class="container">
      <div class="logo">
        <a href="/">
          <img src="/logo-mobile-alternate" />
        </a>
      </div>

      <steps />

      <countdown v-if="!steps.confirmation" />

      <button
        @click="setExitModal(true)"
        v-if="!steps.confirmation"
        class="close"
      ></button>

      <a href="/" v-if="steps.confirmation" class="close"></a>
    </div>
    <modal-exit />
    <modal-warning />
  </header>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { SET_EXIT_MODAL } from '@vue/common/constants/mutation.types'
import ModalExit from '@vue/views/checkout/common/modal-exit'
import ModalWarning from '@vue/views/checkout/common/modal-warning'

import Steps from '@vue/views/checkout/common/steps'
import Countdown from '@vue/common/components/countdown'

export default {
  name: 'CheckoutHeader',
  components: {
    Steps,
    Countdown,
    ModalExit,
    ModalWarning
  },
  computed: {
    ...mapState('checkout', ['steps'])
  },
  methods: {
    ...mapActions({
      getCheckoutContent: 'checkout/getCheckoutContent'
    }),
    ...mapMutations('checkout', [SET_EXIT_MODAL])
  },
  mounted() {
    this.getCheckoutContent()
  }
}
</script>
