var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.selectedCurrency
    ? _c("div", { staticClass: "amount-result" }, [
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "item" }, [
            _c("span", [
              _vm._v("You'll pay " + _vm._s(_vm._f("price")(_vm.total))),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item" }, [
            _c("span", [
              _vm._v(
                " You'll get " +
                  _vm._s(
                    _vm._f("price")(_vm.qty, _vm.selectedCurrency.symbol)
                  ) +
                  " "
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }