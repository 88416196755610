var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cart--item" }, [
    _c("div", { staticClass: "cart--flex" }, [
      _c("div", {
        class: "icon icon__" + _vm.line.currency.code.toLowerCase(),
      }),
      _vm._v(" "),
      _c("div", { staticClass: "cart--inner" }, [
        _c("div", { staticClass: "code" }, [
          _vm._v(_vm._s(_vm.line.currency.code)),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "quantity" },
          [
            _c("span", [_vm._v("Amount:")]),
            _vm._v(" "),
            _c("quantity", { attrs: { value: _vm.line } }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "price" }, [
        _vm._v(_vm._s(_vm._f("price")(_vm.line.total))),
      ]),
    ]),
    _vm._v(" "),
    _c("button", {
      staticClass: "close",
      on: {
        click: function ($event) {
          return _vm.deleteLine(_vm.line.id)
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }