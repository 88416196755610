import { getField, updateField } from 'vuex-map-fields'
import { showError } from '@vue/services/toast.service'

import {
  getDefaultStore,
  getStores,
  getStoreById,
  getNearby
} from '@vue/services/api/store.api'

import { SET_STORES } from '@vue/common/constants/mutation.types'

import { actionTypes } from '@vue/common/constants/store.types'

const initialState = {
  stores: [],
  selectedStore: null,
  defaultStore: null,
  store: null
}

const state = { ...initialState }

const actions = {
  getDefaultStore({ commit, dispatch }) {
    getDefaultStore().then(({ data }) => {
      commit('setDefaultStore', data)
      dispatch('currency/getCurrencyRate', null, { root: true })
    })
  },
  getStores({ commit }) {
    getStores().then(({ data }) => {
      commit(
        SET_STORES,
        data.filter(store => store.isMeta)
      )
    })
  },
  getStore({ commit }, id) {
    getStoreById(id).then(({ data }) => {
      commit('setStore', data)
    })
  },
  [actionTypes.GetNearby]({ commit }, e) {
    getNearby({
      latitude: e.Latitude,
      longitude: e.Longitude,
      amount: e.amount,
      currency: e.currency,
      TransactionType: e.TransactionType
    }).then(({ data }) => {
      commit(SET_STORES, data)
    }).catch((err) => {
      if (err && err.response && err.response.data)
        showError(err.response.data.message);
    });
  },
  clearStores({ commit }) {
    commit('clearStores')
  }
}

const mutations = {
  updateField,
  setDefaultStore(state, defaultStore) {
    state.defaultStore = defaultStore
    state.selectedStore = defaultStore
    state.store = defaultStore
  },
  setStore(state, store) {
    state.store = store
  },
  [SET_STORES](state, stores) {
    state.stores = stores
  },
  clearStores(state) {
    state.stores = []
    state.store = state.defaultStore
    state.selectedStore = null
  }
}

const getters = {
  getField,
  loading: state => !state.stores.length
}

const store = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

export default store
