<template>
  <div>
    <vue-google-autocomplete
      id="GooglePlaces"
      classname="form-control"
      placeholder="Enter your postcode, town or city"
      country="gb"  
      types=""
      v-on:placechanged="onPlaceSelected"
    />

    <branch-finder-modal v-if="location" :location="location" v-on:close="reset()" />
  </div>
</template>

<script>
import axios from 'axios'

import { GEOCODING_URL, GEOCODING_KEY } from '@vue/common/constants/config'
import VueGoogleAutocomplete from 'vue-google-autocomplete'

import BranchFinderModal from './components/branch-finder-modal'

export default {
  name: 'BranchFinder',
  components: {
    BranchFinderModal,
    VueGoogleAutocomplete
  },
  data() {
    return {
      location: null,
    }
  },
  methods: {
    reset(){
      this.branches = []
      this.location = null;
    },
    onPlaceSelected(place) {
      if (place.latitude && place.longitude) {
        this.location = {
          latitude: place.latitude,
          longitude: place.longitude
        }
      } else {
          this.fallbackLookup(place)
      }
    },
    fallbackLookup(place) {
      const params = {
          Key: encodeURIComponent(GEOCODING_KEY),
          Country: encodeURIComponent('GBR'),
          Location: encodeURIComponent(
            `${place.route}, ${place.locality}, ${place.administrative_area_level_1}, ${place.administrative_area_level_2}`
          )
        }

        const queryString = Object.keys(params)
          .map(key => key + '=' + params[key])
          .join('&')

        const url = `${GEOCODING_URL}?${queryString}`

        axios
          .post(url, null, { withCredentials: false })
          .then(({ data }) => {
            this.location = {
              latitude: data.Latitude,
              longitude: data.Longitude
            }
          })
          .catch(error => {
            console.log(error)
          })
    },
  }
}
</script>
