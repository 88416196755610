const Accordion = class {
  constructor() {
    this.accordions = document.querySelectorAll('[data-init="accordion"]')

    // Bind events
    this._events()
  }

  /**
   * Bind event to each item within an accordion element
   */
  _events() {
    this.accordions.forEach(accordion => {
      const items = accordion.querySelectorAll('.item')
      items.forEach(item => {
        const title = item.querySelector('.title')
        title?.addEventListener('click', () => {
          if (item.classList.contains('active')) {
            item.classList.remove('active')
          } else {
            item.classList.add('active')
          }
        })
      })
    })
  }
}

export default Accordion
