import Vue from 'vue'

import store from '@vue/store'
import filters from '@vue/common/filters'
import Cart from './cart'

if (document.getElementById('header-cart')) {
  Vue.use(filters)

  new Vue({
    el: '#header-cart',
    render: createElement => createElement(Cart),
    store
  })
}
